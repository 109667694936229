import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import ListDrawer from "./listDrawer";
import styled, { useTheme } from "styled-components";
import { darkenColor } from "../../utils/functions";

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    position: absolute;
    top: 10%;
    left: auto;
    right: 0;
    background-color: ${(props) => darkenColor(props.theme.colors.primary, 20)};
  }
`;

export default function SwipeableTemporaryDrawer({ open, handleDrawerClose }) {
  const theme = useTheme();

  return (
    <StyledDrawer theme={theme} variant="persistent" anchor="top" open={open}>
      <ListDrawer handleDrawerClose={handleDrawerClose} />
      <Divider />
    </StyledDrawer>
  );
}
