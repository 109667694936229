import axios from "axios";

export const ADD_QR_METRIC = "ADD_QR_METRIC";
export const GET_ALL_QRS = "GET_ALL_QRS";
export const ADD_QR_ENTRY = "ADD_QR_ENTRY";
export const GET_QR_ENTRIES = "GET_QR_ENTRIES";

export const getQREntries = () => ({
  type: GET_QR_ENTRIES,
  payload: new Promise((res, rej) => {
    axios
      .get("/qrMetrics/qr-entries")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addQREntry = (data) => ({
  type: ADD_QR_ENTRY,
  payload: new Promise((res, rej) => {
    axios
      .post("/qrMetrics/qr-entry", { token: data })
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addQRMetric = (data) => ({
  type: ADD_QR_METRIC,
  payload: new Promise((res, rej) => {
    axios
      .post("/qrMetrics/add", data)
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllQRs = () => ({
  type: GET_ALL_QRS,
  payload: new Promise((res, rej) => {
    axios
      .get("/qrMetrics/all-qrs")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});
