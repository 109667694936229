import React from "react";
import styled, { useTheme } from "styled-components";
//* assets
import bliveRojo from "../assets/logo/blive_rojo.png";

export default function TipLeftPicHome() {
  const theme = useTheme();
  return (
    <div style={{ display: "flex", flex: 0.9, flexDirection: "row", justifyContent: "center" }}>
      <StaticFrame theme={theme}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={bliveRojo} style={{ width: "140%" }} alt="" />
        </div>
      </StaticFrame>
    </div>
  );
}

const StaticFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.colors.primary};
  justify-content: center;
  align-items: center;
`;
