import React from "react";
import styled, { useTheme } from "styled-components";

import footer_legal from "../assets/footer/legales_blive.png";
import logo from "../assets/logo/LOGO-blive.png";

import { darkenColor } from "../utils/functions";

export default function Footer() {
  const theme = useTheme();

  return (
    <Container theme={theme}>
      <Img src={footer_legal} alt="img-legal" />
      <div style={{ display: "flex", flex: 0.1 }}>
        <img src={logo} style={{ width: "48px" }} alt="img-footer" />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 0.07;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => darkenColor(props.theme.colors.primary, 20)};
  padding: 0 50px 0 50px;
  z-index: 10000;
  @media (orientation: portrait) {
    flex-direction: column;
    padding: 10px 0px 10px 0px;
  }
`;
const Img = styled.img`
  width: 300px;
  height: 10px;
  @media (orientation: portrait) {
    width: 370px;
  }
`;
