import styled from "styled-components";

import colors from "../../assets/colors";
import registro_fondo_iconos from "../../assets/image/01_inicio_y_registro/01_registro_fondo_iconos.png";
import registro_fondo_rojo from "../../assets/image/01_inicio_y_registro/01_registro_fondo_rojo.jpg";
import registro_frase_1 from "../../assets/image/01_inicio_y_registro/01_registro_frase_3.png";
//import registro_boton_mas_tips from "../../assets/image/01_inicio_y_registro/01_registro_boton_mas_tips.png";

export const Container = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  height: 90%;
  background-color: ${colors.palette.primary};
`;

export const StaticFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url(${registro_fondo_iconos});
`;

export const FormLogin = styled.form`
  flex: 1;
  display: flex;
  padding-left: 6%;
  flex-direction: column;
  justify-content: center;
  background-image: url(${registro_fondo_rojo});
`;

export const Frase1 = styled.div`
height: 163px;
width: 650px;
background-position: center;
background-size: contain;
background-repeat: no-repeat;
}
background-image: url(${registro_frase_1});
`;

export const FraseTips = styled.div`
  height: 36px;
  width: 319px;
`;

export const Footer = styled.div`
  display: flex;
  flex: 0.07;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${colors.palette.darkRed};
  padding: 0 50px 0 50px;
`;

export const Title = styled.text`
  font-family: GilroyBold;
  color: ${colors.palette.secondary};
  font-size: 50px;
  align-self: start;
  margin: 30px;
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: GilroyMedium;
  color: ${colors.palette.secondary};
  align-self: start;
  margin-left: 30px;
  width: 70%;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  &:hover {
    color: ${colors.palette.white};
  }
`;
export const GlassBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-size: 16px;
  font-family: GilroyMedium;
  align-items: center;
  color: ${colors.palette.primary};
  margin: ${(props) => (props.margin ? props.margin + "px" : "10px")};
  height: ${(props) => (props.height ? props.height + "px" : "35px")};
  width: ${(props) => (props.width ? props.width + "px" : "30%")};
  border: ${(props) => (props.empty ? "none" : `1.5px solid ${colors.palette.primary}`)};
  background: ${colors.palette.secondary};
`;

export const Input = styled.input`
  color: ${colors.palette.white};
  border: none;
  border-radius: 0;
  padding: 6px;
  font-family: GilroyMedium;
  font-size: 18px;
  font-weight: lighter;
  border-bottom: 1.5px solid ${colors.palette.secondary};
  outline: none;
  background: transparent;
  width: 70%;
  margin-left: 30px;
`;
export const ErrorMessage = styled.p`
  color: ${colors.palette.white}
  font-family: GilroyMedium;
  font-size: 12px;
  font-weight: lighter;
  margin: 0;
`;
