import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { combo } from "../../utils/actions";

//* components
import AlertComponent from "../../../../components/common/alert";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";

//* styles
import { Container, Divider, FormContainer, Input, Label, TittleContainer } from "./styles";

import { useTheme } from "styled-components";

export const Contacto = ({ landscape }) => {
  const dispatch = useDispatch();
  const plantillaCombo = useSelector((state) => state.combo.plantilla);
  const { handleSubmit, register } = useForm();

  const [openAlert, setAlert] = useState(false);
  const exists = useSelector((state) => state.combo.setSelUserContacto);

  const [checkbox, setCheckbox] = useState(exists ? plantillaCombo.combo.contactosSel : []);
  const theme = useTheme();
  const setChecks = (name) => {
    if (checkbox.includes(name)) setCheckbox(checkbox.filter((value) => value !== name));
    else setCheckbox([...checkbox, name]);
  };

  const onSubmit = (values) => {
    let checkOk = [];

    if (checkbox.length > 0) {
      checkbox.map((checkElem) => {
        checkOk = [];
        if (checkElem === "whatsapp" && values.whatsapp.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
        if (checkElem === "facebook" && values.facebook.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
        if (checkElem === "instagram" && values.instagram.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
      });

      if (checkOk.length > 2) {
        dispatch(combo.setContacto(values));
        dispatch(combo.setContactosSel(checkbox));
        dispatch(combo.setSelUserContacto(true));
        dispatch(combo.setImagenFinal(true));
      }
    } else {
      dispatch(combo.setContacto(values));
      dispatch(combo.setContactosSel(checkbox));
      dispatch(combo.setSelUserContacto(true));
      dispatch(combo.setImagenFinal(true));
    }
    /*   if (
      ((checkbox.length === 1 && values[checkbox[0]].length)
        ||
        (checkbox.length === 2 && values[checkbox[0]].length && values[checkbox[1]].length))
    ) {
      dispatch(combo.setContacto(values));
      dispatch(combo.setContactosSel(checkbox));
      dispatch(combo.setSelUserContacto(true));
      dispatch(combo.setImagenFinal(true));
    } else setAlert(true); */
  };

  const handleClose = (event, reason) => setAlert(false);

  return (
    <Container>
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Existen campos vacíos." />

      <div>
        <TittleContainer>
          <Title text="Completá tus datos" />
        </TittleContainer>

        <Subtitle text="Si querés podes elegir dos datos de contacto." />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Subtitle text="Completá los campos." />
          {landscape && (
            <GlobalButton
              content="FINALIZAR"
              onClick={handleSubmit(onSubmit)}
              //disabled={!checkbox.length}
            />
          )}
        </div>
      </div>

      <FormContainer>
        {!landscape && <Divider />}

        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("whatsapp")}
              type="checkbox"
              id="whatsapp"
              name="whatsapp"
              defaultChecked={exists && plantillaCombo?.combo.contactosSel.includes("whatsapp")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("whatsapp")}
            />
            <Label htmlFor="titulo">Whatsapp</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("whatsapp")}
            placeholder={plantillaCombo?.combo.contacto.whatsapp}
            defaultValue={exists ? plantillaCombo?.combo.contacto.whatsapp : ""}
            name="whatsapp"
            type="text"
            ref={register}
          />
        </div>

        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("facebook")}
              type="checkbox"
              id="facebook"
              name="facebook"
              defaultChecked={exists && plantillaCombo?.combo.contactosSel.includes("facebook")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("facebook")}
            />
            <Label htmlFor="titulo">Facebook</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("facebook")}
            placeholder={plantillaCombo?.combo.contacto.facebook}
            defaultValue={exists ? plantillaCombo?.combo.contacto.facebook : ""}
            name="facebook"
            type="text"
            ref={register}
          />
        </div>

        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("instagram")}
              type="checkbox"
              id="instagram"
              name="instagram"
              defaultChecked={exists && plantillaCombo?.combo.contactosSel.includes("instagram")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("instagram")}
            />
            <Label htmlFor="titulo">Instagram</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("instagram")}
            placeholder={plantillaCombo?.combo.contacto.instagram}
            defaultValue={exists ? plantillaCombo?.combo.contacto.instagram : ""}
            name="instagram"
            type="text"
            ref={register}
          />
        </div>
        {/* TODO  TIK TOK */}
        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={true}
              type="checkbox"
              id="instagram"
              name="instagram"
              //    defaultChecked={exists && plantillaCombo?.combo.contactosSel.includes("instagram")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              //     onClick={() => setChecks("instagram")}
            />
            <Label style={{ color: theme.colors.border }} htmlFor="titulo">
              Tik Tok
            </Label>
          </div>

          <Input
            disabled={true}
            //   placeholder={plantillaCombo?.combo.contacto.instagram}
            //  defaultValue={exists ? plantillaCombo?.combo.contacto.instagram : ""}
            name="tiktok"
            type="text"
            ref={register}
          />
        </div>

        {!landscape && (
          <GlobalButton
            content="FINALIZAR"
            onClick={handleSubmit(onSubmit)}
            //disabled={!checkbox.length}
          />
        )}
      </FormContainer>
    </Container>
  );
};
