import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

//* components
import AlertComponent from '../../../../components/common/alert';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import GlobalButton from '../../../../components/common/GlobalButton';
import { Element } from 'react-scroll';

//* actions
import { createMenu } from '../../redux/actions';

//* styles
import { Container, Form, Input, TextArea, Label, SubtitleD, TittleContainer } from './styles';
import { useTheme } from 'styled-components';

const CreateFields = ({ register, comboRef, name }) => {
  const exists = useSelector((state) => state.createMenu.setSelUserDescripcion);
  const [textComboRef, setTextComboRef] = useState(exists ? comboRef?.description : '');

  const theme = useTheme();

  const onChancheTextInput = (e) => {
    setTextComboRef(e.target.value);
  };

  return (
    <div style={{ borderBottom: `2px ${theme.colors.primary} solid`, marginTop: 15 }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '70%' }}>
          <Label htmlFor={`name_${name}`}>Nombre del plato o combo</Label>
          <Input
            width={'80%'}
            name={`name_${name}`}
            placeholder={comboRef?.name}
            defaultValue={exists ? comboRef?.name : ''}
            type="text"
            ref={register({ required: true })}
            maxLength={24}
          />
        </div>

        <div style={{ width: '30%' }}>
          <Label htmlFor={`price_${name}`}>
            Precio{' '}
            <span
              style={{
                fontSize: '12px',
              }}>
              (opcional)
            </span>
          </Label>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -21 }}>
            <Input
              width={'100%'}
              name={`price_${name}`}
              placeholder={comboRef?.price}
              defaultValue={exists ? comboRef?.price : ''}
              ref={register({ required: false })}
              type="number"
              maxLength={4}
            />
          </div>
        </div>
      </div>

      <Label htmlFor={`description_${name}`}>Descripción del plato o combo ({textComboRef.length}/120)</Label>
      <TextArea
        placeholder={comboRef?.description}
        defaultValue={exists ? comboRef?.description : ''}
        style={{
          width: '95%',
          height: '3em',
          padding: '6px',
          paddingLeft: '12px',
        }}
        name={`description_${name}`}
        ref={register({ required: true })}
        onChange={onChancheTextInput}
        type="password"
        maxLength={92}
        rows="10"
        cols="25"
      />
    </div>
  );
};

export const Description = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const plantillaMenu = useSelector((state) => state.createMenu.template);
  const exists = useSelector((state) => state.combo.etSelUserDescripcion);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const theme = useTheme();
  const onSubmit = (values) => {
    const {
      title,
      title_sector_1,
      title_sector_2,
      name_combo_1,
      name_combo_2,
      name_combo_3,
      name_combo_4,
      name_combo_5,
      name_combo_6,
      name_combo_7,
      name_combo_8,
      price_combo_1,
      price_combo_2,
      price_combo_3,
      price_combo_4,
      price_combo_5,
      price_combo_6,
      price_combo_7,
      price_combo_8,
      description_combo_1,
      description_combo_2,
      description_combo_3,
      description_combo_4,
      description_combo_5,
      description_combo_6,
      description_combo_7,
      description_combo_8,
    } = values;

    dispatch(createMenu.setSelUserDescripcion(true));
    dispatch(
      createMenu.setDescription(
        plantillaMenu.ref === 'simple'
          ? {
              section_1: {
                title: title_sector_1,
                combos: [
                  {
                    name: name_combo_1,
                    description: description_combo_1,
                    price: price_combo_1,
                  },
                  {
                    name: name_combo_2,
                    description: description_combo_2,
                    price: price_combo_2,
                  },
                ],
              },
              section_2: {
                title: title_sector_2,
                combos: [
                  {
                    name: name_combo_3,
                    description: description_combo_3,
                    price: price_combo_3,
                  },
                  {
                    name: name_combo_4,
                    description: description_combo_4,
                    price: price_combo_4,
                  },
                ],
              },
            }
          : {
              section_1: {
                title: title_sector_1,
                combos: [
                  {
                    name: name_combo_1,
                    description: description_combo_1,
                    price: price_combo_1,
                  },
                  {
                    name: name_combo_2,
                    description: description_combo_2,
                    price: price_combo_2,
                  },
                  {
                    name: name_combo_3,
                    description: description_combo_3,
                    price: price_combo_3,
                  },
                  {
                    name: name_combo_4,
                    description: description_combo_4,
                    price: price_combo_4,
                  },
                ],
              },
              section_2: {
                title: title_sector_2,
                combos: [
                  {
                    name: name_combo_5,
                    description: description_combo_5,
                    price: price_combo_5,
                  },
                  {
                    name: name_combo_6,
                    description: description_combo_6,
                    price: price_combo_6,
                  },
                  {
                    name: name_combo_7,
                    description: description_combo_7,
                    price: price_combo_7,
                  },
                  {
                    name: name_combo_8,
                    description: description_combo_8,
                    price: price_combo_8,
                  },
                ],
              },
            }
      )
    );
    dispatch(createMenu.setTitle(title));
    dispatch(createMenu.setEtapa('picture'));
  };

  return (
    <Container className="containerPlantilla">
      <AlertComponent
        open={openAlert}
        color="warning"
        onClose={handleClose}
        message="Debes completar todos los campos."
      />

      <div>
        <TittleContainer>
          <Title text="Describí tu menú" />
        </TittleContainer>

        <Subtitle notmr text={'Recordá que el nombre aparecerá en el próximo paso una vez que las selecciones.'} />
      </div>

      <Element
        className="element"
        id="scroll-container"
        style={{ overflow: 'scroll', overflowX: 'hidden', marginBottom: '10%' }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '3%',
            }}>
            <SubtitleD>Completá los campos</SubtitleD>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <GlobalButton content="SIGUIENTE" disabled={Object.keys(errors).length} onClick={() => setAlert(true)} />
            </div>
          </div>

          <div style={{ borderBottom: `2px ${theme.colors.primary} solid`, marginTop: 15 }}>
            <Label htmlFor="title">Título</Label>
            <Input
              width={'100%'}
              name="title"
              placeholder={plantillaMenu?.menu.title}
              defaultValue={exists ? plantillaMenu?.menu.title : ''}
              ref={register({ required: true })}
              type="text"
            />
          </div>

          <div style={{ borderBottom: `2px ${theme.colors.primary} solid`, marginTop: 15 }}>
            <Label htmlFor="title_sector_1">Título de los platos o combos 1</Label>
            <Input
              width={'100%'}
              name="title_sector_1"
              placeholder={plantillaMenu?.menu.sections.section_1.title}
              defaultValue={exists ? plantillaMenu?.menu.sections.section_1.title : ''}
              ref={register({ required: true })}
              type="text"
            />
          </div>

          {plantillaMenu.ref === 'simple' ? (
            <>
              <CreateFields
                register={register}
                comboRef={plantillaMenu?.menu.sections.section_1.combos[0]}
                name="combo_1"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_1.combos[1]}
                name="combo_2"
              />
            </>
          ) : (
            <>
              <CreateFields
                register={register}
                comboRef={plantillaMenu?.menu.sections.section_1.combos[0]}
                name="combo_1"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_1.combos[1]}
                name="combo_2"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu?.menu.sections.section_1.combos[2]}
                name="combo_3"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_1.combos[3]}
                name="combo_4"
              />
            </>
          )}

          <div style={{ borderBottom: `2px ${theme.colors.primary} solid`, marginTop: 15 }}>
            <Label htmlFor="title_sector_2">Título de los platos o combos 2</Label>
            <Input
              width={'100%'}
              name="title_sector_2"
              placeholder={plantillaMenu?.menu.sections.section_2.title}
              defaultValue={exists ? plantillaMenu?.menu.sections.section_2.title : ''}
              ref={register({ required: true })}
              type="text"
            />
          </div>

          {plantillaMenu.ref === 'simple' ? (
            <>
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[0]}
                name="combo_3"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[1]}
                name="combo_4"
              />
            </>
          ) : (
            <>
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[0]}
                name="combo_5"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[1]}
                name="combo_6"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[2]}
                name="combo_7"
              />
              <CreateFields
                register={register}
                comboRef={plantillaMenu.menu.sections.section_2.combos[3]}
                name="combo_8"
              />
            </>
          )}
        </Form>
      </Element>
    </Container>
  );
};
