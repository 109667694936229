import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Element } from "react-scroll";
import { createMenu } from "../../redux/actions";

//* components
import GlobalButton from "../../../../components/common/GlobalButton";
import AlertComponent from "../../../../components/common/alert";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";

//*styles
import { Container, FormLogin, GridImage, ContainerImage, Image, CustomLabel, CustomLabelLeft, TittleContainer } from "./styles";

export const Food = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const comidas = useSelector((state) => state.createMenu.foods);
  const exists = useSelector((state) => state.createMenu.setSelUserComida);

  const [selComida, setSelComida] = useState("");
  const [openAlert, setAlert] = useState(false);

  const handleClose = () => setAlert(false);

  const onSubmit = () => {
    if (selComida) dispatch(createMenu.setEtapa("contact"));
    else if (exists) dispatch(createMenu.setEtapa("contact"));
  };

  const onChangeLogo = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSelComida(reader.result);
        dispatch(createMenu.setPresetFoodPhoto(false));
        dispatch(createMenu.setSelUserComida(true));
        dispatch(createMenu.setFood({ src: reader.result }));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      {comidas && (
        <>
          <TittleContainer>
            <Title text="Elegí tu comida" />
          </TittleContainer>

          <Subtitle text="Subí tu foto (sólo .jpg, .jpeg y .png, peso máximo de 500kb) o seleccioná una de nuestro banco de imágenes." />

          <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione alguna imagen." />

          <FormLogin onSubmit={handleSubmit(onSubmit)}>
            <div style={{ disply: "flex", flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "30px",
                }}
              >
                <CustomLabelLeft>archivo.jpg</CustomLabelLeft>
                <CustomLabel htmlFor="logo">EXAMINAR...</CustomLabel>
              </div>

              <input id="logo" name="comidaUser" ref={register} type="file" onChange={onChangeLogo} style={{ display: "none" }} />
            </div>

            <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
              <GlobalButton
                disabled={!exists}
                content="SIGUIENTE"
                onClick={() => {
                  if (!exists) setAlert(true);
                }}
              />
            </div>
          </FormLogin>

          <Element
            className="element"
            id="scroll-container"
            style={{
              overflow: "scroll",
              marginLeft: 12,
              marginRight: 12,
              paddingBottom: "50px",
            }}
          >
            <GridImage>
              {comidas.map((comida, i) => (
                <div key={i}>
                  <ContainerImage key={comida.id} active={selComida.id === comida.id}>
                    <Image
                      src={comida.src}
                      onClick={() => {
                        setSelComida(comida);
                        dispatch(createMenu.setPresetFoodPhoto(true));
                        dispatch(createMenu.setSelUserComida(true));
                        dispatch(createMenu.setFood(comida));
                      }}
                    />
                  </ContainerImage>
                </div>
              ))}
            </GridImage>
          </Element>
        </>
      )}
    </Container>
  );
};
