import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, GrdHome, GrdBtnHome, Title, BtnCombo, BtnMenu, BtnQR, Label } from "./styles";

//* components
import TipLeftPic from "../../components/TipLeftPic";
import Footer from "../../components/Footer";
import TermsAndConditions from "../../components/TermsAndConditions";

import Snackbar from "@material-ui/core/Snackbar";

import { showTermsAndConditions } from "../../redux/ui/actions";
import { addComboEntry } from "../../redux/combo/actions";
import { addMenuEntry } from "../../redux/menu/actions";
import { addQREntry } from "../../redux/qr/actions";

const Home = ({ history }) => {
  const [openAlert, setAlert] = useState(false);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const [landscape, setLandscape] = useState(window.innerWidth > 700);
  const user = useSelector((state) => state.credentials.userLogueado);
  useLayoutEffect(() => {
    const updateSize = () => setLandscape(window.innerWidth > 700);
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    dispatch(showTermsAndConditions(false));
  }, [dispatch]);

  return (
    <Container>
      <Snackbar open={openAlert} color="warning" z onClose={() => setAlert(false)} message="Credenciales incorrectas, intentelo nuevamente." />

      <TermsAndConditions openModal={openModal} onClose={() => setOpenModal(false)} />

      {!landscape && (
        <div style={{ display: "flex", flex: 0.93, flexDirection: "column" }}>
          <TipLeftPic landscape={landscape} />

          <GrdHome>
            <Title>¿Qué desea crear?</Title>

            <GrdBtnHome>
              <BtnCombo onClick={() => dispatch(addComboEntry(user.token)) && history.push("/account/createcombo")} />
              <BtnMenu onClick={() => dispatch(addMenuEntry(user.token)) && history.push("/account/createMenu")} />

              <BtnQR onClick={() => dispatch(addQREntry(user.token)) && history.push("/account/createQR")} />
            </GrdBtnHome>

            <div style={{ alignSelf: "flex-end" }}>
              <Label style={{ margin: 0 }}>
                <span style={{ fontWeight: "bold", marginLeft: 6 }} onClick={() => setOpenModal(true)}>
                  Bases y condiciones
                </span>
              </Label>
            </div>
          </GrdHome>
        </div>
      )}

      {landscape && (
        <div style={{ display: "flex", flex: 0.93, flexDirection: "row" }}>
          <TipLeftPic landscape={landscape} />
          <GrdHome>
            <GrdBtnHome>
              <Title>¿Qué desea crear? </Title>
              <BtnCombo onClick={() => dispatch(addComboEntry(user.token)) && history.push("/account/createcombo")} />
              <BtnMenu onClick={() => dispatch(addMenuEntry(user.token)) && history.push("/account/createMenu")} />
              <BtnQR onClick={() => dispatch(addQREntry(user.token)) && history.push("/account/createQR")} />
              <div>
                <Label style={{ margin: 0 }}>
                  <span style={{ fontWeight: "bold" }} onClick={() => setOpenModal(true)}>
                    Bases y condiciones
                  </span>
                </Label>
              </div>
            </GrdBtnHome>
          </GrdHome>
        </div>
      )}

      <Footer />
    </Container>
  );
};

export default Home;
