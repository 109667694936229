import React from "react";
import { Container, Image } from "./styles";

const MiniPreview = ({ src, onClick, active }) => (
  <Container active={active} onClick={onClick}>
    <Image src={src}></Image>
  </Container>
);

export { MiniPreview };
