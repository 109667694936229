import React, { useRef, useEffect, useState } from "react";
import { useResizeObserver } from "use-events";
import { useSelector } from "react-redux";

import * as d3 from "d3";
import "./BarChart.css";

//assets
import logo_ades from "../../../../assets/metrics/icons/logo_ades.png";
import logo_aquarius from "../../../../assets/metrics/icons/logo_aquarius.png";
import logo_ccl from "../../../../assets/metrics/icons/logo_ccl.png";
import logo_ccsa from "../../../../assets/metrics/icons/logo_ccsa.png";
import logo_ccso from "../../../../assets/metrics/icons/logo_ccso.png";
import logo_cepita from "../../../../assets/metrics/icons/logo_cepita.png";
import logo_fn from "../../../../assets/metrics/icons/logo_fn.png";
import logo_kin from "../../../../assets/metrics/icons/logo_kin.png";
import logo_powerade from "../../../../assets/metrics/icons/logo_powerade.png";
import logo_sp from "../../../../assets/metrics/icons/logo_sp.png";
import logo_spsa from "../../../../assets/metrics/icons/logo_spsa.png";
import logo_sw from "../../../../assets/metrics/icons/logo_sw.png";
import logo_monster from "../../../../assets/metrics/icons/logo_monster.png";
import logo_cc_coffee from "../../../../assets/metrics/icons/logo_cc_coffee.png";
import logo_scheppes from "../../../../assets/metrics/icons/logo_scheppes.png";
//* styles
import { ChartsFrame, GrdIcons, Chart, ImgIcons } from "./style";

export const BarChart = () => {
  /**
   * chequerar todos los drinkline y capacidades ml de
   *
   */

  const stackIcons = [
    {
      drinkLine: "ades",
      image: logo_ades,
    },
    {
      drinkLine: "aquarius",
      image: logo_aquarius,
    },
    {
      drinkLine: "ccl",
      image: logo_ccl,
    },
    {
      drinkLine: "ccsa",
      image: logo_ccsa,
    },
    {
      drinkLine: "ccso",
      image: logo_ccso,
    },
    {
      drinkLine: "cepita-fresh",
      image: logo_cepita,
    },
    {
      drinkLine: "cepita",
      image: logo_cepita,
    },
    {
      drinkLine: "fanta",
      image: logo_fn,
    },
    {
      drinkLine: "kin",
      image: logo_kin,
    },
    {
      drinkLine: "powerade",
      image: logo_powerade,
    },
    {
      drinkLine: "sprite",
      image: logo_sp,
    },
    {
      drinkLine: "spsa",
      image: logo_spsa,
    },
    {
      drinkLine: "smartwater",
      image: logo_sw,
    },
    {
      drinkLine: "monster",
      image: logo_monster,
    },
    {
      drinkLine: "ccl-coffee",
      image: logo_cc_coffee,
    },
    {
      drinkLine: "schweppes",
      image: logo_scheppes,
    },
  ];
  const wrapperRef = useRef(null);
  const svgChart = useRef();
  const customHeight = 300;
  const [width, height] = useResizeObserver(wrapperRef);
  const [infoReady, setInfoReady] = useState(undefined);
  const dimensions = { width, height }; //useResizeObserver(wrapperRef)
  const dataChart = useSelector((state) => state.metrics.totalCombos.combos);

  const convertData = (data) => {
    let result = [];
    data.forEach((combo) => {
      if (combo.drinkLine === "cc" || combo.drinkLine === "socc")
        combo.drinkLine = "ccso";
      const objBebida = result.find((b) => b.drinkLine === combo.drinkLine);
      const foundIndex = result.findIndex(
        (b) => b.drinkLine === combo.drinkLine
      );
      const foundIconBebida = stackIcons.find(
        (b) => b.drinkLine === combo.drinkLine
      );
      const icon = foundIconBebida ? foundIconBebida.image : logo_powerade;
      if (!objBebida) {
        let obj = {};
        let color = Math.round(Math.random() * 1000);
        obj.drinkLine = combo.drinkLine;
        obj[combo.drinkLitrage] = 1;
        obj.color = `hsl(${color}, 70%, 50%)`;
        obj.total = 1;
        obj.imageBebida = icon;
        result.push(obj);
      } else {
        if (objBebida[combo.drinkLitrage]) objBebida[combo.drinkLitrage]++;
        else objBebida[combo.drinkLitrage] = 1;
        objBebida.total++;
        result[foundIndex] = objBebida;
      }
    });

    setInfoReady(result);
  };

  const genChart = React.useCallback(() => {
    d3.select(".barChart").remove();

    var subgroups = [
      "Vaso",
      "200ml",
      "220ml",
      "237ml",
      "350ml",
      "354ml",
      "473ml",
      "500ml",
      "591ml",
      "1L",
      "1,25L",
      "1,5L",
      "1,75L",
      "2L",
      "2,25L",
    ];

    var groups = d3.map(infoReady, function (d) {
      return d.drinkLine;
    });

    let svgGlobal = d3.select(svgChart.current),
      margin = { top: 0, right: 40, bottom: 30, left: 60 },
      width = dimensions.width - margin.left - margin.right,
      height = customHeight - margin.top - margin.bottom;

    const svg = svgGlobal
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("class", "barChart");

    // Add X axis
    let x = d3.scaleBand().domain(groups).range([0, width]).padding([0.1]);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickFormat("").tickSizeOuter(0));

    // Add Y axis
    let y = d3
      .scaleLinear()
      .domain([0, d3.max(infoReady, (d) => d.total * 1.1)])
      .range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // Add X axis label:
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("x", width)
      .attr("y", height + margin.top + 20)
      .text("MARCA");

    // Y axis label:
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left + 15)
      .attr("x", -margin.top)
      .text("CANTIDAD");

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal().domain(subgroups).range(d3.schemePaired);
    //.range(['#e41a1c', '#377eb8', '#4daf4a'])

    //stack the data? --> stack per subgroup
    var stackedData = d3.stack().keys(subgroups)(infoReady);

    //var tooltip = d3.select(idTooltip.current);

    svg
      .append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", function (d) {
        return color(d.key);
      })
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d;
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return x(d.data.drinkLine);
      })
      .attr("y", function (d) {
        return y(d[1]);
      })
      .attr("height", function (d) {
        return y(d[0]) - y(d[1]);
      })
      .attr("width", x.bandwidth())
      .attr("pointer-events", "all")
      .on("mouseover", function (d, i) {
        const tt = svg.append("g").attr("id", "tt");
        let thisName = d3.select(this.parentNode).datum().key;
        let thisValue = i["data"][thisName];
        tt.append("text")
          .text(thisName + " : " + thisValue)
          .attr("text-anchor", "middle")
          .attr(
            "transform",
            "translate(" +
              (x(i["data"].drinkLine) + x.bandwidth() / 2) +
              "," +
              y(i[1]) +
              ")"
          )
          .transition()
          .duration(500)
          .attr("y", -10)
          .attr("opacity", 1);
      })
      .on("mouseout", function (d) {
        svg.select("#tt").remove();
      });
  }, [infoReady, dimensions.width]);

  useEffect(() => {
    if (infoReady) genChart();
  }, [infoReady, dimensions.width]);
  useEffect(() => {
    if (dataChart) {
      convertData(dataChart);
    }
  }, [dataChart]);
  return (
    <ChartsFrame ref={wrapperRef}>
      <Chart ref={svgChart} />
      {infoReady && (
        <GrdIcons icons={infoReady}>
          {infoReady.map((b, i) => {
            return (
              <div key={i} style={{ textAlign: "center" }}>
                <p>{b.total}</p>
                <ImgIcons src={b.imageBebida} />
              </div>
            );
          })}
        </GrdIcons>
      )}
    </ChartsFrame>
  );
};
