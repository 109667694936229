import styled from "styled-components";

export const Input = styled.input`
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.button};
  padding: 4px;
  padding-left: 12px;
  font-family: GilroyMedium;
  font-size: 13px;
  font-weight: 500;
  width: ${(props) => (props.width ? props.width : "90%")};
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.border};
    font-family: GilroyMedium;
    font-size: 13px;
    font-weight: 500;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const SubtitleD = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  font-family: GilroyMedium;
  padding: 5px 0px 0px 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 20px;
  overflow: scroll;
  @media (orientation: portrait) {
    padding: 0;
    height: 85vh;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  margin: 1rem 0;
`;

export const ReadingSection = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  @media (orientation: portrait) {
    padding: 20px 20px 0 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex: 3;
  flex-direction: column;
  @media (orientation: portrait) {
    padding: 20px;
  }
`;

export const TextArea = styled.textarea`
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.button};
  padding: 6px;
  padding-left: 12px;
  font-family: GilroyMedium;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  outline: none;
  height: 4em;
  margin-top: 8px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.border};
    font-family: GilroyMedium;
    font-size: 13px;
    font-weight: 500;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const Label = styled.label`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  padding: 8px;
  font-size: 15px;
  font-family: GilroyMedium;
  font-weight: bold;
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
