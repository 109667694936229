import React from "react";
import { useSelector } from "react-redux";

//* components
import SwitchStage from "../../utils/SwitchStage";

//COMPONENTS
import Plantilla from "../../../../components/common/buttons/Plantilla";
import Link from "../../../../components/common/buttons/Link";
import Logo from "../../../../components/common/buttons/Logo";
import Description from "../../../../components/common/buttons/Description";
import Picture from "../../../../components/common/buttons/Picture";
import Contact from "../../../../components/common/buttons/Contact";
//import FlechaSelectQR from "../../../../components/common/flechaSelectQR";

//STYLES
import { GridControl, GridLayout, ButtonContainer, Flecha, Container } from "./styles";
import { useTheme } from "styled-components";
import { ReactSVG } from "react-svg";
import flecha from "../../../../assets/image/02_crear_combo/arrow.svg";
import { darkenColor } from "../../../../utils/functions";

export const Sidebar = ({ landscape }) => {
  const { stage, template, setSelUserLogo, setSelUserDescripcion, setSelUserContacto, setSelUrl, setSelUserComida } = useSelector(
    (state) => state.comboQR
  );
  const theme = useTheme();
  const FlechaSelect = ({ isActive }) => (
    <Container isActive={isActive}>
      <Flecha src={flecha} color={darkenColor(theme.colors.primary, 20)} />
    </Container>
  );
  const SidebarButton = ({ children, isActive }) => (
    <ButtonContainer>
      {children}
      {isActive && <FlechaSelect isActive={isActive} />}
    </ButtonContainer>
  );
  console.log(stage);
  return (
    <>
      <GridLayout>
        <GridControl>
          <SidebarButton isActive={stage === "template"}>
            <Plantilla type="comboQR" stage={stage} template={template} />
          </SidebarButton>

          <SidebarButton isActive={stage === "logo"}>
            <Logo type="comboQR" stage={stage} template={template} setLogo={setSelUserLogo} />
          </SidebarButton>

          <SidebarButton isActive={stage === "description"}>
            <Description type="comboQR" stage={stage} template={template} setDescripcion={setSelUserDescripcion} />
          </SidebarButton>

          <SidebarButton isActive={stage === "picture"}>
            <Picture type="comboQR" stage={stage} template={template} setPic={setSelUserComida} />
          </SidebarButton>

          <SidebarButton isActive={stage === "link"}>
            <Link type="comboQR" stage={stage} template={template} setUrl={setSelUrl} />
          </SidebarButton>

          <SidebarButton isActive={stage === "contact"}>
            <Contact type="comboQR" stage={stage} template={template} setContacto={setSelUserContacto} />
          </SidebarButton>
        </GridControl>

        <SwitchStage />
      </GridLayout>
    </>
  );
};
