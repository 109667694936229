import React from "react";

import styled, { useTheme } from "styled-components";

export default function Tips({ texts }) {
  const theme = useTheme();
  return (
    <div style={{ flex: 1, marginTop: 12 }}>
      {texts.map((elemento) => (
        <Text key={elemento} theme={theme}>
          {elemento}
        </Text>
      ))}
    </div>
  );
}

const Text = styled.div`
  // margin: 0 0 0 12px;
  display: flex;
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.secondary};
  font-size: 16px;
  font-family: GilroyMedium;
  width: 90%;
  padding: 5px 12px;
`;
