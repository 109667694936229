import styled from "styled-components";
import colors from "../../assets/colors";

//TODO NO SE ESTARIA USANDO
export const ButtonUploadImage = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: ${colors.palette.grey} 1px solid;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 5px;
  text-align: center;
  background: transparent;
  text-transform: uppercase;
`;
