import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB7MV1bnCgu2V2sxzZmy-GcV0vlDjuwjoo",
  authDomain: "blive-mkt.firebaseapp.com",
  projectId: "blive-mkt",
  storageBucket: "blive-mkt.appspot.com",
  messagingSenderId: "501286003721",
  appId: "1:501286003721:web:db497c43fea24895eec4d8",
  measurementId: "G-T71XGJF0YF"}

// Initialize Firebase
export const appFB = initializeApp(firebaseConfig);
const analytics = getAnalytics(appFB);
