import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";

//* components
import AlertComponent from "../../../../components/common/alert";
import Dropdown from "../../../../components/Dropdown";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";

//* actions
import { createMenu } from "../../redux/actions";

//* styles
import { Container, GridControl, GridImage, ContainerImageGrid, ContainerImage, Image, TittleContainer } from "./styles";

export const Template = () => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.createMenu.templates);
  const exist = useSelector((state) => state.createMenu.template);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const [plantilla, setPlantilla] = useState(null);
  const [referencia, setReferencia] = useState(null);
  const [list, setList] = useState([]);

  const comboFilter = useCallback(() => {
    if (referencia) {
      setList(templates.filter((template) => template.ref === referencia));
    } else {
      setList(templates.filter((template) => template.ref === referencia));
    }
  }, [referencia, templates]);

  const setPlantillaPosta = (template) => {
    setPlantilla(template.id);
    dispatch(createMenu.setTemplate({ ...template }));
  };

  useEffect(() => {
    if (referencia) comboFilter();
    else {
      setList(templates);
    }
  }, [referencia, templates, comboFilter]);

  return (
    <Container className="containerPlantilla">
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione alguna plantilla." />

      {templates && (
        <>
          <div>
            <TittleContainer>
              <Title text="Elegí tu plantilla" />
            </TittleContainer>

            <Subtitle text="Recordá que podés cambiar todos los elementos de plantilla en los próximos pasos." />
          </div>

          <GridControl>
            <Dropdown
              name="MODELO"
              data={[
                {
                  name: "Simple (2-4 platos)",
                  action: () => setReferencia("simple"),
                },
                {
                  name: "Complejo (6-8 platos)",
                  action: () => setReferencia("complex"),
                },
              ]}
            />

            <GlobalButton
              disabled={!exist}
              content="SIGUIENTE"
              onClick={() => {
                if (plantilla) dispatch(createMenu.setEtapa("logo"));
                else setAlert(true);
              }}
            />
          </GridControl>

          <Element
            className="element"
            id="scroll-container"
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <GridImage>
              {list.map((plant) => (
                <ContainerImageGrid key={plant.id}>
                  <ContainerImage active={plantilla === plant.id}>
                    <Image src={plant.src} onClick={() => setPlantillaPosta(plant)} />
                  </ContainerImage>
                </ContainerImageGrid>
              ))}
            </GridImage>
          </Element>
        </>
      )}
    </Container>
  );
};
