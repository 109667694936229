import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 150px;
  padding: 20px 30px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  margin: 5px;
  background-color: ${({ theme, active }) => (active ? theme.colors.secondary : "none")};
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  @media (max-width: 1200px) {
    padding: 20px 10px;
    width: 75px;
    height: 140px;
  }
`;

export const Image = styled.img`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 32px;
  margin-bottom: 10px;
  width: 60px;
  @media (max-width: 1200px) {
    width: 45px;
  }
`;

export const Text = styled.p`
  font-size: 9px;
  color: ${({ theme }) => theme.colors.border};
  text-transform: capitalize;
  margin: 0;
  text-align: center;
  width: fit-content @media (max-width: 1200px) {
    font-size: 5px;
  }
`;
