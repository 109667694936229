//* librerias
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//* components
import Sidebar from "../../utils/Sidebar";
import Footer from "../../../../components/Footer";

//* functions and actions
import { getLines, callBack_contactImage } from "../../../../utils/functions";
import { createMenu } from "../../redux/actions";
import { showTermsAndConditions } from "../../../../redux/ui/actions";
import { addMenuMetric } from "../../../../redux/menu/actions";

//* assets
import imageBackRect from "../../../../assets/image/02_crear_combo/02_crear_combo_base_rectangular_empeza_a_crear.png";
import smile_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_smille_final.png";
import final_image from "../../../../assets/image/02_crear_combo/02_crear_combo_frase_final.png";
import fb_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_facebook.png";
import wsp_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_whatsapp.png";
import inst_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_instagram.png";
import phone from "../../../../assets/image/02_crear_combo/02_crear_combo_base_telefono.png";
import plantilla_menu_botellas from "../../../../assets/menu/elements/11_plantilla_menu_botellas.png";
import plantilla_menu_franja_titular from "../../../../assets/menu/elements/11_plantilla_menu_franja_titular.png";
import plantilla_menu_comida_23 from "../../../../assets/menu/foodGalery/11_plantilla_menu_comida_23.png";

//* styles
import {
  Container,
  GridBinding,
  BackImageComboRect,
  ContainerDescarga,
  GridLeft,
  ContainerImages,
  ImageText,
  ImageSmile,
  ImageIcon,
  ContainerIcons,
  GridButtons,
  BtnDescarga,
  Canvas2D,
  ImageWrapper,
  Canvas2DDescarga,
  HomeButton,
  BackButton,
  SubContainerDescarga,
} from "./styles";

export const CreateMenu = () => {
  const dispatch = useDispatch();
  const [descImage, setDescImage] = useState(null);

  const finalTemplate = useSelector((state) => state.createMenu.template);
  const final_imagen = useSelector((state) => state.createMenu.imagenFinal);
  const user = useSelector((state) => state.credentials.userLogueado);
  const sendMetric = () =>
    dispatch(
      addMenuMetric({
        menuType: finalTemplate.ref,
        presetFoodPhoto: finalTemplate.menu.presetFoodPhoto,
        token: user.token,
      })
    );

  useEffect(() => {
    dispatch(showTermsAndConditions(true));
  }, [dispatch]);

  const [landscape, setLandscape] = useState(window.innerWidth > 700);
  useLayoutEffect(() => {
    const updateSize = () => setLandscape(window.innerWidth > 700);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const mergeTemplate = useCallback(
    (template) => {
      const canvas_global = document.getElementById("canvas");
      const ctx = canvas_global.getContext("2d");
      ctx.textAlign = "center";
      const imagefinal = new Image();
      imagefinal.crossOrigin = "anonymous";
      imagefinal.src = template.menu.srcPlantilla;

      imagefinal.onload = async function () {
        if (!template.menu.food) {
          //* image food
          const imageFood = new Image();
          imageFood.src = plantilla_menu_comida_23;
          imageFood.onload = async function () {
            return await ctx.drawImage(this, 0, 0, 700, 586);
          };
          //top franja - footer franja - logo - legal image
          setTimeout(() => {
            //* footer franja
            const imageButtonStripe = new Image();
            imageButtonStripe.src = template.menu.stripe;

            imageButtonStripe.onload = function () {
              ctx.drawImage(imageButtonStripe, 0, 1030, 1081, 108);
            };

            //* legal image
            const imageLegal = new Image();
            imageLegal.src = template.menu.legal;

            imageLegal.onload = function () {
              ctx.drawImage(imageLegal, 20, 1160, 260, 60);
            };
          }, 100);

          setTimeout(() => {
            //* top franja
            const imageTopStripe = new Image();
            imageTopStripe.src = plantilla_menu_franja_titular;

            imageTopStripe.onload = function () {
              ctx.drawImage(imageTopStripe, 0, 50, 1081, 108);
            };

            //* footer franja
            const imageButtonStripe = new Image();
            imageButtonStripe.src = template.menu.stripe;

            imageButtonStripe.onload = function () {
              ctx.drawImage(imageButtonStripe, 0, 1030, 1081, 108);
            };

            //* logo
            /*   if (template.menu.logo) {
              const imageLogo = new Image();
              imageLogo.src = template.menu.logo;
              imageLogo.onload = function () {
                ctx.drawImage(imageLogo, 260, 470, 160, 160);
              };
            } else {
              ctx.fillStyle = "#0399db";
              ctx.fillRect(260, 470, 160, 160);
            } */

            //* legal image
            const imageLegal = new Image();
            imageLegal.src = template.menu.legal;

            imageLegal.onload = function () {
              ctx.drawImage(imageLegal, 20, 1160, 260, 60);
            };
          }, 150);

          setTimeout(() => {
            //* top franja
            const imageTopStripe = new Image();
            imageTopStripe.src = plantilla_menu_franja_titular;

            imageTopStripe.onload = function () {
              ctx.drawImage(imageTopStripe, 0, 50, 1081, 108);
            };
          }, 250);

          setTimeout(() => {
            // title
            if (template.menu.title) {
              const ctxTest = canvas_global.getContext("2d");
              ctxTest.textAlign = "center";
              ctxTest.font = "50px GilroyBold";
              ctxTest.fillStyle = template.menu.titleColor;
              const textWidth = ctx.measureText(template.menu.titleColor).width;

              //ctxTest.fillText(template.menu.title, ((canvas_global.width+60) / 2) - (textWidth / 2), 120);
              getLines(ctx, template.menu.title.toUpperCase(), canvas_global.width).forEach((text, i) =>
                ctx.fillText(text, (canvas_global.width + 60) / 2 - textWidth / 2, 120)
              );
              ctx.textAlign = "start";
            }

            //* logo
            if (template.menu.logo) {
              const imageLogo = new Image();
              imageLogo.src = template.menu.logo;
              imageLogo.onload = function () {
                ctx.drawImage(imageLogo, 260, 470, 160, 160);
              };
            } else {
              if (template.menu.motivo === "navidad") {
                ctx.lineWidth = 5;
                ctx.strokeStyle = "#ffffff80";
                ctx.fillStyle = "#3798dcb3";
                ctx.fillRect(260, 470, 160, 160);
                ctx.strokeRect(260, 470, 160, 160);
              } else {
                ctx.fillStyle = "#0399db";
                ctx.fillRect(260, 470, 160, 160);
              }
            }

            // description simple

            if (template.menu.sections) {
              const { section_1, section_2 } = template.menu.sections;
              const combos_1_section = section_1.combos;
              const combos_2_section = section_2.combos;

              const ctxTitleSections = canvas_global.getContext("2d");
              ctxTitleSections.font = "23px GilroyBold";
              ctxTitleSections.fillStyle = template.menu.titleColor;

              // title section 1
              ctxTitleSections.fillText(section_1.title, 20, 670, 1000);

              // title section 2
              ctxTitleSections.fillText(section_2.title, 350, 670, 1000);

              // const ctxComboName = canvas_global.getContext('2d');
              // ctxComboName.font = '20px GilroyBold';
              // ctxComboName.fillStyle = template.menu.descriptionColor;

              if (template.ref === "simple") {
                const ctxSections = canvas_global.getContext("2d");
                ctxSections.font = "16px GilroyMedium";
                ctxSections.fillStyle = template.menu.descriptionColor;

                // combo 1
                ctxSections.fillText(combos_1_section[0].name, 20, 720, 1000);
                ctxSections.fillText(`| $${combos_1_section[0].price}`, 280, 720, 1000);
                getLines(ctxSections, combos_1_section[0].description, 270).forEach((text, i) => ctxSections.fillText(text, 20, 20 * i + 745));

                // combo 2
                ctxSections.fillText(combos_1_section[1].name, 20, 845, 1000);
                ctxSections.fillText(`| $${combos_1_section[1].price}`, 280, 845, 1000);
                getLines(ctxSections, combos_1_section[1].description, 270).forEach((text, i) => ctxSections.fillText(text, 20, 20 * i + 865));

                // combo 3
                ctxSections.fillText(combos_2_section[0].name, 350, 720, 1000);
                ctxSections.fillText(`| $${combos_2_section[0].price}`, 610, 720, 1000);
                getLines(ctxSections, combos_2_section[0].description, 270).forEach((text, i) => ctxSections.fillText(text, 350, 20 * i + 745));

                // combo 4
                ctxSections.fillText(combos_2_section[1].name, 350, 845, 1000);
                ctxSections.fillText(`| $${combos_2_section[1].price}`, 610, 845, 1000);
                getLines(ctxSections, combos_2_section[1].description, 270).forEach((text, i) => ctxSections.fillText(text, 350, 20 * i + 865));
              } else {
                const ctxSections = canvas_global.getContext("2d");
                ctxSections.font = "12.5px GilroyMedium";
                ctxSections.fillStyle = template.menu.descriptionColor;

                // combo 1
                ctxSections.fillText(combos_1_section[0].name, 20, 700, 1000);
                ctxSections.fillText(`| $${combos_1_section[0].price}`, 285, 700, 1000);
                getLines(ctxSections, combos_1_section[0].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 716));

                // combo 2
                ctxSections.fillText(combos_1_section[1].name, 20, 770, 1000);
                ctxSections.fillText(`| $${combos_1_section[1].price}`, 285, 770, 1000);
                getLines(ctxSections, combos_1_section[1].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 785));

                // combo 3
                ctxSections.fillText(combos_1_section[2].name, 20, 840, 1000);
                ctxSections.fillText(`| $${combos_1_section[2].price}`, 285, 840, 1000);
                getLines(ctxSections, combos_1_section[2].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 855));

                // combo 4
                ctxSections.fillText(combos_1_section[3].name, 20, 910, 1000);
                ctxSections.fillText(`| $${combos_1_section[3].price}`, 285, 910, 1000);
                getLines(ctxSections, combos_1_section[3].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 925));

                // combo 5
                ctxSections.fillText(combos_2_section[0].name, 350, 700, 1000);
                ctxSections.fillText(`| $${combos_2_section[0].price}`, 615, 700, 1000);
                getLines(ctxSections, combos_2_section[0].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 716));

                // combo 6
                ctxSections.fillText(combos_2_section[1].name, 350, 770, 1000);
                ctxSections.fillText(`| $${combos_2_section[1].price}`, 615, 770, 1000);
                getLines(ctxSections, combos_2_section[1].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 785));

                // combo 7
                ctxSections.fillText(combos_2_section[2].name, 350, 840, 1000);
                ctxSections.fillText(`| $${combos_2_section[2].price}`, 615, 840, 1000);
                getLines(ctxSections, combos_2_section[2].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 855));

                // combo 8
                ctxSections.fillText(combos_2_section[3].name, 350, 910, 1000);
                ctxSections.fillText(`| $${combos_2_section[3].price}`, 615, 910, 1000);
                getLines(ctxSections, combos_2_section[3].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 925));
              }
            }

            //* botellas image
            const imageBotellas = new Image();

            if (template.menu.motivo === "navidad") {
              imageBotellas.src = "";
            } else {
              imageBotellas.src = plantilla_menu_botellas;

              imageBotellas.onload = function () {
                ctx.drawImage(imageBotellas, 285, 975, 220, 270);
                // ctx.drawImage(imageBotellas, 300, 965, 187, 281);
              };
            }

            //* footer logo image
            const imageLogoFooter = new Image();

            if (template.menu.motivo === "navidad") {
              imageLogoFooter.src = "";
            } else {
              imageLogoFooter.src = template.menu.brandLogo;

              imageLogoFooter.onload = function () {
                ctx.drawImage(imageLogoFooter, 470, 970, 230, 254);
              };
            }

            //contacto////////////
            if (template.menu.contact) {
              if (template.menu.contactosSel.length === 1) {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                ctxTitle.font = "21px 'GilroyBold'";
                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(template.menu.contactosSel[0]);
                imageContact1.onload = function () {
                  ctx.drawImage(imageContact1, 20, 1050, 30, 30);
                  ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1070, 180, 50);
                };
              } else {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                ctxTitle.font = "21px 'GilroyBold'";

                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(template.menu.contactosSel[0]);
                imageContact1.onload = function () {
                  if (template.menu.motivo === "navidad") {
                    ctx.drawImage(imageContact1, 20, 1065, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1085, 180, 50);
                  } else {
                    ctx.drawImage(imageContact1, 20, 1050, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1070, 180, 50);
                  }
                };
                const ctxTitle2 = canvas_global.getContext("2d");
                ctxTitle2.fillStyle = "white";
                ctxTitle2.fontWeight = 120;
                ctxTitle2.font = "21px 'GilroyBold'";
                const imageContact2 = new Image();
                imageContact2.src = callBack_contactImage(template.menu.contactosSel[1]);
                imageContact2.onload = function () {
                  if (template.menu.motivo === "navidad") {
                    ctx.drawImage(imageContact2, 20, 1096, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[1]], 53, 1116, 180, 40);
                  } else {
                    ctx.drawImage(imageContact2, 20, 1090, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[1]], 53, 1110, 180, 40);
                  }
                };
              }
            }

            ///
            //cinta navidad

            const imageCinta = new Image();
            imageCinta.src = template.menu.cinta;
            imageCinta.onload = function () {
              ctx.drawImage(imageCinta, 0, 0, 1051, 53);
            };
          }, 450);

          ctx.drawImage(imagefinal, 0, 0, canvas_global.width, canvas_global.height);
        } else {
          //cinta navidad
          setTimeout(() => {
            const imageCinta = new Image();
            imageCinta.src = template.menu.cinta;
            imageCinta.onload = function () {
              ctx.drawImage(imageCinta, 0, 0, 1051, 53);
            };
          }, 200);
          //imageUser
          setTimeout(() => {
            const imageFood = new Image();
            imageFood.src = template.menu.food;
            imageFood.onload = async function () {
              return await ctx.drawImage(this, 0, 0, 700, 586);
            };
          }, 100);

          setTimeout(() => {
            //* footer franja
            const imageButtonStripe = new Image();
            imageButtonStripe.src = template.menu.stripe;

            imageButtonStripe.onload = function () {
              ctx.drawImage(imageButtonStripe, 0, 1030, 1081, 108);
            };

            //* legal image
            const imageLegal = new Image();
            imageLegal.src = template.menu.legal;

            imageLegal.onload = function () {
              ctx.drawImage(imageLegal, 20, 1160, 260, 60);
            };
          }, 50);

          setTimeout(() => {
            //* top franja
            const imageTopStripe = new Image();
            imageTopStripe.src = plantilla_menu_franja_titular;

            imageTopStripe.onload = function () {
              ctx.drawImage(imageTopStripe, 0, 50, 1081, 108);
            };

            //* footer franja
            const imageButtonStripe = new Image();
            imageButtonStripe.src = template.menu.stripe;

            imageButtonStripe.onload = function () {
              ctx.drawImage(imageButtonStripe, 0, 1030, 1081, 108);
            };

            //* logo
            if (template.menu.logo) {
              const imageLogo = new Image();
              imageLogo.src = template.menu.logo;
              imageLogo.onload = function () {
                ctx.drawImage(imageLogo, 260, 470, 160, 160);
              };
            } else {
              ctx.fillStyle = "#0399db";
              ctx.fillRect(260, 470, 160, 160);
            }

            //* legal image
            const imageLegal = new Image();
            imageLegal.src = template.menu.legal;

            imageLegal.onload = function () {
              ctx.drawImage(imageLegal, 20, 1160, 260, 60);
            };
          }, 100);

          setTimeout(() => {
            //* top franja
            const imageTopStripe = new Image();
            imageTopStripe.src = plantilla_menu_franja_titular;

            imageTopStripe.onload = function () {
              ctx.drawImage(imageTopStripe, 0, 50, 1081, 108);
            };
          }, 200);

          setTimeout(() => {
            // title
            if (template.menu.title) {
              const ctxTest = canvas_global.getContext("2d");
              ctxTest.textAlign = "center";
              ctxTest.font = "50px GilroyBold";
              ctxTest.fillStyle = template.menu.titleColor;
              const textWidth = ctx.measureText(template.menu.titleColor).width;

              //ctxTest.fillText(template.menu.title, ((canvas_global.width+60) / 2) - (textWidth / 2), 120);
              getLines(ctx, template.menu.title.toUpperCase(), canvas_global.width).forEach((text, i) =>
                ctx.fillText(text, (canvas_global.width + 60) / 2 - textWidth / 2, 120)
              );
              ctx.textAlign = "start";
            }

            //* logo
            if (template.menu.logo) {
              const imageLogo = new Image();
              imageLogo.src = template.menu.logo;
              imageLogo.onload = function () {
                ctx.drawImage(imageLogo, 260, 470, 160, 160);
              };
            } else {
              ctx.fillStyle = "#0399db";
              ctx.fillRect(260, 470, 160, 160);
            }

            // description simple

            if (template.menu.sections) {
              const { section_1, section_2 } = template.menu.sections;
              const combos_1_section = section_1.combos;
              const combos_2_section = section_2.combos;

              const ctxTitleSections = canvas_global.getContext("2d");
              ctxTitleSections.font = "23px GilroyBold";
              ctxTitleSections.fillStyle = template.menu.titleColor;

              // title section 1
              ctxTitleSections.fillText(section_1.title, 20, 670, 1000);

              // title section 2
              ctxTitleSections.fillText(section_2.title, 350, 670, 1000);

              // const ctxComboName = canvas_global.getContext('2d');
              // ctxComboName.font = '20px GilroyBold';
              // ctxComboName.fillStyle = template.menu.descriptionColor;

              if (template.ref === "simple") {
                const ctxSections = canvas_global.getContext("2d");
                ctxSections.font = "16px GilroyMedium";
                ctxSections.fillStyle = template.menu.descriptionColor;
                // combo 1
                ctxSections.fillText(combos_1_section[0].name, 20, 720, 1000);
                ctxSections.fillText(combos_1_section[0].price.length > 0 ? `| $${combos_1_section[0].price}` : "", 280, 720, 1000);
                getLines(ctxSections, combos_1_section[0].description, 270).forEach((text, i) => ctxSections.fillText(text, 20, 20 * i + 745));

                // combo 2
                ctxSections.fillText(combos_1_section[1].name, 20, 845, 1000);
                ctxSections.fillText(combos_1_section[1].price.length > 0 ? `| $${combos_1_section[1].price}` : "", 280, 845, 1000);
                getLines(ctxSections, combos_1_section[1].description, 270).forEach((text, i) => ctxSections.fillText(text, 20, 20 * i + 865));

                // combo 3
                ctxSections.fillText(combos_2_section[0].name, 350, 720, 1000);
                ctxSections.fillText(combos_2_section[0].price.length > 0 ? `| $${combos_2_section[0].price}` : "", 610, 720, 1000);
                getLines(ctxSections, combos_2_section[0].description, 270).forEach((text, i) => ctxSections.fillText(text, 350, 20 * i + 745));

                // combo 4
                ctxSections.fillText(combos_2_section[1].name, 350, 845, 1000);
                ctxSections.fillText(combos_2_section[1].price.length > 0 ? `| $${combos_2_section[1].price}` : "", 610, 845, 1000);
                getLines(ctxSections, combos_2_section[1].description, 270).forEach((text, i) => ctxSections.fillText(text, 350, 20 * i + 865));
              } else {
                const ctxSections = canvas_global.getContext("2d");
                ctxSections.font = "12.5px GilroyMedium";
                ctxSections.fillStyle = template.menu.descriptionColor;

                // combo 1
                ctxSections.fillText(combos_1_section[0].name, 20, 700, 1000);
                ctxSections.fillText(combos_1_section[0].price.length > 0 ? `| $${combos_1_section[0].price}` : "", 285, 700, 1000);
                getLines(ctxSections, combos_1_section[0].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 716));

                // combo 2
                ctxSections.fillText(combos_1_section[1].name, 20, 770, 1000);
                ctxSections.fillText(combos_1_section[1].price.length > 0 ? `| $${combos_1_section[1].price}` : "", 285, 770, 1000);
                getLines(ctxSections, combos_1_section[1].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 785));

                // combo 3
                ctxSections.fillText(combos_1_section[2].name, 20, 840, 1000);
                ctxSections.fillText(combos_1_section[2].price.length > 0 ? `| $${combos_1_section[2].price}` : "", 285, 840, 1000);
                getLines(ctxSections, combos_1_section[2].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 855));

                // combo 4
                ctxSections.fillText(combos_1_section[3].name, 20, 910, 1000);
                ctxSections.fillText(combos_1_section[3].price.length > 0 ? `| $${combos_1_section[3].price}` : "", 285, 910, 1000);
                getLines(ctxSections, combos_1_section[3].description, 260).forEach((text, i) => ctxSections.fillText(text, 20, 16 * i + 925));

                // combo 5
                ctxSections.fillText(combos_2_section[0].name, 350, 700, 1000);
                ctxSections.fillText(combos_2_section[0].price ? `| $${combos_2_section[0].price}` : "", 615, 700, 1000);
                getLines(ctxSections, combos_2_section[0].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 716));

                // combo 6
                ctxSections.fillText(combos_2_section[1].name, 350, 770, 1000);
                ctxSections.fillText(combos_2_section[1].price.length > 0 ? `| $${combos_2_section[1].price}` : "", 615, 770, 1000);
                getLines(ctxSections, combos_2_section[1].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 785));

                // combo 7
                ctxSections.fillText(combos_2_section[2].name, 350, 840, 1000);
                ctxSections.fillText(combos_2_section[2].price.length > 0 ? `| $${combos_2_section[2].price}` : "", 615, 840, 1000);
                getLines(ctxSections, combos_2_section[2].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 855));

                // combo 8
                ctxSections.fillText(combos_2_section[3].name, 350, 910, 1000);
                ctxSections.fillText(combos_2_section[3].price.length > 0 ? `| $${combos_2_section[3].price}` : "", 615, 910, 1000);
                getLines(ctxSections, combos_2_section[3].description, 260).forEach((text, i) => ctxSections.fillText(text, 350, 16 * i + 925));
              }
            }

            //* botellas image
            const imageBotellas = new Image();

            if (template.menu.motivo === "navidad") {
              imageBotellas.src = "";
            } else {
              imageBotellas.src = plantilla_menu_botellas;
              imageBotellas.onload = function () {
                ctx.drawImage(imageBotellas, 300, 965, 187, 281);
              };
            }

            ///////

            //* footer logo image
            const imageLogoFooter = new Image();
            imageLogoFooter.src = template.menu.brandLogo;

            imageLogoFooter.onload = function () {
              ctx.drawImage(imageLogoFooter, 470, 970, 230, 254);
            };

            //contacto
            if (template.menu.contact) {
              if (template.menu.contactosSel.length === 1) {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                ctxTitle.font = "21px 'GilroyBold'";
                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(template.menu.contactosSel[0]);
                imageContact1.onload = function () {
                  /*  ctx.drawImage(imageContact1, 20, 1050, 30, 30);
                  ctx.fillText(
                    template.menu.contact[template.menu.contactosSel[0]],
                    53,
                    1070,
                    180,
                    50
                  ); */
                  ////////final
                  if (template.menu.motivo === "navidad") {
                    ctx.drawImage(imageContact1, 20, 1065, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1085, 180, 50);
                  } else {
                    ctx.drawImage(imageContact1, 20, 1050, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1070, 180, 50);
                  }
                };
              } else {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                ctxTitle.font = "21px 'GilroyBold'";

                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(template.menu.contactosSel[0]);
                imageContact1.onload = function () {
                  if (template.menu.motivo === "navidad") {
                    ctx.drawImage(imageContact1, 20, 1065, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1085, 180, 50);
                  } else {
                    ctx.drawImage(imageContact1, 20, 1050, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[0]], 53, 1070, 180, 50);
                  }
                  /*   ctx.drawImage(imageContact1, 20, 1050, 28, 28);
                  ctx.fillText(
                    template.menu.contact[template.menu.contactosSel[0]],
                    53,
                    1070,
                    180,
                    50
                  ); */
                };
                const ctxTitle2 = canvas_global.getContext("2d");
                ctxTitle2.fillStyle = "white";
                ctxTitle2.fontWeight = 120;
                ctxTitle2.font = "21px 'GilroyBold'";
                const imageContact2 = new Image();
                imageContact2.src = callBack_contactImage(template.menu.contactosSel[1]);
                imageContact2.onload = function () {
                  if (template.menu.motivo === "navidad") {
                    ctx.drawImage(imageContact2, 20, 1096, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[1]], 53, 1116, 180, 40);
                  } else {
                    ctx.drawImage(imageContact2, 20, 1120, 28, 28);
                    ctx.fillText(template.menu.contact[template.menu.contactosSel[1]], 53, 1140, 180, 40);
                  }
                };
                /*   ctx.drawImage(imageContact2, 20, 1090, 28, 28);
                  ctx.fillText(
                    template.menu.contact[template.menu.contactosSel[1]],
                    53,
                    1110,
                    180,
                    40
                  ); */
              }
            }
          }, 300);

          setTimeout(() => {
            ctx.drawImage(imagefinal, 0, 0, canvas_global.width, canvas_global.height);
          }, 50);
        }
      };

      if (final_imagen) {
        setTimeout(() => {
          var image = canvas_global.toDataURL("image/png").replace("image/png", "image/octet-stream");
          setDescImage(image);
        }, 1000);
      }
    },
    [final_imagen]
  );

  useEffect(() => {
    if (finalTemplate) mergeTemplate(finalTemplate);
  }, [finalTemplate, final_imagen, mergeTemplate]);

  return !final_imagen ? (
    <Container>
      <Sidebar landscape={landscape} />

      <GridBinding>{finalTemplate ? <Canvas2D id="canvas" height={1244} width={700} /> : <BackImageComboRect src={imageBackRect} />}</GridBinding>
    </Container>
  ) : (
    finalTemplate && (
      <ContainerDescarga>
        <SubContainerDescarga>
          <GridLeft>
            <ContainerImages style={{ paddingLeft: 50 }}>
              <ImageText src={final_image} />
              {landscape && <ImageSmile src={smile_image} />}
            </ContainerImages>

            <ContainerIcons>
              <ImageIcon src={fb_image} />
              <ImageIcon src={wsp_image} />
              <ImageIcon src={inst_image} />
            </ContainerIcons>
          </GridLeft>

          <ImageWrapper>
            <div
              style={{
                width: "100%",
                height: landscape ? "70%" : "100%",
                position: "relative",
                left: "0px",
                top: "0px",
              }}
            >
              <img
                src={phone}
                class="eye"
                alt=""
                style={{
                  position: "absolute",
                  height: landscape ? "100%" : "100%",
                  left: landscape ? "30%" : "50%",
                }}
              />

              <Canvas2DDescarga id="canvas" height={1244} width={700} size={"84%"} top={"10%"} />
              <div
                style={{
                  display: "flex",
                  flex: 0.4,
                  height: landscape ? "auto" : "80px",
                }}
              >
                <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
              </div>
            </div>

            <GridButtons>
              {landscape && (
                <div style={{ display: "flex", flex: 0.3 }}>
                  <BackButton
                    onClick={() => {
                      dispatch(createMenu.setImagenFinal(false));
                      dispatch(createMenu.setEtapa("contact"));
                    }}
                  >
                    {" "}
                    ATRAS
                  </BackButton>
                </div>
              )}

              {landscape && (
                <div
                  style={{
                    display: "flex",
                    flex: 0.4,
                    height: landscape ? "auto" : "80px",
                  }}
                >
                  <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
                </div>
              )}

              {landscape && (
                <div style={{ display: "flex", flex: 0.3 }}>
                  <HomeButton
                    onClick={() => {
                      dispatch(createMenu.setImagenFinal(false));
                      dispatch(createMenu.resetTemplate());
                    }}
                  >
                    VOLVER AL INICIO
                  </HomeButton>
                </div>
              )}
            </GridButtons>
          </ImageWrapper>
        </SubContainerDescarga>
        {landscape && <Footer />}
      </ContainerDescarga>
    )
  );
};
