import React, { useState, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//import { AES, enc } from "crypto-js";

import { Container, ContainerButton, FormLogin, GlassBtn, Input, Label, Title, ErrorMessage } from "./styles";
//* components
import TipLeft from "../../components/TipLeftPicHome";
import Footer from "../../components/Footer";
import Snackbar from "@material-ui/core/Snackbar";

//* actions
import { loginUser } from "../../redux/credentials/credentials";
import { appFB } from "../../config/firebase";

import { setColors } from "../../redux/colors/actions";
import { Button } from "@material-ui/core";

const auth = getAuth();

export const Login = ({ history }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const [openAlert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const [landscape, setLandscape] = useState(window.innerWidth > 700);

  useLayoutEffect(() => {
    const updateSize = () => setLandscape(window.innerWidth > 700);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const onSubmit = async ({ email, password }) => {
    setLoading(true);

    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch(loginUser(user));
        console.log(user, "login user 43");
        dispatch(
          setColors({
            primary: "#93a31c",
            secondary: "#d6aa26",
            border: "#d916b8",
            button: "#408156",
            input: "#535d7f",
            background: "#cac4c4ff", //usar colores muy claros
            text: "#070707",
            logo: "rgba(119, 18, 102, 0.5)",
          })
        );
        //seguarda token el local storage
        /*   const envryptedObject = AES.encrypt(
          JSON.stringify(user.accessToken),
          "MYKEY91/"
        ); 
        console.log(user) */
        localStorage.setItem("data", user.accessToken);

        history.push("/metrics");
      })

      .catch((error) => {
        //no se usan
        //  const errorCode = error.code;
        //  const errorMessage = error.message;
        setAlert(true);
      });
    setLoading(false);
  };

  return (
    <Container>
      <Snackbar
        open={openAlert}
        color="warning"
        onClose={() => setAlert(false)}
        message="Credenciales incorrectas, intentelo nuevamente."
        key="keyy"
        style={{ position: "absolute", top: "50%" }}
      />

      <div style={{ display: "flex", flex: 0.93, flexDirection: "row" }}>
        {landscape ? <TipLeft /> : null}

        <FormLogin onSubmit={handleSubmit(onSubmit)}>
          <Title>Ingresá</Title>
          <Label>Usuario</Label>
          <Input
            name="email"
            type="email"
            ref={register({
              required: "Campo obligatorio",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Dirección de correo inválida.",
              },
            })}
          />
          {errors.email && errors.email.message && <ErrorMessage>{errors.email.message}</ErrorMessage>}

          <Label>Contraseña</Label>
          <Input
            name="password"
            type="password"
            ref={register({
              required: "Campo obligatorio",
              minLength: {
                value: 5,
                message: "La contraseña tiene que tener mas de 5 caracteres.",
              },
            })}
          />
          {errors.password && errors.password.message && <ErrorMessage>Debe tener al menos 6 caracteres </ErrorMessage>}

          <Label style={{ cursor: "pointer" }} onClick={() => history.push("/account/recoverpassword")}>
            ¿Olvidaste tu contraseña?
          </Label>

          <ContainerButton>
            <GlassBtn type="submit">{loading ? <CircularProgress size={20} color="secondary" /> : "CONTINUAR"}</GlassBtn>

            <Label style={{ cursor: "pointer", marginLeft: 15 }}>
              <span style={{ fontWeight: "bold" }} onClick={() => history.push("/account/register")}>
                ¿No tenés cuenta? Registrate.
              </span>
            </Label>
          </ContainerButton>
        </FormLogin>
      </div>

      <Footer />
    </Container>
  );
};
