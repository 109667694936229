import axios from 'axios';

export const GET_ALL_COMBOS = 'GET_ALL_COMBOS';
export const GET_ALL_COMBOS_FORMAT = 'GET_ALL_COMBOS_FORMAT';
export const GET_ALL_COMBOS_TYPE = 'GET_ALL_COMBOS_TYPE';
export const GET_ALL_MENU = 'GET_ALL_MENU';
export const GET_ALL_MENU_TYPE = 'GET_ALL_MENU_TYPE';
export const GET_ALL_QR = 'GET_ALL_QR';

export const getAllCombos = () => ({
  type: GET_ALL_COMBOS,
  payload: new Promise((res, rej) => {
    axios
      .get('/comboMetrics/all-combos')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllCombosFormat = () => ({
  type: GET_ALL_COMBOS_FORMAT,
  payload: new Promise((res, rej) => {
    axios
      .get('/comboMetrics/all-combos-by-format')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllCombosType = () => ({
  type: GET_ALL_COMBOS_TYPE,
  payload: new Promise((res, rej) => {
    axios
      .get('/comboMetrics/all-combos-by-type')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllMenu = () => ({
  type: GET_ALL_MENU,
  payload: new Promise((res, rej) => {
    axios
      .get('/menuMetrics/all-menus')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllMenuType = () => ({
  type: GET_ALL_MENU_TYPE,
  payload: new Promise((res, rej) => {
    axios
      .get('/menuMetrics/all-menus-by-type')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllQR = () => ({
  type: GET_ALL_QR,
  payload: new Promise((res, rej) => {
    axios
      .get('/qrMetrics/all-qrs')
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});
