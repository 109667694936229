import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// import CreateCombo from '../pages/create-combo';
// import CreateQR from '../pages/comboQR';
// import CreateMenu from '../pages/createMenu';
// import Home from '../pages/home';
import { Metrics } from "../pages/metrics/views";

const PrivateRoutes = function () {
  const isAdmin = useSelector(
    (state) => state.credentials.userLogueado.isAdmin
  );

  return (
    <div style={{ display: "flex", height: "90%" }}>
      {isAdmin && (
        <Switch>
          <Route exact path="/private/metrics" component={Metrics} />

          <Route exact path="*">
            <Redirect to="/private/metrics" />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default PrivateRoutes;
