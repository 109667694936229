import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalContainer = styled.div`
  position: absolute;
  width: 400;
  background-color: ${(props) => props.theme.colors.background};
  position: absolute;
  top: 15%;
  left: 25%;
  width: 50%;
  background-color: ${(props) => props.theme.colors.background};
  padding: 50px 50px 50px 50px;
`;
