import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { combo } from '../../utils/actions';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

//* components
import AlertComponent from '../../../../components/common/alert';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import GlobalButton from '../../../../components/common/GlobalButton';
import Tips from '../../../../components/Tips';

//* styles
import {
  Container,
  Form,
  TextArea,
  Input,
  Label,
  ButtonContainer,
  ReadingSection,
  Divider,
  TittleContainer,
} from './styles';

export const Description = ({ landscape }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const plantillaCombo = useSelector((state) => state.combo.plantilla);
  const exists = useSelector((state) => state.combo.setSelUserDescripcion);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const [textInput, setTextInput] = useState(plantillaCombo?.combo?.descripcion.descp_combo);

  const onChancheTextInput = (e) => {
    setTextInput(e.target.value);
  };

  const onSubmit = (values) => {
    dispatch(combo.setSelUserDescripcion(true));
    dispatch(combo.setDescripcion(values));
    dispatch(combo.setEtapa('BEBIDA'));
  };

  const onError = () => {
    setAlert(true);
  };

  return (
    <Container>
      <AlertComponent
        open={openAlert}
        color="warning"
        onClose={handleClose}
        message="Debes completar todos los campos."
      />

      <ReadingSection>
        <TittleContainer>
          <Title text="Describí tu combo" />
        </TittleContainer>

        <Subtitle text="Recordá que el nombre de la bebida aparecerá en el próximo paso una vez que las selecciones." />

        <ButtonContainer>
          <Subtitle text="Completá los campos" />
          <GlobalButton
            content="SIGUIENTE"
            disabled={Object.keys(errors).length}
            onClick={handleSubmit(onSubmit, onError)}
          />
        </ButtonContainer>
      </ReadingSection>

      {!landscape && <Divider />}

      <Form>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '70%' }}>
            <Label htmlFor="titulo">Título</Label>
            <Input
              onChange={() => {}}
              width={'80%'}
              name="titulo_combo"
              placeholder={plantillaCombo?.combo?.descripcion.titulo_combo}
              defaultValue={exists ? plantillaCombo.combo.descripcion.titulo_combo : ''}
              type="text"
              ref={register({ required: true })}
              maxLength={22}
            />
          </div>

          <div style={{ width: '150px' }}>
            <Label htmlFor="precio">
              Precio{' '}
              <span
                style={{
                  fontSize: '12px',
                }}>
                (opcional)
              </span>
            </Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: -21,
              }}>
              <AttachMoneyIcon />
              <Input
                onChange={() => {}}
                width={'100%'}
                name="precio"
                placeholder={plantillaCombo?.combo?.descripcion.precio}
                defaultValue={exists ? plantillaCombo.combo.descripcion.precio : ''}
                ref={register({ required: false })}
                type="number"
                maxLength={4}
              />
            </div>
          </div>
        </div>

        <Label htmlFor="titulo_combo">Título del combo</Label>
        <Input
          onChange={() => {}}
          width={'100%'}
          name="titulo_comida"
          placeholder={plantillaCombo?.combo?.descripcion.titulo_comida}
          defaultValue={exists ? plantillaCombo.combo.descripcion.titulo_comida : ''}
          ref={register({ required: true })}
          type="text"
          maxLength={34}
        />

        <Label htmlFor="descp_combo">Descripción del combo ({textInput?.length}/120)</Label>
        <TextArea
          placeholder={plantillaCombo?.combo?.descripcion.descp_combo}
          defaultValue={exists ? plantillaCombo.combo.descripcion.descp_combo : ''}
          style={{
            width: '95%',
            height: '3em',
            padding: '6px',
            paddingLeft: '12px',
          }}
          name="descp_combo"
          ref={register({ required: true })}
          onChange={onChancheTextInput}
          maxLength={120}
          rows="10"
          cols="25"
        />
      </Form>

      {landscape && (
        <div style={{ flex: 2 }}>
          <Tips
            texts={[
              '· Describí tu plato o combo buscando tentar.',
              '· Destacá los diferenciales en tu plato, los que hacen que sea distinto.',
            ]}
          />
        </div>
      )}
    </Container>
  );
};
