import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { createMenu } from "../../redux/actions";

//* components
import AlertComponent from "../../../../components/common/alert";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import Tips from "../../../../components/Tips";
import Checkbox from "../../../../components/Checkbox";
//* styles
import { Container, CustomLabel, CustomLabelLeft, TittleContainer } from "./styles";

export const Logo = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const exist = useSelector((state) => state.createMenu.setSelUserLogo);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const checked = useSelector((state) => state.createMenu.setSetCheckLogo);

  const handleCheck = () => {
    dispatch(createMenu.setSetCheckLogo(!checked));

    if (checked) {
      dispatch(createMenu.setSelUserLogo(false));
    } else {
      dispatch(createMenu.setSelUserLogo(true));
      dispatch(createMenu.setLogo({}));
    }
  };

  const onSubmit = (values) => {
    if (checked || Object.keys(values.logo).length > 0) {
      dispatch(createMenu.setEtapa("description"));
      dispatch(createMenu.setSelUserLogo(true));
    } /*  else if (exist) dispatch(createMenu.setEtapa("description")); */ else setAlert(true);
  };

  const onChangeLogo = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        dispatch(createMenu.setLogo(reader.result));
        dispatch(createMenu.setSelUserLogo(true));
        dispatch(createMenu.setSetCheckLogo(false));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      <div>
        <TittleContainer>
          <Title text="Cargá tu logo" />
        </TittleContainer>

        <Subtitle notmr text={"· Sólo archivos .jpg y .png"} />
        <Subtitle notmr text={"· Peso máximo 350kb."} />
      </div>

      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione algun logo de su galeria." />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ disply: "flex", height: "30px" }}>
          <div style={{ display: "flex", flexDirection: "row", height: "30px" }}>
            <CustomLabelLeft>archivo.jpg</CustomLabelLeft>
            <CustomLabel htmlFor="logo">EXAMINAR...</CustomLabel>
          </div>

          <input id="logo" name="logo" ref={register} type="file" onChange={onChangeLogo} style={{ display: "none" }} />
        </div>
        <label style={{ lineHeight: 0 }}>
          <Checkbox checked={checked} onChange={handleCheck} inputWidth="12px" inputHeight="12px" />
          <span
            style={{
              lineHeight: "16px",
              verticalAlign: "middle",
              marginLeft: 8,
              fontFamily: "GilroyMedium",
              fontSize: 16,
            }}
          >
            continuar sin logo
          </span>
        </label>

        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <GlobalButton content="SIGUIENTE" disabled={!exist} />
        </div>
      </form>

      <div>
        <Tips texts={["Verificá que las fotos no estén en baja resolución (pixeladas o fuera de foco)."]} />
      </div>
    </Container>
  );
};
