import styled from "styled-components";
import colors from "../../assets/colors";

//* assets
import registro_fondo_rojo from "../../assets/image/01_inicio_y_registro/01_registro_fondo_rojo.jpg";

export const Container = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  height: 90%;
  background-color:${colors.palette.primary};
  @media (orientation: portrait) {
    justify-content: center;
  }
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.5;
  padding: 0 50px;
  // background-image: url(${registro_fondo_rojo});
  @media (orientation: portrait) {
    padding: 3%;
    flex: 1;
  }
`;

export const Title = styled.p`
  font-family: GilroyBold;
  color: ${colors.palette.secondary};
  font-size: 40px;
  align-self: start;
`;

export const Label = styled.label`
  font-size: 15px;
  font-family: GilroyMedium;
  color: ${colors.palette.secondary};
  align-self: start;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  &:hover {
    color: ${colors.palette.white};
  }
`;
export const GlassBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-size: 14px;
  font-family: GilroyMedium;
  align-items: center;
  color: ${colors.palette.primary};
  padding: 10px;
  height: ${(props) => (props.height ? props.height + "px" : "35px")};
  border: ${(props) => (props.empty ? "none" : `1.5px solid ${colors.palette.primary}`)};
  background: ${colors.palette.secondary};
`;

export const Input = styled.input`
  color: white;
  border: none;
  border-radius: 0;
  padding: 6px;
  font-family: GilroyMedium;
  font-size: 18px;
  font-weight: lighter;
  border-bottom: 1.5px solid ${colors.palette.secondary};
  outline: none;
  background: transparent;
  height: 25px;
  margin: 0px auto;
`;
export const ErrorMessage = styled.p`
  color: white;
  font-family: GilroyMedium;
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 30;
`;
