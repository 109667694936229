import React from "react";
import styled, { useTheme } from "styled-components";
import { useSelector } from "react-redux";

//* components
import SwitchStage from "./switchStage";

import Plantilla from "../../buttons/Template";
import Logo from "../../buttons/Logo";
import Description from "../../buttons/Description";
import Food from "../../buttons/Food";
import Contact from "../../buttons/Contact";

//import FlechaSelect from "../../../../components/common/flechaSelectMenu";
import { ReactSVG } from "react-svg";
import flecha from "../../../../assets/image/02_crear_combo/arrow.svg";
import { darkenColor } from "../../../../utils/functions";

export default function Sidebar({ landscape }) {
  const { stage, template, setSelUserLogo, setSelUserDescripcion, setSelUserContact, setSelUserComida } = useSelector((state) => state.createMenu);
  const theme = useTheme();

  const FlechaSelect = ({ isActive }) => (
    <Container isActive={isActive}>
      <Flecha src={flecha} color={darkenColor(theme.colors.primary, 20)} />
    </Container>
  );
  const SidebarButton = ({ children, isActive }) => (
    <ButtonContainer>
      {children}
      {isActive && <FlechaSelect isActive={isActive} />}
    </ButtonContainer>
  );
  console.log(stage);
  return (
    <GridLayout>
      <GridControl>
        <SidebarButton isActive={stage === "template"}>
          <Plantilla type="createMenu" stage={stage} template={template} setLogo={template} />
        </SidebarButton>

        <SidebarButton isActive={stage === "logo"}>
          <Logo type="createMenu" stage={stage} template={template} setLogo={setSelUserLogo} />
        </SidebarButton>

        <SidebarButton isActive={stage === "description"}>
          <Description type="createMenu" stage={stage} template={template} setSelUserDescripcion={setSelUserDescripcion} />
        </SidebarButton>

        <SidebarButton isActive={stage === "picture"}>
          <Food type="createMenu" stage={stage} template={template} setPic={setSelUserComida} />
        </SidebarButton>

        <SidebarButton isActive={stage === "contact"}>
          <Contact type="createMenu" stage={stage} template={template} setContacto={setSelUserContact} />
        </SidebarButton>
      </GridControl>

      <SwitchStage />
    </GridLayout>
  );
}

export const GridControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100px;
  justify-content: flex-start;
  margin-top: -2px;
  @media screen and (max-width: 700px) {
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    align-items: center;
    order: 1;
  }
`;

export const GridLayout = styled.div`
  display: flex;
  flex: 1;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80px;
  position: relative;
  @media (orientation: portrait) {
    width: 16vw;
  }
`;

export const Flecha = styled(ReactSVG)`
  svg {
    width: 60%;
    height: 60%;
    path {
      fill: ${(props) => props.color || "currentColor"};
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: -10px; /* Ajusta el posicionamiento horizontal de la flecha */
  transform: translateY(-50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)}; /* Muestra la flecha solo si isActive es true */
  transition: opacity 0.3s ease-in-out; /* Agrega una transición para suavizar el cambio de opacidad */
  @media screen and (max-width: 700px) {
    opacity: 0; /* Oculta la flecha en pantallas pequeñas */
  }
`;
