import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { combo } from "../../utils/actions";

//* components
import GlobalButton from "../../../../components/common/GlobalButton";
import AlertComponent from "../../../../components/common/alert";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import { ButtonUploader } from "../../../../components/ButtonFileUpload";
import Dropdown from "../../../../components/Dropdown";

import { MiniPreview } from "./components";

//* styles
import { Container, FormLogin, CustomLabel, CustomLabelLeft, PreviewContainer, Divider, ButtonsContainer, TittleContainer } from "./styles";
import { FoodBank } from "@styled-icons/material-rounded";

export const Comida = ({ landscape }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);

  const comidas = useSelector((state) => state.combo.comidas);
  const plantillaCombo = useSelector((state) => state.combo.plantilla);
  const exists = useSelector((state) => state.combo.setSelUserComida);

  const [selComida, setSelComida] = useState("");
  const [openAlert, setAlert] = useState(false);

  const [ocasion, setOcasion] = useState(null);
  const [listaDeComidas, setListaDeComidas] = useState([]);

  useEffect(() => {
    setListaDeComidas(comidas);
  }, []);

  const comboFilter = useCallback(() => {
    if (ocasion) {
      setListaDeComidas(comidas?.filter((comida) => comida?.ocasion?.indexOf(ocasion) !== -1));
    }
  }, [ocasion, comidas]);

  useEffect(() => {
    if (ocasion) {
      comboFilter();
    } else {
      setListaDeComidas(comidas);
    }
  }, [ocasion, comidas, comboFilter]);

  const handleClose = () => setAlert(false);

  const onSubmit = (values) => {
    if (selComida) dispatch(combo.setEtapa("CONTACTO"));
    else if (exists) dispatch(combo.setEtapa("CONTACTO"));
    //  else setAlert(true);
  };

  const onChangeLogo = (e) => {
    dispatch(combo.setPresetFoodPhoto(false));
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        dispatch(
          combo.setComidaUser({
            id: "pruebaUpload",
            marco: true,
            ref: "rectangular",
            src: reader.result,
          })
        );
        dispatch(combo.setSelUserComida(true));
        setSelComida(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione alguna imagen." />

      <TittleContainer>
        <Title text="Elegí tu comida" />
      </TittleContainer>

      <Subtitle text="Subí tu foto (sólo .jpg, .jpeg y .png, peso máximo de 500kb) o seleccioná una de nuestro banco de imágenes." />

      {!landscape && (
        <div>
          <ButtonUploader selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
          <GlobalButton
            disabled={!exists}
            content="SIGUIENTE"
            onClick={() => {
              if (!exists) setAlert(true);
              else dispatch(combo.setEtapa("CONTACTO"));
            }}
          />
        </div>
      )}

      {!landscape && <Divider />}

      {landscape && (
        <FormLogin onSubmit={handleSubmit(onSubmit)}>
          <div style={{ disply: "flex", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row", height: "30px" }}>
              <CustomLabelLeft>archivo.jpg</CustomLabelLeft>
              <CustomLabel htmlFor="logo">EXAMINAR...</CustomLabel>
            </div>

            <input id="logo" name="comidaUser" ref={register} type="file" onChange={onChangeLogo} style={{ display: "none" }} />
          </div>

          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <GlobalButton
              disabled={!exists}
              content="SIGUIENTE"
              onClick={() => {
                if (!exists) setAlert(true);
              }}
            />
          </div>
        </FormLogin>
      )}
      <ButtonsContainer>
        <Dropdown
          name="OCASIÓN"
          data={[
            {
              name: "Todos",
              action: () => {
                setOcasion(null);
              },
            },
            {
              name: "Almuerzo/Cena",
              action: () => setOcasion("almuerzo/cena"),
            },
            {
              name: "Comidas Rápidas",
              action: () => setOcasion("comidasRapidas"),
            },
            {
              name: "Snack Salado",
              action: () => setOcasion("snackSalado"),
            },
            {
              name: "Snack Dulce",
              action: () => setOcasion("snackDulce"),
            },
          ]}
        />
      </ButtonsContainer>

      {comidas && (
        <PreviewContainer>
          {listaDeComidas
            .filter((c) => c.ref === plantillaCombo.forma)
            .map((food, i) => (
              <MiniPreview
                key={food.id}
                src={food.src}
                name={food.name}
                description={food.description}
                onClick={() => {
                  setSelComida(FoodBank);
                  dispatch(combo.setPresetFoodPhoto(true));
                  //dispatch(combo.setComidaUser(undefined));
                  dispatch(combo.setSelUserComida(true));
                  dispatch(combo.setComida(food));
                }}
              />
            ))}
        </PreviewContainer>
      )}
    </Container>
  );
};
