import React from "react";
import { Switch, Router } from "react-router-dom";
import { useSelector } from "react-redux";
import history from "../history";
import Header from "../components/Header.jsx";
import HeaderMetrics from "../components/HeaderMetrics";
import PrivateRoutes from "./privateRoutes";
import AccountRoutes from "./accountRoutes";
import PrivateRoutesComponent from "./utils/privateRouteComponent";

const RoutesContainer = function () {
  const isAdmin = useSelector((state) => state.credentials.userLogueado.isAdmin);

  return (
    <Router history={history}>
      {history.location.pathname !== "/private/metrics" ? <Header /> : <HeaderMetrics />}
      <Switch>
        <PrivateRoutesComponent pathnameRedirect="/account/home" isAuth={isAdmin} component={PrivateRoutes} path="/private" />

        <PrivateRoutesComponent pathnameRedirect="/private/metrics" isAuth={!isAdmin} component={AccountRoutes} path="/" />
      </Switch>
    </Router>
  );
};

export default RoutesContainer;
