import React, { useState, useRef, useEffect } from "react";

import styled, { useTheme } from "styled-components";
import ButtonSelector from "./ButtonSelector";

function useOutsideAlerter(ref, displayMenu, setDisplayMenu) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) setDisplayMenu(!displayMenu);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, displayMenu, setDisplayMenu]);
}

export default function Dropdown({ data, name }) {
  const wrapperRef = useRef(null);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [checkName, setCheckName] = useState("");
  const theme = useTheme();

  const showDropdownMenu = (e) => {
    e.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  useOutsideAlerter(wrapperRef, displayMenu, setDisplayMenu);

  return (
    <Container>
      <ButtonSelector onClick={showDropdownMenu} content={name} />

      {displayMenu && (
        <List ref={wrapperRef}>
          {data.map((elemento, i) => (
            <Items
              theme={theme}
              key={i}
              onClick={(e) => {
                elemento.action();
                setCheckName(elemento.name);
                showDropdownMenu(e);
                setCheckName(elemento.name);
              }}
            >
              <ItemLabel theme={theme}>
                <CheckboxContainer>
                  <HiddenCheckbox checked={elemento.name === checkName} />
                </CheckboxContainer>
                <span>{elemento.name}</span>
              </ItemLabel>
            </Items>
          ))}
        </List>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  position: relative;
`;

const List = styled.div`
  background: white;
  list-style-type: none;
  font-family: Gilroy;
  position: absolute;
  top: 52px;
  margin: 0;
  border-radius: 2px;
  max-height: 300px;
  width: 250px;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
`;
const ItemLabel = styled.div`
  line-height: 2;
  text-align: left;
  display: block;
  color: ${(props) => props.theme.colors.border};
  font-size: 14px;
  font-weight: 200;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: 13px;
  margin-top: 9px;
`;

const Items = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  border-bottom: 0.5px ${(props) => props.theme.colors.border} solid;
  font-family: GilroyMedium;
  font-weight: 500;
  border-radius: 2px;
  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
`;
