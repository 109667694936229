import React from "react";
import styled, { useTheme } from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.colors.background};
  stroke-width: 4px;
  display: flex;
  align-items: "center";
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  //clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${(props) => props.inputWidth || "12px"};
  height: ${(props) => props.inputHeight || "12px"};
  background: ${(props) => (props.checked ? props.theme.colors.primary : props.theme.colors.background)};
  border-radius: 3px;
  border-color: ${(props) => props.theme.colors.text};
  transition: all 150ms;
  border: 2px solid ${(props) => props.theme.colors.primary};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px grey;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({ className, checked, ...props }) => {
  const theme = useTheme();
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} {...props}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
