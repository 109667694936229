import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { comboQR } from "../../utils/actions";

//COMPONENTS
import AlertComponent from "../../../../components/common/alert";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import GlobalButton from "../../../../components/common/GlobalButton";

//STYLES
import { Container, Form, Input, Label, SubtitleD, TittleContainer } from "./styles";

export const LinkQR = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const plantillaCombo = useSelector((state) => state.comboQR.template);
  const [openAlert, setAlert] = useState(false);
  const exists = useSelector((state) => state.comboQR.setSelUrl);
  const [textInput, setTextInput] = useState(plantillaCombo?.combo?.url);

  const onChancheTextInput = (e) => {
    setTextInput(e.target.value);
    if (e.target.value.length > 0) {
      dispatch(comboQR.setSelUrl(true));
    }
  };

  const onSubmit = (values) => {
    dispatch(comboQR.setSelUrl(true));
    dispatch(comboQR.setLink(values.link_combo));
    dispatch(comboQR.setEtapa("contact"));
  };

  const handleClose = (event, reason) => setAlert(false);

  return (
    <Container>
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Debes agregar un link." />

      {plantillaCombo?.combo?.url && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <TittleContainer>
              <Title text="Ingresá tu enlace" />
            </TittleContainer>

            <Subtitle
              notmr
              text={"Escribí el enlace de tu página web o alguna de tus redes sociales para que tus clientes puedan ingresar leyendo tu propio QR."}
            />
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2%",
              }}
            >
              <SubtitleD>Completá los campos</SubtitleD>
              <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <GlobalButton content="SIGUIENTE" disabled={!exists} onClick={() => errors.link_combo && setAlert(true)} />
              </div>
            </div>

            <div>
              <Label htmlFor="link_combo">Enlace</Label>
              <Input
                placeholder={plantillaCombo?.combo?.url}
                defaultValue={exists ? textInput : ""}
                name="link_combo"
                ref={register({ required: true })}
                onChange={onChancheTextInput}
              />
            </div>
          </Form>
        </div>
      )}
    </Container>
  );
};
