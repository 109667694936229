import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

//* stages de QR
import { Contact, Description, LinkQR, Logo, Food, Template } from "../views";

export default function ComboLayout() {
  const etapaCombo = useSelector((state) => state.comboQR.stage);

  const renderLayout = () => {
    switch (etapaCombo) {
      case "template":
        return <Template />;
      case "logo":
        return <Logo />;
      case "description":
        return <Description />;
      case "picture":
        return <Food />;
      case "link":
        return <LinkQR />;
      case "contact":
        return <Contact />;
      default:
        break;
    }
  };

  return <Container>{renderLayout()}</Container>;
}

const Container = styled.div`
  --id: ContainerLayout;
  display: flex;
  height: 79%;
  padding: 1.5em;
`;
