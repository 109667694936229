//* librerias
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//* components
import { Sidebar } from "../Sidebar";
import Footer from "../../../../components/Footer";

//* functions and actions
import { getLines, callBack_contactImage } from "../../../../utils/functions";
import { combo } from "../../utils/actions";
import { addComboMetric } from "../../../../redux/combo/actions";
import { showTermsAndConditions } from "../../../../redux/ui/actions";

//* assets
import imageBackRect from "../../../../assets/image/02_crear_combo/02_crear_combo_base_rectangular_empeza_a_crear.png";
import footer_plantilla from "../../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_fondo_datos.png";
import footer_logo_cc_blanco from "../../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_logo_cc_blanco.png";
import footer_logo_cc_rojo from "../../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_logo_cc_rojo.png";
import smile_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_smille_final.png";
import final_image from "../../../../assets/image/02_crear_combo/02_crear_combo_frase_final.png";
import fb_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_facebook.png";
import wsp_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_whatsapp.png";
import inst_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_instagram.png";
import phone from "../../../../assets/image/02_crear_combo/02_crear_combo_base_telefono.png";
import marco_comida_SOLO from "../../../../assets/image/02_crear_combo/03_plantilla_combo_rectangular_marco_comida_SOLO.png";
import marco_comida from "../../../../assets/image/02_crear_combo/04_plantilla_combo_rectangular_marco_comida_22.png";

//* styles
import {
  Container,
  GridBinding,
  BackImageComboRect,
  ContainerDescarga,
  SubContainerDescarga,
  GridLeft,
  ContainerImages,
  ImageText,
  ImageSmile,
  ImageIcon,
  ContainerIcons,
  GridButtons,
  BtnDescarga,
  Canvas2D,
  ImageWrapper,
  Canvas2DDescarga,
  BackButton,
  HomeButton,
} from "./styles";

import colors from "../../../../assets/colors";
import { useTheme } from "styled-components";

export const CreateCombo = () => {
  const dispatch = useDispatch();
  const [descImage, setDescImage] = useState(null);

  const plantillaCombo = useSelector((state) => state.combo.plantilla);
  const final_imagen = useSelector((state) => state.combo.imagenFinal);
  const user = useSelector((state) => state.credentials.userLogueado);
  const theme = useTheme();

  const sendMetric = () =>
    dispatch(
      addComboMetric({
        comboFormat: plantillaCombo.forma,
        comboType: plantillaCombo.ref,
        presetFoodPhoto: plantillaCombo.combo.comidaUserFlag,
        drinkBrand: plantillaCombo.combo.drinkBrand,
        drinkLine: plantillaCombo.combo.drinkLine,
        drinkLitrage: plantillaCombo.combo.drinkLitrage,
        token: user.token,
      })
    );

  useEffect(() => {
    dispatch(showTermsAndConditions(true));
  }, [dispatch]);

  const [landscape, setLandscape] = useState(window.innerWidth > 700);

  useLayoutEffect(() => {
    const updateSize = () => setLandscape(window.innerWidth > 700);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const mergePlantilla = useCallback(
    (plantilla) => {
      if (landscape || (!landscape && final_imagen)) {
        const canvas_global = document.getElementById("canvas");
        const ctx = canvas_global.getContext("2d");
        const imagefinal = new Image();
        imagefinal.crossOrigin = "anonymous";
        imagefinal.src = plantilla.combo.srcPlantilla;

        imagefinal.onload = function () {
          //comida
          const imageCom = new Image();
          if (plantilla.combo.presetFoodPhoto) {
            imageCom.src = plantilla.combo.comida;
            imageCom.onload = function () {
              plantilla.forma === "rectangular"
                ? ctx.drawImage(imageCom, 290, 410, 450, 600)
                : plantilla.ref === "duo"
                ? ctx.drawImage(imageCom, 155, 220, 350, 350)
                : ctx.drawImage(imageCom, 85, 175, 410, 440);
            };
          } else {
            if (plantilla.combo.comidaUser) {
              ctx.save(); //save canvas
              const imageComUser = new Image();
              imageComUser.width = 200;
              imageComUser.height = 200;
              imageComUser.src = plantilla.combo.comidaUser;
              imageComUser.onload = function () {
                if (plantilla.forma === "rectangular") {
                  ctx.translate(imageComUser.width * 0.5, imageComUser.height * 0.5);
                  ctx.rotate(-((8 * Math.PI) / 180));
                  ctx.translate(-imageComUser.width * 0.5, -imageComUser.height * 0.5);
                  ctx.drawImage(imageComUser, 265, 520, 400, 450);
                  ctx.restore();
                } else {
                  if (plantilla.ref === "duo") {
                    ctx.translate(imageComUser.width * 0.5, imageComUser.height * 0.5);
                    ctx.rotate(-((6 * Math.PI) / 180));
                    ctx.translate(-imageComUser.width * 0.5, -imageComUser.height * 0.5);
                    ctx.drawImage(imageComUser, 150, 273, 300, 250);
                    ctx.restore();
                  } else {
                    ctx.translate(imageComUser.width * 0.5, imageComUser.height * 0.5);
                    ctx.rotate(-((6 * Math.PI) / 180));
                    ctx.translate(-imageComUser.width * 0.5, -imageComUser.height * 0.5);
                    ctx.drawImage(imageComUser, 75, 225, 360, 330);
                    ctx.restore();
                  }
                }
              };
            }
            setTimeout(() => {
              imageCom.src = plantilla.forma === "rectangular" ? marco_comida_SOLO : marco_comida;
              imageCom.onload = function () {
                plantilla.forma === "rectangular"
                  ? ctx.drawImage(imageCom, 290, 410, 450, 600)
                  : plantilla.ref === "duo"
                  ? ctx.drawImage(imageCom, 155, 220, 350, 350)
                  : ctx.drawImage(imageCom, 85, 175, 410, 440);
              };
            }, 100);
          }

          //navidad cinta1

          setTimeout(() => {
            const imageCinta1 = new Image();
            imageCinta1.src = plantilla.combo.cinta1;
            imageCinta1.onload = function () {
              if (plantilla.forma === "rectangular") {
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  ctx.drawImage(imageCinta1, 270, 400, 177, 171);
                } else {
                  ctx.drawImage(imageCinta1, 300, 430, 117, 111);
                }
              } else {
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  ctx.drawImage(imageCinta1, 310, 100, 197, 191);
                } else {
                  ctx.drawImage(imageCinta1, 380, 180, 117, 111);
                }
              }
            };
          }, 200);

          //navidad sticker1
          setTimeout(() => {
            const imageSticker1 = new Image();
            imageSticker1.src = plantilla.combo.sticker1;
            imageSticker1.onload = function () {
              plantilla.forma === "rectangular" ? ctx.drawImage(imageSticker1, 270, 400, 105, 127) : ctx.drawImage(imageSticker1, 400, 160, 89, 106); //ok
            };
          }, 200);

          //navidad cinta2
          setTimeout(() => {
            const imageCinta2 = new Image();
            imageCinta2.src = plantilla.combo.cinta2;
            imageCinta2.onload = function () {
              if (plantilla.forma === "rectangular") {
                ctx.drawImage(imageCinta2, 400, 940, 153, 80);
              } else {
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  ctx.drawImage(imageCinta2, 350, 550, 153, 80);
                } else {
                  ctx.drawImage(imageCinta2, 320, 510, 153, 80);
                }
              }

              /* 
              plantilla.forma === "rectangular"
                ? ctx.drawImage(imageCinta2, 400, 940, 153, 80)
                : ctx.drawImage(imageCinta2, 320, 510, 153, 80); */
            };
          }, 200);

          //navidad sticker2
          setTimeout(() => {
            const imageSticker2 = new Image();
            imageSticker2.src = plantilla.combo.sticker2;
            imageSticker2.onload = function () {
              plantilla.forma === "rectangular" ? ctx.drawImage(imageSticker2, 570, 910, 106, 106) : ctx.drawImage(imageSticker2, 380, 500, 90, 90); //ok
            };
          }, 200);

          if (plantilla.combo.motivo !== "navidad") {
            //footer background
            const imageFooter = new Image();
            imageFooter.src = footer_plantilla;
            imageFooter.onload = function () {
              plantilla.forma === "rectangular" ? ctx.drawImage(imageFooter, 0, 1060, 700, 56) : ctx.drawImage(imageFooter, 0, 684, 800, 44);
            };
          }

          //footer logo image
          const imageLogoFooter = new Image();
          if (plantilla.combo.motivo === "navidad") {
            imageLogoFooter.src = "";
          } else {
            //TODO descomentar, si el usuaro es de coca se mostraria el logo de coca
            imageLogoFooter.src = plantilla.logo_cc_color === "white" ? footer_logo_cc_blanco : footer_logo_cc_rojo;

            imageLogoFooter.onload = function () {
              plantilla.forma === "rectangular"
                ? ctx.drawImage(imageLogoFooter, 470, 970, 230, 254)
                : ctx.drawImage(imageLogoFooter, 620, 620, 180, 194);
            };
          }

          //bebida
          setTimeout(() => {
            const imageBeb = new Image();
            imageBeb.src = plantilla.combo.bebida;
            imageBeb.onload = function () {
              plantilla.forma === "rectangular"
                ? ctx.drawImage(imageBeb, 10, 430, 328, 620)
                : plantilla.ref === "duo"
                ? ctx.drawImage(imageBeb, -10, 60, 240, 590)
                : ctx.drawImage(imageBeb, -10, 150, 190, 490);
            };
          }, 200);

          //logo

          if (plantilla.combo.logo) {
            const imageLogo = new Image();
            imageLogo.src = plantilla.combo.logo;
            imageLogo.onload = function () {
              plantilla.forma === "rectangular" ? ctx.drawImage(imageLogo, 520, 20, 160, 160) : ctx.drawImage(imageLogo, 620, 30, 140, 140);
            };
          } else {
            setTimeout(() => {
              if (plantilla.combo.motivo === "navidad") {
                ctx.lineWidth = 10;
                ctx.strokeStyle = "#ffffff";
                ctx.fillStyle = "#3798dcb3";
                plantilla.forma === "rectangular" ? ctx.strokeRect(520, 20, 160, 160) : ctx.strokeRect(620, 30, 140, 140);
              }

              ctx.fillStyle = plantilla.combo.sinLogoColor;
              plantilla.forma === "rectangular" ? ctx.fillRect(520, 20, 160, 160) : ctx.fillRect(620, 30, 140, 140);
            });
          }

          setTimeout(() => {
            // titulo
            if (plantilla.combo.descripcion.titulo_combo) {
              const title = plantilla.combo.descripcion.titulo_combo;
              let t =
                title.length >= 14 && !title.includes(" ")
                  ? title.slice(0, title.length / 2) + " " + title.slice(title.length / 2, title.length)
                  : title;
              if (plantilla.combo.motivo === "navidad") {
                if (plantilla.combo.descripcion.titulo_plantilla_roja === "ok") {
                  ctx.fillStyle = "white";
                  ctx.font = "60px You2013";
                  if (plantilla.forma === "rectangular") {
                    getLines(ctx, t, 500).forEach((text, i) => ctx.fillText(text, 40, 64 * i + 100));
                  } else {
                    ctx.font = "50px You2013";
                    getLines(ctx, t, 660).forEach((text, i) => ctx.fillText(text, 40, 48 * i + 100));
                  }
                } else {
                  ctx.font = "60px GilroyBold";
                  t = t.toUpperCase();
                  ctx.fillStyle = "#dc0f1d";
                  if (plantilla.forma === "rectangular") {
                    getLines(ctx, t, 500).forEach((text, i) => ctx.fillText(text, 40, 64 * i + 100));
                  } else {
                    ctx.font = "40px GilroyBold";
                    getLines(ctx, t, 660).forEach((text, i) => ctx.fillText(text, 40, 48 * i + 100));
                  }
                }
              } else {
                //titulo del combo
                ctx.font = "60px GilroyBold";
                ctx.fillStyle = colors.palette.primary;
                if (plantilla.forma === "rectangular") {
                  getLines(ctx, t.toUpperCase(), 500).forEach((text, i) => ctx.fillText(text, 40, 64 * i + 100));
                } else {
                  ctx.font = "44px GilroyBold";
                  getLines(ctx, t.toUpperCase(), 660).forEach((text, i) => ctx.fillText(text, 40, 48 * i + 100));
                }
              }
            }
            let minYValLegal = plantilla.forma === "rectangular" ? 1128 : 740;

            //parrafo
            let minYVal = 200;
            function writeText(text, options) {
              ctx.fillStyle = options.color || colors.palette.primary;
              ctx.font = `${options.size || 40}px ${options.font || "GilroyBold"}`;

              getLines(ctx, text, plantilla.forma === "rectangular" ? 430 : 280).forEach((text) => {
                ctx.fillText(text, plantilla.forma === "rectangular" ? 40 : 500, minYVal + options.size + 4);
                minYVal += options.size + 6;
              });
            }

            if (plantilla.combo.descripcion.titulo_comida) {
              //ctx.fillStyle = "#dc0f1d";
              ctx.font = "20px GilroyBold";
              if (plantilla.combo.motivo === "navidad") {
                writeText(plantilla.combo.descripcion.titulo_comida.toUpperCase(), {
                  color: plantilla.combo.descripcion.titulo_comida_color,
                  font: "GilroyBold",
                  size: plantilla.forma === "rectangular" ? 35 : 24,
                });
              } else {
                writeText(plantilla.combo.descripcion.titulo_comida.toUpperCase(), {
                  color: colors.palette.primary,
                  font: "GilroyBold",
                  size: plantilla.forma === "rectangular" ? 35 : 24,
                });
              }

              writeText(plantilla.combo.descripcion.descp_combo, {
                color: plantilla.combo.color_text,
                font: "GilroyMedium",
                size: plantilla.forma === "rectangular" ? 20 : 16,
              });
            }

            // BEBIDA IMAGE TEXT
            if (plantilla.combo.bebida_image_text) {
              ((val) => {
                const bebida = new Image();
                bebida.src = plantilla.combo.bebida_image_text;
                bebida.onload = function () {
                  let width = Math.round((bebida.width * (plantilla.forma === "rectangular" ? 60 : 40)) / bebida.height);
                  if (plantilla.forma === "rectangular") {
                    if (plantilla.combo.descripcion.tipo_pizza === "ok" && plantilla.combo.descripcion.tipo_burger !== "ok") {
                      ctx.drawImage(bebida, 40, val + 50, width, 60); //cambiar
                    } else {
                      ctx.drawImage(bebida, 40, val + 10, width, 60);
                    }
                  } else {
                    if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                      ctx.drawImage(bebida, 510, val + 50, width, 40);
                    } else {
                      ctx.drawImage(bebida, 510, val + 10, width, 40);
                    }
                  }
                };
              })(minYVal);
              minYVal += 60;
            }

            // PRECIO
            if (plantilla.combo.descripcion.precio) {
              ctx.fillStyle = plantilla.combo.color_text;

              ctx.font = "75px GilroyBold";

              if (plantilla.forma === "rectangular") {
                //horizontal,vertical
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  ctx.fillStyle = "white";
                  ctx.fillText(plantilla.combo.descripcion.precio, 460, 380);
                } else {
                  ctx.fillText(plantilla.combo.descripcion.precio, 460, 380);
                }
              } else {
                if (plantilla.combo.motivo === "navidad") {
                  if (plantilla.combo.descripcion.titulo_plantilla_roja === "ok") {
                    if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                      ctx.fillStyle = "white";
                      ctx.fillText(plantilla.combo.descripcion.precio, 526, 470);
                    } else {
                      writeText("  " + plantilla.combo.descripcion.precio, {
                        font: "GilroyBold",
                        color: "white",
                        size: 50,
                      });
                    }
                  } else {
                    writeText("  " + plantilla.combo.descripcion.precio, {
                      font: "GilroyBold",
                      color: "black",
                      size: 50,
                    });
                  }
                } else {
                  writeText("  " + plantilla.combo.descripcion.precio, {
                    font: "GilroyBold",
                    color: plantilla.combo.color_text,
                    size: 50,
                  });
                }
              }

              ctx.font = `${plantilla.forma === "rectangular" ? 40 : 32}px GilroyBold`;

              if (plantilla.forma === "rectangular") {
                ctx.fillText("$", 425, 365);
              } else {
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  /////acasa
                  ctx.fillText("$", 510, 460);
                } else {
                  ctx.fillText("$", 510, minYVal - 13, 150, 100);
                }
              }

              ctx.fillStyle = plantilla.combo.descripcion.border_precio_color;

              if (plantilla.forma === "rectangular") {
                ctx.fillRect(410, 310, 3, 80);
              } else {
                if (plantilla.combo.descripcion.tipo_pizza === "ok") {
                  ctx.fillRect(510, 480, 65, 3);
                } else {
                  ctx.fillRect(510, minYVal + 5, 65, 3);
                }
              }
            }

            //legales
            function writeTextLegal(text, options) {
              ctx.fillStyle = options.color || colors.palette.primary;
              ctx.font = `${options.size || 11}px ${options.font || "GilroyMedium"}`;

              getLines(ctx, text, plantilla.forma === "rectangular" ? 450 : 500).forEach((text) => {
                ctx.fillText(text, 40, minYValLegal + options.size + 2);
                minYValLegal += options.size + 2;
              });
            }

            if (plantilla.combo.legal) {
              ctx.fillStyle = colors.palette.primary;
              ctx.font = "12px GilroyMedium";

              writeTextLegal(plantilla.combo.legal, {
                color: plantilla.combo.legal_color,
                font: "GilroyMedium",
                size: 11,
              });
            }
          }, 0);

          //contacto
          setTimeout(() => {
            if (plantilla.combo.contacto) {
              if (plantilla.combo.contactosSel.length === 1) {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                plantilla.forma === "rectangular" ? (ctxTitle.font = "23px 'GilroyBold'") : (ctxTitle.font = "20px 'GilroyBold'");
                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(plantilla.combo.contactosSel[0]);
                imageContact1.onload = function () {
                  if (plantilla.forma === "rectangular") {
                    ///para mostrar el final
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact1, 20, 1080, 30, 30);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 70, 1100, 180, 45);
                    } else {
                      ctx.drawImage(imageContact1, 20, 1074, 30, 30);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 70, 1095, 180, 45);
                    }
                  } else {
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact1, 20, 680, 20, 20);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 46, 698, 180, 40);
                    } else {
                      ctx.drawImage(imageContact1, 20, 694, 20, 20);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 46, 712, 180, 40);
                    }
                  }
                };
              } else {
                const ctxTitle = canvas_global.getContext("2d");
                ctxTitle.fillStyle = "white";
                ctxTitle.fontWeight = 120;
                plantilla.forma === "rectangular" ? (ctxTitle.font = "23px 'GilroyBold'") : (ctxTitle.font = "20px 'GilroyBold'");
                const imageContact1 = new Image();
                imageContact1.src = callBack_contactImage(plantilla.combo.contactosSel[0]);
                imageContact1.onload = function () {
                  //si se elije un conacto pierde el lugar ubicaldo
                  if (plantilla.forma === "rectangular") {
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact1, 20, 1080, 28, 28);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 53, 1102, 180, 50);
                    } else {
                      ctx.drawImage(imageContact1, 20, 1074, 28, 28);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 53, 1095, 180, 50);
                    }
                  } else {
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact1, 20, 680, 24, 24);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 46, 698, 180, 40);
                    } else {
                      ctx.drawImage(imageContact1, 20, 694, 24, 24);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[0]], 46, 712, 180, 40);
                    }
                  }
                };
                const ctxTitle2 = canvas_global.getContext("2d");
                ctxTitle2.fillStyle = "white";
                ctxTitle2.fontWeight = 120;
                plantilla.forma === "rectangular" ? (ctxTitle2.font = "23px 'GilroyBold'") : (ctxTitle2.font = "20px 'GilroyBold'");
                const imageContact2 = new Image();
                imageContact2.src = callBack_contactImage(plantilla.combo.contactosSel[1]);
                imageContact2.onload = function () {
                  if (plantilla.forma === "rectangular") {
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact2, 250, 1083, 28, 28);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[1]], 285, 1102, 180, 40);
                    } else {
                      ctx.drawImage(imageContact2, 250, 1074, 28, 28);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[1]], 285, 1095, 180, 40);
                    }
                  } else {
                    if (plantilla.combo.motivo === "navidad") {
                      ctx.drawImage(imageContact2, 240, 680, 24, 24);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[1]], 266, 698, 180, 40);
                    } else {
                      ctx.drawImage(imageContact2, 240, 694, 24, 24);
                      ctx.fillText(plantilla.combo.contacto[plantilla.combo.contactosSel[1]], 266, 712, 180, 40);
                    }
                  }
                };
              }
            }
          }, 150);
          //plantilla
          ctx.drawImage(imagefinal, 0, 0, canvas_global.width, canvas_global.height);
        };

        if (final_imagen) {
          setTimeout(() => {
            var image = canvas_global.toDataURL("image/jpeg", 1.0);
            setDescImage(image);
          }, 1000);
        }
      }
    },
    [final_imagen]
  );

  useEffect(() => {
    if (plantillaCombo) {
      mergePlantilla(plantillaCombo);
    }
  }, [plantillaCombo, final_imagen, mergePlantilla]);

  return !final_imagen ? (
    <Container>
      <Sidebar landscape={landscape} />
      {landscape && (
        <GridBinding theme={theme}>
          {plantillaCombo ? (
            <Canvas2D
              id="canvas"
              height={plantillaCombo.forma === "rectangular" ? 1340 : 800}
              width={plantillaCombo.forma === "rectangular" ? 700 : 800}
            />
          ) : (
            <BackImageComboRect src={imageBackRect} />
          )}
        </GridBinding>
      )}
    </Container>
  ) : (
    plantillaCombo && (
      <ContainerDescarga>
        <SubContainerDescarga>
          <GridLeft>
            <ContainerImages style={{ paddingLeft: 50 }}>
              <ImageText src={final_image} />
              {landscape && <ImageSmile src={smile_image} />}
            </ContainerImages>

            <ContainerIcons>
              <ImageIcon src={fb_image} />
              <ImageIcon src={wsp_image} />
              <ImageIcon src={inst_image} />
            </ContainerIcons>
          </GridLeft>

          <ImageWrapper>
            {(landscape || (!landscape && final_imagen)) && (
              <div
                style={{
                  width: "100%",
                  height: landscape ? "70%" : "100%",
                  position: "relative",
                  left: "0px",
                  top: "0px",
                  // padding: landscape ? '0px' : '50px'
                }}
              >
                <img
                  src={phone}
                  class="eye"
                  alt=""
                  style={{
                    position: "absolute",
                    height: landscape ? "100%" : "400px",
                    left: "30%",
                  }}
                />

                <Canvas2DDescarga
                  id="canvas"
                  height={plantillaCombo.forma === "rectangular" ? 1244 : 800}
                  width={plantillaCombo.forma === "rectangular" ? 700 : 800}
                  size={plantillaCombo.forma === "rectangular" ? (landscape ? "84%" : "300px") : "47%"}
                  top={plantillaCombo.forma === "rectangular" ? "49px" : "84px"}
                />

                <div
                  style={{
                    display: "flex",
                    flex: 0.4,
                    height: landscape ? "auto" : "80px",
                  }}
                >
                  <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
                </div>
              </div>
            )}

            <GridButtons>
              {landscape && (
                <div style={{ display: "flex", flex: 0.3 }}>
                  <BackButton
                    onClick={() => {
                      dispatch(combo.setImagenFinal(false));
                      dispatch(combo.setEtapa("CONTACTO"));
                    }}
                  >
                    ATRAS
                  </BackButton>
                </div>
              )}
              {landscape && (
                <div
                  style={{
                    display: "flex",
                    flex: 0.4,
                    height: landscape ? "auto" : "80px",
                  }}
                >
                  <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
                </div>
              )}

              {landscape && (
                <div style={{ display: "flex", flex: 0.3 }}>
                  <HomeButton
                    onClick={() => {
                      dispatch(combo.setImagenFinal(false));
                      dispatch(combo.resetTemplate());
                    }}
                  >
                    VOLVER AL INICIO
                  </HomeButton>
                </div>
              )}
            </GridButtons>
          </ImageWrapper>
        </SubContainerDescarga>

        {landscape && <Footer />}
      </ContainerDescarga>
    )
  );
};
