import styled from 'styled-components'

export const ChartsFrame = styled.div`
  --class: ChartsFrame;
  display:grid;
  grid-template-rows:1fr 150px;
`
export const GrdIcons = styled.div`
  --class: GrdIcons;
  margin: 0px 20px 30px 40px;
  display:grid;
  grid-template-columns: ${props => (props.icons ? `${props.icons.map(i => '1fr').join(' ')}` : `${props.icons.map(i => '1fr').join('')}`)};
`
export const Chart = styled.svg`
	--id: Chart;
	overflow: visible !important;
	width: 960px;
	height: 280px;
	filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
`
export const ImgIcons = styled.img`
  --id: ImgIcons;
  margin:0px auto;
`