import React from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// el selector usado en plantilla
const ButtonSelector = ({
  onClick,
  // height,
  // width,
  margin,
  content,
  disabled,
}) => {
  return (
    <GlassBtn
      onClick={onClick}
      // height={height}
      // width={width}
      margin={margin}
      content={content}
      disabled={disabled}
    >
      {content}
      <ArrowDropDownIcon style={{ marginBottom: "3px" }} />
    </GlassBtn>
  );
};

export default ButtonSelector;

const GlassBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  outline: none;
  font-family: GilroyMedium;
  font-size: large;
  align-items: center;
  color: ${(props) => (!props.disabled ? props.theme.colors.border : props.theme.colors.text)};
  height: 30px;
  width: 150px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
  &:hover {
    background: ${(props) => props.theme.colors.border};
    color: ${(props) => props.theme.colors.background};
  }
`;
