import { handleActions } from "redux-actions";
import { showTermsAndConditions } from "./actions";

const initialState = {
    showTermsAndConditions: false,
};

export default handleActions(
    {
        [showTermsAndConditions]: (state, action) => {
            return { ...state, showTermsAndConditions: action.payload }
        },
    },
    initialState
);
