import React from "react";
import { useSelector } from "react-redux";
import { exportXLSXMetrics } from "../../../../utils/functions";
//* assets
import Linea1 from "../../../../assets/metrics/ _LINEAS 1.png";

//* styles
import { Container, ContainerCards, Card, Linea } from "./styles";

export const MetricsOne = () => {
  const {
    totalMenuEntries,
    totalComboEntries,
    totalQREntries,
    totalComboClientEntries,
    totalMenuClientEntries,
    totalQrClientEntries,
  } = useSelector((state) => state.metrics);
  let totalEntries = 0;
  if (
    totalComboClientEntries &&
    totalMenuClientEntries &&
    totalQrClientEntries
  ) {
    totalEntries = [
      ...totalComboClientEntries,
      ...totalMenuClientEntries,
      ...totalQrClientEntries,
    ].sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  return (
    <Container>
      <Card
        onClick={() => {
          exportXLSXMetrics(totalEntries, "TotalEntries");
        }}
      >
        <p>INGRESOS AL SITE</p>
        <div>
          <strong>
            {totalMenuEntries &&
              totalComboEntries &&
              totalQREntries &&
              totalMenuEntries + totalComboEntries + totalQREntries}
          </strong>
        </div>
      </Card>
      <Linea>
        <img src={Linea1} alt="img"/>
      </Linea>
      <ContainerCards>
        <div></div>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalComboClientEntries, "MenuEntries");
          }}
        >
          <p>COMBO</p>
          <div>
            <strong>{totalComboEntries && totalComboEntries}</strong>
          </div>
        </Card>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalMenuClientEntries, "MenuEntries");
          }}
        >
          <p>MENÚ</p>
          <div>
            <strong>{totalMenuEntries && totalMenuEntries}</strong>
          </div>
        </Card>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalQrClientEntries, "QrEntries");
          }}
        >
          <p>QR</p>
          <div>
            <strong>{totalQREntries && totalQREntries}</strong>
          </div>
        </Card>
      </ContainerCards>
    </Container>
  );
};
