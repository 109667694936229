// colorsReducer.js
import { SET_COLORS } from "./actions";

//utizar este formato de color
const initialState = {
  palette: {
    primary: "#D82403",
    secondary: "#EDEBD2",
    border: "#B1AE9B", //lightgray
    button: "#585953", // darkgray
    input: "#e0e0e0", //grey
    background: "#FFFFFF", // white
    text: "#000000", //black
    logo: "rgba(71, 164, 158, 0.5)", //logo
    //reemplazar yellow por secondary
  },
};

const colorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLORS:
      const newPalette = { ...state.palette, ...action.payload };
      return { ...state, palette: newPalette };
    default:
      return state;
  }
};

export default colorsReducer;
