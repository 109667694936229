import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";
import { combo } from "../../utils/actions";

//COMPONENTS
import AlertComponent from "../../../../components/common/alert";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import GlobalButton from "../../../../components/common/GlobalButton";
import Dropdown from "../../../../components/Dropdown";

import { MiniPreview } from "./components";

//STYLES
import { Container, GridControl, ErrorText, PreviewContainer, TittleContainer } from "./styles";

export const Bebida = () => {
  const dispatch = useDispatch();
  const bebidas = useSelector((state) => state.combo.bebidas);
  const plantillaCombo = useSelector((state) => state.combo.plantilla);
  const exists = useSelector((state) => state.combo.setSelUserBebida);

  const [selBebida, setSelBebida] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(bebidas.filter((b) => b.combo === plantillaCombo.ref && b.ref === plantillaCombo.forma));
  }, [plantillaCombo.ref, plantillaCombo.forma, bebidas]);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  //* Utilizamos esta function para aplicar filtros sobre las bebidas que se muestran en la grilla
  const filterList = (type, key, type2, key2) => {
    if (type === "all") return setList(bebidas.filter((b) => b.combo === plantillaCombo.ref && b.ref === plantillaCombo.forma));
    else if (type2 && key2) {
      setList(bebidas.filter((b) => b.combo === plantillaCombo.ref && b.ref === plantillaCombo.forma && b[key] === type && b[key2] === type2));
    } else {
      setList(bebidas.filter((b) => b.combo === plantillaCombo.ref && b.ref === plantillaCombo.forma && b[key] === type));
    }
  };

  return (
    <Container>
      {bebidas && (
        <>
          <div>
            <TittleContainer>
              <Title text="Elegí tu bebida" />
            </TittleContainer>
            <Subtitle text="Seleccioná las opciones de bebidas que acompañan tu menú o tu combo." />
          </div>

          <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione alguna bebida." />

          <GridControl>
            <Dropdown
              name="MARCA"
              data={[
                { name: "Todas", action: () => filterList("all", "marca") },
                {
                  name: "Coca-Cola Sabor Original",
                  action: () => filterList("coca-cola", "marca", "Sabor Original", "description"),
                },
                {
                  name: "Coca-Cola Sin Azucares",
                  action: () => filterList("coca-cola", "marca", "Sin Azucares", "description"),
                },
                {
                  name: "Coca-Cola Sabor liviano",
                  action: () => filterList("coca-cola", "marca", "Sabor Liviano", "description"),
                },
                {
                  name: "Coca-Cola con Café",
                  action: () => filterList("coca-cola", "marca", "Con Café", "description"),
                },
                {
                  name: "Sprite",
                  action: () => filterList("sprite", "marca"),
                },
                {
                  name: "Fanta",
                  action: () => filterList("fanta", "marca"),
                },
                {
                  name: "Schweppes",
                  action: () => filterList("schweppes", "marca"),
                },
                {
                  name: "Smartwater",
                  action: () => filterList("smartwater", "marca"),
                },
                {
                  name: "Kin",
                  action: () => filterList("kin", "marca"),
                },
                {
                  name: "Powerade",
                  action: () => filterList("powerade", "marca"),
                },
                {
                  name: "Aquarius",
                  action: () => filterList("aquarius", "marca"),
                },
                {
                  name: "Cepita",
                  action: () => filterList("cepita", "marca"),
                },
                {
                  name: "Cepita-Fresh",
                  action: () => filterList("cepita-fresh", "marca"),
                },
                {
                  name: "Ades",
                  action: () => filterList("ades", "marca"),
                },
                {
                  name: "Monster",
                  action: () => filterList("monster", "marca"),
                },
              ]}
            />

            <div />

            <GlobalButton
              disabled={!exists}
              content="SIGUIENTE"
              onClick={() => {
                if (selBebida) dispatch(combo.setEtapa("COMIDA"));
                else if (exists) dispatch(combo.setEtapa("COMIDA"));
                else setAlert(true);
              }}
            />
          </GridControl>

          <Element
            className="element"
            id="scroll-container"
            style={{
              height: "55vh",
              overflow: "scroll",
              overflowX: "hidden",
              marginLeft: 12,
              marginRight: 12,
              paddingBottom: "50px",
            }}
          >
            <PreviewContainer>
              {list.length ? (
                list.map((drink, i) => (
                  <MiniPreview
                    active={selBebida === drink.id}
                    key={drink.id}
                    src={drink.src}
                    brand={drink.marca}
                    description={drink.description}
                    litraje={drink.litraje}
                    onClick={() => {
                      setSelBebida(drink.id);
                      dispatch(
                        combo.setBebida({
                          ...drink,
                          colorText: plantillaCombo.combo.bebida_color_text,
                        })
                      );
                      dispatch(combo.setSelUserBebida(true));
                      dispatch(combo.setLegal(drink.legal));
                    }}
                  />
                ))
              ) : (
                <ErrorText>No se han encontrado coincidencias</ErrorText>
              )}
            </PreviewContainer>
          </Element>
        </>
      )}
    </Container>
  );
};
