import React from "react";
import styled, { useTheme } from "styled-components";
import { darkenColor } from "../../utils/functions";

const GlobalButton = ({ onClick, content, disabled }) => {
  const theme = useTheme();
  return (
    <Container>
      <GlassBtn theme={theme} onClick={onClick} content={content} disb={disabled}>
        {content}
      </GlassBtn>
    </Container>
  );
};

export default GlobalButton;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const GlassBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-family: GilroyMedium;
  font-size: large;
  align-items: center;
  color: ${(props) => props.theme.colors.background};
  margin: 10px;
  height: 30px;
  width: 150px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => (!props.disb ? `${props.theme.colors.primary}` : `${props.theme.colors.input}`)};
  &:hover {
    background: ${(props) => !props.disb && darkenColor(props.theme.colors.primary, 20)};
  }
`;
