import React from "react";
import styled, { useTheme } from "styled-components";
import { useDispatch } from "react-redux";

//* importamos ambas actions
import { combo } from "../../../pages/create-combo/utils/actions";
import { comboQR } from "../../../pages/comboQR/utils/actions";

import { darkenColor } from "../../../utils/functions";

import { ReactSVG } from "react-svg";
import bitmap from "../../../assets/image/qr/sidebar/link/B8.svg";

export default function BtnLogoCombo({ type, template, stage, setUrl }) {
  const dispatch = useDispatch();

  const comboType = type === "combo" ? combo.setEtapa : comboQR.setEtapa;
  const stageType = type === "combo" ? "LINK" : "link";
  const theme = useTheme();

  const buttonInactive = (plantilla) => {
    return plantilla?.combo?.url && setUrl ? (
      <StyledSVG src={bitmap} onClick={() => dispatch(comboType(stageType))} color={theme.colors.primary} background={theme.colors.background} />
    ) : (
      <StyledSVG src={bitmap} color={theme.colors.button} background={theme.colors.background} />
    );
  };

  const StyledSVGContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.border};

    @media screen and (max-width: 700px) {
      width: 60px;
      height: 60px;
    }
  `;

  const StyledSVG = styled(ReactSVG)`
    //background-color: blue;

    svg {
      margin-top: -0.6em;
      width: 100%;
      height: 100%;

      path {
        fill: ${(props) => props.color || "currentColor"};
      }
    }
    ${(props) =>
      props.background &&
      `
    & > div {
      background-color: ${props.background};
    }
  `}
  `;

  return (
    <StyledSVGContainer>
      {stage === stageType ? (
        <StyledSVG src={bitmap} color={theme.colors.background} background={darkenColor(theme.colors.primary, 20)} />
      ) : (
        buttonInactive(template)
      )}
    </StyledSVGContainer>
  );
}
