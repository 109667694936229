import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";
import { comboQR } from "../../utils/actions";

//COMPONENTS
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";

//STYLES
import { Container, GridControl, GridImage, ContainerImageGrid, ContainerImage, Image, TittleContainer } from "./styles";

export const Template = () => {
  const dispatch = useDispatch();
  const plantillas = useSelector((state) => state.comboQR.templates);

  const [plantilla, setPlantilla] = useState("");

  const setPlantillaPosta = (plant) => {
    setPlantilla(plant.id);
    dispatch(comboQR.setTemplate(plant));
  };

  return (
    <Container className="containerPlantilla">
      {plantillas && (
        <>
          <div>
            <TittleContainer>
              <Title text="Elegí tu plantilla" />
            </TittleContainer>

            <Subtitle text="Recordá que podés cambiar todos los elementos de plantilla en los próximos pasos." />
          </div>
          <GridControl>
            <GlobalButton disabled={!plantilla} content="SIGUIENTE" onClick={() => plantilla && dispatch(comboQR.setEtapa("logo"))} />
          </GridControl>
          <Element
            className="element"
            id="scroll-container"
            style={{
              height: "55vh",
              overflow: "scroll",
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <GridImage>
              {plantillas.map((plant) => (
                <ContainerImageGrid key={plant.id}>
                  <ContainerImage active={plantilla === plant.id}>
                    <Image src={plant.src} onClick={() => setPlantillaPosta(plant)} />
                  </ContainerImage>
                </ContainerImageGrid>
              ))}
            </GridImage>
          </Element>
        </>
      )}
    </Container>
  );
};
