import { createAction } from "redux-actions";

export const setEtapa = createAction("combo.setEtapa");
export const setPlantilla = createAction("combo.setPlantilla");
export const setLogo = createAction("combo.setLogo");
export const setDescripcion = createAction("combo.setDescripcion");
export const setBebida = createAction("combo.setBebida");
export const setComida = createAction("combo.setComida");
export const setComidaUser = createAction("combo.setComidaUser");
export const setPresetFoodPhoto = createAction("combo.setPresetFoodPhoto");
export const setContacto = createAction("combo.setContacto");
export const setContactosSel = createAction("combo.setContactosSel");
export const setSelUserContacto = createAction("combo.setSelUserContacto");
export const setImagenFinal = createAction("combo.setImagenFinal");
export const setSelUserLogo = createAction("combo.setSelUserLogo");
export const setSelUserDescripcion = createAction(
  "combo.setSelUserDescripcion"
);
export const setSelUserBebida = createAction("combo.setSelUserBebida");
export const setSelUserComida = createAction("combo.setSelUserComida");
export const setLegal = createAction("combo.setLegal");
export const resetTemplate = createAction("combo.resetTemplate");
export const setCheckLogoCombo = createAction("combo.setCheckLogoCombo");

export const combo = {
  setEtapa,
  setPlantilla,
  setLogo,
  setDescripcion,
  setBebida,
  setComida,
  setComidaUser,
  setPresetFoodPhoto,
  setContactosSel,
  setContacto,
  setSelUserContacto,
  setImagenFinal,
  setSelUserLogo,
  setSelUserDescripcion,
  setSelUserBebida,
  setSelUserComida,
  setLegal,
  resetTemplate,
  setCheckLogoCombo,
};
