import { createAction } from "redux-actions";

export const setEtapa = createAction("createMenu.setStage");
export const setTemplate = createAction("createMenu.setTemplate");
export const setLogo = createAction("createMenu.setLogo");
export const setDescription = createAction("createMenu.setDescription");
export const setTitle = createAction("createMenu.setTitle");

export const setFood = createAction("createMenu.setFood");
export const setContact = createAction("createMenu.setContact");
export const setImagenFinal = createAction("createMenu.setImagenFinal");
export const setContactSel = createAction("createMenu.setContactSel");
export const setSelUserContact = createAction("createMenu.setSelUserContact");
export const resetTemplate = createAction("createMenu.resetTemplate");
export const setSelUserLogo = createAction("createMenu.setSelUserLogo");
export const setSelUserBebida = createAction("createMenu.setSelUserBebida");
export const setSelUserComida = createAction("createMenu.setSelUserComida");
export const setSelUserDescripcion = createAction(
  "createMenu.setSelUserDescripcion"
);
export const setPresetFoodPhoto = createAction("createMenu.setPresetFoodPhoto");
export const setSetCheckLogo = createAction("combo.setSetCheckLogo");

export const createMenu = {
  setTemplate,
  setEtapa,
  setLogo,
  setTitle,
  setDescription,
  setFood,
  setContact,
  setContactSel,
  setSelUserContact,
  setSelUserLogo,
  setImagenFinal,
  setSelUserDescripcion,
  setSelUserBebida,
  setSelUserComida,
  setPresetFoodPhoto,
  resetTemplate,
  setSetCheckLogo,
};
