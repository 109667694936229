import React from 'react';
import styled from 'styled-components';

//* assets
import cocagif from '../assets/image/login/homeBK.gif';
import registro_fondo_iconos from '../assets/image/01_inicio_y_registro/01_registro_fondo_iconos.png';

export default function TipLeft({ landscape }) {
  return (
    <>
      {!landscape && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <StaticFrame>
            <img
              src={cocagif}
              style={{
                // width: "45vw",
                marginLeft: 'auto',
                marginRight: 'auto',
                bottom: '0vh',
              }}
              alt=""
            />
          </StaticFrame>
        </div>
      )}

      {landscape && (
        <div style={{ display: 'flex', flex: 0.9, flexDirection: 'row' }}>
          <StaticFrame>
            <img
              src={cocagif}
              style={{
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
                bottom: '0vh',
              }}
              alt=""
            />
          </StaticFrame>
        </div>
      )}
    </>
  );
}

const StaticFrame = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
  padding-left: 0;
  @media (orientation: portrait) {
    //flex: 0.5;
    //background-image: url(${registro_fondo_iconos});
    padding: 10px;
    flex-direction: row;
    height: 200px;
  }
`;

// const Imagen = styled.img`
//   position: relative;
//   left: 0;
// `;
