import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../assets/colors";

const ListItemStyled = styled(Link)`
  background-color: ${({ selected }) => (selected ? colors.palette.dark : colors.palette.darkRed)};
  &:hover {
    background-color: ${colors.palette.primary};
  }
  display: flex;
  align-items: center;
  padding: 16px;
  text-decoration: none;
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
`;

const Text = styled.p`
  color: ${({ selected }) => (selected ? colors.palette.primary : colors.palette.secondary)};
  font-family: GilroyMedium;
  margin: 0px;
  font-size: medium;
  text-align: center;
  flex: 1;
`;
const ListItemComponent = ({ path, selected, title, onClick }) => (
  <ListItemStyled to={path} selected={selected} onClick={onClick}>
    <Text selected={selected}>{title}</Text>
  </ListItemStyled>
);

export default memo(ListItemComponent);
