import React from "react";
import styled, { useTheme } from "styled-components";

export default function Title({ text }) {
  const theme = useTheme();
  return (
    <Container>
      <Text theme={theme}>{text}</Text>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  display: flex;
  color: ${(props) => props.theme.colors.primary};
  font-size: 32px;
  background-color: ${(props) => props.theme.colors.secondary};
  height: fit-content;
  padding: 10px;
  margin: 0;
  font-family: GilroyBold;
`;
