import styled from "styled-components";

export const Container = styled.div`
  padding-right: 12px;
  flex-direction: column;
  @media (max-height: 632px) {
    overflow: scroll;
  }
`;

export const Form = styled.form`
  padding: 0px 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Input = styled.input`
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.button};
  padding: 4px;
  padding-left: 12px;
  font-family: GilroyMedium;
  font-size: 13px;
  font-weight: 500;
  width: ${(props) => (props.width ? props.width : "90%")};
  border: 2px solid ${({ theme }) => theme.colors.border};
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.colors.border};
    font-family: GilroyMedium;
    font-size: 13px;
    font-weight: 500;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  padding-left: 4px;
  font-size: 15px;
  font-family: GilroyMedium;
  font-weight: 25;
`;

export const SubtitleD = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  font-family: GilroyMedium;
  padding: 5px 0px 0px 5px;
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
