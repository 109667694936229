import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 50px;
  overflow: hidden;
  @media (orientation: portrait) {
    padding: 20px 20px 0 20px;
    height: 82vh;
  }
`;

export const FormLogin = styled.form`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  //flex: 7;
  justify-content: space-around;
  flex-flow: wrap;
  overflow: scroll;
`;

export const CustomLabel = styled.label`
  flex: 0.4;
  width: 130px;
  text-align: center;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CustomLabelLeft = styled.label`
  flex: 0.6;
  width: 130px;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  text-align: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;
`;

export const UploadSection = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  @media (orientation: portrait) {
    padding: 20px 20px 0 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (orientation: portrait) {
    margin: 10px 0px;
  }
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
