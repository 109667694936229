import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { comboQR } from "../../utils/actions";
import { useTheme } from "styled-components";

//COMPONENTS
import AlertComponent from "../../../../components/common/alert";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import GlobalButton from "../../../../components/common/GlobalButton";

//STYLES
import { Container, Form, TextArea, Label, SubtitleD, TittleContainer } from "./styles";

export const Description = () => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const plantillaCombo = useSelector((state) => state.comboQR.template);
  const [openAlert, setAlert] = useState(false);
  const exists = useSelector((state) => state.comboQR.setSelUserDescripcion);
  const [textInput, setTextInput] = useState(plantillaCombo?.combo?.descripcion.title);
  const [stateDesc, setStateDesc] = useState(null);
  const theme = useTheme();

  const onChancheTextInput = (e) => {
    setStateDesc(true);
    setTextInput(e.target.value);
  };

  const onSubmit = (values) => {
    dispatch(comboQR.setSelUserDescripcion(true));
    dispatch(comboQR.setDescription(values.descp_combo));
    dispatch(comboQR.setEtapa("picture"));
    setStateDesc(null);
  };

  const handleClose = (event, reason) => setAlert(false);

  return (
    <Container>
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Debes agregar una descripción." />

      {plantillaCombo?.combo?.descripcion && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TittleContainer>
            <Title text="Describí tu QR" />
          </TittleContainer>
          <Subtitle notmr text={"Escribí un texto para tu QR."} />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2%",
              }}
            >
              <SubtitleD theme={theme}>Completá los campos</SubtitleD>
              <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <GlobalButton content="SIGUIENTE" disabled={errors.descp_combo || !stateDesc} onClick={() => errors.descp_combo && setAlert(true)} />
              </div>
            </div>

            <div>
              <Label htmlFor="descp_combo">Descripción del combo ({textInput.length}/65)</Label>
              <TextArea
                theme={theme}
                placeholder={plantillaCombo?.combo?.descripcion.title}
                defaultValue={exists ? plantillaCombo.combo.descripcion.title : ""}
                style={{
                  width: "95%",
                  height: "3em",
                  padding: "6px",
                  paddingLeft: "12px",
                }}
                name="descp_combo"
                ref={register({ required: true })}
                onChange={onChancheTextInput}
                maxLength={65}
                rows="10"
                cols="25"
              />
            </div>
          </Form>
        </div>
      )}
    </Container>
  );
};
