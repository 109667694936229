import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormLogin = styled.form`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 24px;
  @media (orientation: portrait) {
    width: 90vw;
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const GridImage = styled.div`
  --id: GridImage;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // grid-auto-rows: 1fr 1fr 1fr;
  padding: 2%;
`;

export const ContainerImage = styled.div`
  --id: ContainerImage;
  margin: 5px;
  margin-bottom: 0px;
  opacity: ${(props) => (props.active ? "0.6" : "1.0")};
  :hover {
    opacity: 0.6;
  }
`;

export const Image = styled.img`
  --id: Image;
  width: 100%;
`;

export const CustomLabel = styled.label`
  flex: 0.4;
  width: 130px;
  text-align: center;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CustomLabelLeft = styled.label`
  flex: 0.6;
  width: 130px;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  text-align: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px;
`;

export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (orientation: portrait) {
    width: 90vw;
  }
`;
