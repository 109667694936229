import styled from "styled-components";

export const Image = styled.img`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 32px;
  width: 185px;
  margin: 5px;
  @media (max-width: 1200px) {
    width: 110px;
  }
`;
