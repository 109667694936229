import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//* Login
import { Login } from "../pages/login";

//* Register
import { Register } from "../pages/register";
import ConfirmCode from "../pages/confirmCode/index";

//* Reset password
import { SendCode, CheckCode, SendNewPassword } from "../pages/recoverPassword/views";

// Combo qr & menu
import { CreateCombo } from "../pages/create-combo/views";
import { CreateQR } from "../pages/comboQR/views";
import { CreateMenu } from "../pages/createMenu/views";
// import {Metrics} from '../pages/metrics/views';
import Home from "../pages/home";
import { loginUser } from "../redux/credentials/credentials";

//import { AES, enc } from 'crypto-js';

const AuthRoutes = function () {
  const user = useSelector((state) => state.credentials.userLogueado);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("data") !== null) {
      /*   const decrypted = AES.decrypt(localStorage.getItem('data'), 'MYKEY91/');
      const decryptedObject = JSON.parse(decrypted.toString(enc.Utf8)); */
      const storedToken = localStorage.getItem("data");

      dispatch(loginUser({ accessToken: storedToken }));
    }

    setLoading(false);
  }, [dispatch]);

  if (loading) {
    // Mostrar un loader o una pantalla de carga mientras se completa el efecto
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Switch>
        {/*  <Route path="/account/login" component={Login} />
        <Route exact path="/account/register" component={Register} />
        <Route exact path="/account/confirmcode" component={ConfirmCode} />
        <Route exact path="/account/recoverpassword" component={SendCode} />
        <Route exact path="/account/recoverpassword/check" component={CheckCode} />
        <Route exact path="/account/recoverpassword/newpass" component={SendNewPassword} />
 */}
        <Route exact path="/account/home" component={Home} />
        <Route exact path="/account/createcombo" component={CreateCombo} />
        <Route exact path="/account/createQR" component={CreateQR} />
        <Route exact path="/account/createMenu" component={CreateMenu} />

        <Redirect to="/account/home" />
      </Switch>
    </div>
  );
};

export default AuthRoutes;
