import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import promise from "redux-promise-middleware";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

//* importando reducers
import combo from "../pages/create-combo/utils/reducer";
import comboQR from "../pages/comboQR/utils/reducer";
import credentials from "./credentials/credentialsReducer";
import createMenu from "../pages/createMenu/redux/reducer";
import metrics from "./metrics/reducer";
import ui from "./ui/reducer";
import colorsReducer from "./colors/colorsReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["credentials"],
};

const middlewares = [promise, thunk];
const rootReducer = combineReducers({ colors: colorsReducer, combo, comboQR, credentials, createMenu, metrics, ui });
const persistedReducer = persistReducer(persistConfig, rootReducer);

// process.env.NODE_ENV !== 'production' && middlewares.push(createLogger());

const store = createStore(persistedReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);

export { store, persistor };
