import { handleActions } from "redux-actions";
import { combo } from "./actions";
/*
Plantillas
**/

import combo_duo_rec_1 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_1.png";
import combo_duo_rec_2 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_2.png";
import combo_duo_rec_3 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_3.png";
import combo_duo_rec_4 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_4.png";
// import combo_duo_rec_5 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_5.png";
import combo_duo_rec_6 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_rec_6.png";
import combo_duo_cuad_1 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_1.png";
import combo_duo_cuad_2 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_2.png";
import combo_duo_cuad_3 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_3.png";
import combo_duo_cuad_4 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_4.png";
import combo_duo_cuad_5 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_5.png";
import combo_duo_cuad_6 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_duo_cuad_6.png";
import combo_simple_rec_1 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_1.png";
import combo_simple_rec_2 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_2.png";
import combo_simple_rec_3 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_3.png";
import combo_simple_rec_4 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_4.png";
import combo_simple_rec_5 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_5.png";
import combo_simple_rec_6 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_rec_6.png";
import combo_simple_cuad_1 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_cuad_1.png";
import combo_simple_cuad_3 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_cuad_3.png";
import combo_simple_cuad_4 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_cuad_4.png";
import combo_simple_cuad_5 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_cuad_5.png";
import combo_simple_cuad_6 from "../../../assets/image/05_crear_combo_galeria_plantillas/05_galeria_plantillas_combo_simple_cuad_6.png";

///// combos comidas
import combo_duo_cuad_13 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_13.png";
import combo_duo_rec_13 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_13.png";
import combo_simple_cuad_13 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_13.png";
import combo_simple_rec_13 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_13.png";

import combo_duo_cuad_14 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_14.png";
import combo_duo_rec_14 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_14.png";
import combo_simple_cuad_14 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_14.png";
import combo_simple_rec_14 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_14.png";

import combo_duo_cuad_15 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_15.png";
import combo_duo_rec_15 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_15.png";
import combo_simple_cuad_15 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_15.png";
import combo_simple_rec_15 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_15.png";

import combo_duo_cuad_16 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_16.png";
import combo_duo_rec_16 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_16.png";
import combo_simple_cuad_16 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_16.png";
import combo_simple_rec_16 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_16.png";

import combo_duo_cuad_17 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_17.png";
import combo_duo_rec_17 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_17.png";
import combo_simple_cuad_17 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_17.png";
import combo_simple_rec_17 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_17.png";

import combo_duo_cuad_18 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_18.png";
import combo_duo_rec_18 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_18.png";
import combo_simple_cuad_18 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_18.png";
import combo_simple_rec_18 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_18.png";

import combo_duo_cuad_19 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_19.png";
import combo_duo_rec_19 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_19.png";
import combo_simple_cuad_19 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_19.png";
import combo_simple_rec_19 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_19.png";

import combo_duo_cuad_20 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_20.png";
import combo_duo_rec_20 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_20.png";
import combo_simple_cuad_20 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_20.png";
import combo_simple_rec_20 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_20.png";

import combo_duo_cuad_21 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_cuad_21.png";
import combo_duo_rec_21 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_duo_rec_21.png";
import combo_simple_cuad_21 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_cuad_21.png";
import combo_simple_rec_21 from "../../../assets/image/05_crear_combo_galeria_plantillas/20_galeria_plantillas_combo_simple_rec_21.png";

///
import cuad01 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_1.jpg";
import cuad02 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_2.jpg";
import cuad03 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_3.jpg";
import cuad04 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_4.jpg";
import cuad05 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_5.jpg";
import cuad06 from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_combo_cuadrada_fondo_6.jpg";
//
import cuad07 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_7.png";
import cuad08 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_8.png";
import cuad09 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_9.png";
import cuad10 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_10.png";
import cuad11 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_11.png";
import cuad12 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_12.png";
import cuad13 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_13.png";
import cuad14 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_14.png";
import cuad15 from "../../../assets/image/07_elementos_plantillas_cuadradas/20_plantilla_combo_cuadrada_fondo_15.png";

//
import rect01 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_1.jpg";
import rect02 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_2.jpg";
import rect03 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_3.jpg";
import rect04 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_4.jpg";
import rect05 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_5.jpg";
import rect06 from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_combo_rectangular_fondo_6.jpg";
//
import rect07 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_7.png";
import rect08 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_8.png";
import rect09 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_9.png";
import rect10 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_10.png";
import rect11 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_11.png";
import rect12 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_12.png";
import rect13 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_13.png";
import rect14 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_14.png";
import rect15 from "../../../assets/image/07_elementos_plantillas_rectagular/20_plantilla_combo_rectangular_fondo_15.png";
//
/*
Bebidas
**/
//rect

// import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco.png";
// import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro.png";
// import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa.png";

import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso.png";

import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp.png";

import plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_cc from "../../../assets/image/07_elementos_plantillas_rectagular/10_plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_cc.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/10__plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_blanco.png";
import plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_negro from "../../../assets/image/07_elementos_plantillas_rectagular/10__plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_negro.png";

import image_rec_combo_duo_cp_gaseosas_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_duo_cp_stills_pet_500_aq_pom.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccso.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_fn from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_fn.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_kin from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_kin.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_sp from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_duo_cp_gaseosas_pet_500_sp.png";

import image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_manzana.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_manzana_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_manzana_desc_negro.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pera from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pera.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pera_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pera_desc_negro.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pomelo.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pomelo_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_aq_pomelo_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja.png";
import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja_desc_negro.png";

import image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo.png";
import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo_desc_negro.png";
import image_rec_combo_simple_cf_stills_pet_1_5_kin from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_kin.png";
import image_rec_combo_simple_cf_stills_pet_1_5_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_kin_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_1_5_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_1.5_kin_desc_negro.png";
import image_rec_combo_simple_cf_stills_pet_2_25_kin from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_2.25_kin.png";
import image_rec_combo_simple_cf_stills_pet_2_25_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_2.25_kin_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_pet_2.25_kin_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_almendras.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_manzana.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_natural from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_natural.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro.png";

//TODO
//import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa.png";
//import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco.png";
//import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro.png";
//////////
import image_rec_combo_simple_cp_gaseosas_lata_220_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccso.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro.png";

///TODO
//import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa.png";
//import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco.png";
//import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro.png";
/////
import image_rec_combo_simple_cp_gaseosas_lata_354_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccso.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_fn from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_fn.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_sp from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_sp.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_negro.png";

/////TODO
//import image_rec_combo_simple_cp_gaseosas_pet_500_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl.png";
//import image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco.png";
//import image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro.png";

////
import image_rec_combo_simple_cp_gaseosas_pet_500_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccso.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro.png";

////TODO
////import image_rec_combo_simple_cp_gaseosas_pet_600_fn from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_pet_600_fn.png";
//import image_rec_combo_simple_cp_gaseosas_pet_600_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_pet_600_fn_desc_blanco.png";
//import image_rec_combo_simple_cp_gaseosas_pet_600_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_pet_600_fn_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_237_ccso.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_pet_500_sp from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_sp.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_negro.png";

//TODO
///import image_rec_combo_simple_cp_stills_pet_500_aq_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_manzana.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_man.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_man_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_man_desc_negro.png";

///TODO
//import image_rec_combo_simple_cp_stills_pet_500_aq_pera from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pera from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_negro.png";

//TODO
//import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pomelo.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco.png";
//import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pom.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pom_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_aq_pom_desc_negro.png";

import image_rec_combo_simple_cp_stills_pet_500_kin from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_kin.png";
import image_rec_combo_simple_cp_stills_pet_500_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_kin_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_kin_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_manzana.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro.png";
import image_rec_combo_simple_cp_stills_tetra_200_ades_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_manzana.png";
import image_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco.png";
import image_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/07_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro.png";

import image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_man.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_man_desc_blanco.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_man_desc_negro.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_multi.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_multi_desc_blanco.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_multi_desc_negro.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_nar from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_nar.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco.png";
import image_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro.png";

// /* NUEVOS REC */

//TODO
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccl.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccl_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccl_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccl.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccl_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccl_desc_negro.png";

// import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccsa.png";
// import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_blanco.png";
// import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_negro.png";

//TODO
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccso.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccso_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_ccso_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccso.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccso_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccso_desc_blanco.png";

//TODO
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_1,5_ccsa.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_1,5_ccsa_desc_blanco.jpg";
//import image_rec_combo_simple_cf_gaseosas_pet_15_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_1,5_ccsa_desc_negro.jpg";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccsa.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccsa_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccsa_desc_blanco.png";

//TODO
//import image_rec_combo_simple_cf_gaseosas_pet_15_fn from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_fn.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_fn_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_fn_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_fn_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_fn_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_fn from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_fn.png";

import image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro.png";

//TODO
//import image_rec_combo_simple_cf_gaseosas_pet_15_sp from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sp.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sp_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sp_desc_negro.png";

import image_rec_combo_simple_cf_gaseosas_pet_175_sp from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_sp.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_sp_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_sp_desc_negro.png";

//import image_rec_combo_simple_cf_gaseosas_pet_15_spsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_spsa.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_spsa_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_15_spsa_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_spsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_spsa.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_spsa_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_175_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_spsa_desc_negro.png";
/////////////////////////////////////////////////////////
import image_rec_combo_simple_cf_gaseosas_pet_15_sw from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sw.png";
////import image_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_negro.png";

//TODO
///import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccl.png";
///import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco.png";
///import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccl.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccl_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccl_desc_negro.png";

//TODO se cambia la imagen
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccsa.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccsa_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_ccsa_desc_negro.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccso.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_fn from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_fn.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_sp from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_sp.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro.png";
//import image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_spsa.png";
//import image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_negro.png";
//import image_rec_combo_simple_cf_gaseosas_ret_2_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_ret_2_ccsa.png";
//import image_rec_combo_simple_cf_gaseosas_ret_2_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_ret_2_ccsa_desc_blanco.png";
//import image_rec_combo_simple_cf_gaseosas_ret_2_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cf_gaseosas_ret_2_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_aguas_pet_591_sw from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_aguas_pet_591_sw.png";
import image_rec_combo_simple_cp_aguas_pet_591_sw_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_aguas_pet_591_sw_desc_blanco.png";
import image_rec_combo_simple_cp_aguas_pet_591_sw_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_aguas_pet_591_sw_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_spsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_lata_220_spsa.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccsa.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_fn from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_fn.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_spsa.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/17_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/16_plantilla_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro.png";

//TODO nuevos

import image_rec_combo_simple_cp_gaseosas_pet_500_fn from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fn.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_pet_500_fl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fl.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_negro.png";

import image_rec_combo_simple_cp_stills_pet_500_ksg from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_ksg.png";
import image_rec_combo_simple_cp_stills_pet_500_ksg_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_ksg_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_500_ksg_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_pet_500_ksg_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccl.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_fnl.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_fnl_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_pet_2,25_fnl_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_man.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_man_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_man_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_nar.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_nar_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_nar_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_pom.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_pom_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cf_gaseosas_stills_2,25_cepita_fresh_pom_desc_negro.png";

import image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_frutas_trop.png";
import image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_frutas_trop_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_frutas_trop_desc_negro.png";

import image_rec_combo_simple_cf_stills_tetra_200_ades_nar from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_nar.png";
import image_rec_combo_simple_cf_stills_tetra_200_ades_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_nar_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_200_ades_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_stills_tetra_200_ades_nar_desc_negro.png";

import image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_ener.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro.png";

import image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_mango.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro.png";

import image_rec_combo_simple_cp_isotonicas_lata_473_monster_white from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_white.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro.png";

import image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro.png";

import image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco.png";
import image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_pet_500_fnsa from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fnsa.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/18_plantilla_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro.png";

//TODO chequear se tendria que usar el blanco y negro
import image_rec_combo_duo_cp_gaseosas_pet_500_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccsa-01.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco.png";
import image_rec_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro.png";

import image_rec_combo_simple_cp_stills_pet_591_sw_con_gas from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_simple_cp_stills_pet_591_sw_sin_gas.png";
import image_rec_combo_simple_cp_stills_pet_591_sw_con_gas_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_simple_cp_stills_pet_591_sw_sin_gas_desc_blanco.png";
import image_rec_combo_simple_cp_stills_pet_591_sw_con_gas_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/21_plantilla_rec_combo_simple_cp_stills_pet_591_sw_sin_gas_desc_negro.png";

import image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa.png";
import image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro.png";

import image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla.png";
import image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco.png";
import image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/24_plantilla_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25L_aq_man from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_man.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_man_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_man_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_man_desc_negro.png";

//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_nar.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_nar_desc_blanco.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_nar_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pera.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pera_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pera_desc_negro.png";

import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_desc_blanco.png";
import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_desc_negro.png";

//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado_desc_blanco.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado_desc_negro.png";

//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_sa.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_sa_desc_blanco.png";
//import image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/27_plantilla_rec_combo_simple_cf_stills_pet_2,25L_aq_pom_sa_desc_negro.png";

import image_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cf_gaseosas_pet_1,75_ccso.png";

import image_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_fn from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_fn.png";

import image_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_blanco.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_negro.png";
import image_rec_combo_simple_cp_gaseosas_pet_237_sp from "../../../assets/image/07_elementos_plantillas_rectagular/30_plantilla_rec_combo_simple_cp_gaseosas_pet_237_sp.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_fn from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_fn.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_sp from "../../../assets/image/07_elementos_plantillas_rectagular/31_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_sp.png";

import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/32_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_blanco.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/32_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_negro.png";
import image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/32_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccso.png";

import image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cp_gaseosas_pet_500_cssa_y_csso.png";
import image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cp_gaseosas_pet_500_cssa_y_csso_desc_negro.png";
import image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cp_gaseosas_pet_500_cssa_y_csso_desc_blanco.png";

import image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_csso.png";
import image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_csso_desc_negro.png";
import image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/33_plantilla_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_csso_desc_blanco.png";

//COMBO DUO
import image_rec_combo_duo_cp_aguas_pet_591_swsg_desc_blanco from "../../../assets/image/07_elementos_plantillas_rectagular/29_plantilla_rec_combo_duo_ccp_aguas_pet_591_swsg_desc_blanco.png";
import image_rec_combo_duo_cp_aguas_pet_591_swsg_desc_negro from "../../../assets/image/07_elementos_plantillas_rectagular/29_plantilla_rec_combo_duo_cp_aguas_pet_591_swsg_desc_negro.png";
import image_rec_combo_duo_cp_aguas_pet_591_swsg from "../../../assets/image/07_elementos_plantillas_rectagular/29_plantilla_rec_combo_duo_cp_aguas_pet_591_swsg.png";

//cuadrado

// import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco.png";
// import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro.png";
// import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa.png";

import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso.png";

import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp.png";

//aca
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_cc from "../../../assets/image/07_elementos_plantillas_cuadradas/10_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_cc.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/10__plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_blanco.png";
import plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/10__plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_negro.png";

import image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_cuadrada_combo_duo_cp_stills_pet_500_aq_pom.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_cuadrada_combo_duo_cp_stills_pet_500_aq_pom_desc_blanco.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_cuadrada_combo_duo_cp_stills_pet_500_aq_pom_desc_negro.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_fn.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_blanco.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_negro.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_kin.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_blano from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_blano.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_negro.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_sp.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_blanco.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_negro.png";

import image_cuadrada_combo_duo_cp_agua_pet_591_swsg_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/29_plantilla_cuadrada_combo_duo_cp_aguas_pet_591_swsg_desc_blanco.png";
import image_cuadrada_combo_duo_cp_agua_pet_591_swsg_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/29_plantilla_cuadrada_combo_duo_cp_aguas_pet_591_swsg_desc_negro.png";
import image_cuadrada_combo_duo_cp_agua_pet_591_swsg from "../../../assets/image/07_elementos_plantillas_cuadradas/29_plantilla_cuadrada_combo_duo_cp_aguas_pet_591_swsg.png";

/////SIMPLE
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_manzana.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_manzana_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_manzana_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pera.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pera_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pera_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pomelo.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pomelo_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_aq_pomelo_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_naranja_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_cepita_fresh_pomelo_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_kin from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_kin.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_kin_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_kin_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_sw from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_sw.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_sw_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_1.5_sw_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_pet_225_kin from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_2.25_kin.png";
import image_cuadrada_combo_simple_cf_stills_pet_225_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_2.25_kin_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_225_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_pet_2.25_kin_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa.png";
//import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa.png";
//import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro.png";
//
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_fn.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_sp.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl.png";
//import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro.png";

/////
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro.png";

////TODO reemplazar por 237
//import image_cuadrada_combo_simple_cp_gaseosas_pet_600_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_600_fn.png";
//import image_cuadrada_combo_simple_cp_gaseosas_pet_600_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_600_fn_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_gaseosas_pet_600_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_600_fn_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro.png";

//TODO
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_sp.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana.png";
//import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_man.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_man_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_man_desc_negro.png";

/////
///import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera.png";
///import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco.png";
//import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_negro.png";

///import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo.png";
///import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco.png";
///import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pom.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pom_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_aq_pom_desc_negro.png";

import image_cuadrada_combo_simple_cp_stills_pet_500_kin from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_kin.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/07_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro.png";

// /* NUEVOS CUAD */

//TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccl_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccl.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccl_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccl_desc_negro.png";
//TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccsa.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccsa_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccso.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_ccso_desc_negro.png";

//TODO
///import image_cuadrada_combo_simple_cf_gaseosas_pet_15_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_fn.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_fn_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_fn_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_fn.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_fn_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_sp.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_sp_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_sp_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_sp.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_spl_desc_negro.png";

//TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa.png";
////import image_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_15_spsa_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_spsa.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_spsa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_1,75_spsa_desc_negro.png";

//////TODO
//import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl.png";
////import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccl.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccl_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccl_desc_negro.png";

//TODO cambia la imagen
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccsa.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_ccsa_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro.png";

import image_cuadrada_combo_simple_cp_aguas_pet_591_sw from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_aguas_pet_591_sw.png";
import image_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_blanco.png";
import image_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/17_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco.png";

import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_negro.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/16_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro.png";

//TODO nuevas

import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fn.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fl.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_negro.png";

import image_cuadrada_combo_simple_cp_stills_pet_500_ksg from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_ksg.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_blanco.png";
import image_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro.png";

import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_fl.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_fl_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2,25_fl_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_man.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_man_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_man_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_nar.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_nar_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_nar_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_pom.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_pom_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25_cepita_fresh_pom_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_frutas_tro.png";
import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_frutas_tro_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_frutas_tro_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_nar.png";
import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_nar_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_stills_tetra_200_ades_nar_desc_negro.png";

import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro.png";

import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco .png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro.png";

import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro.png";

import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro.png";

import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco.png";
import image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro.png";

import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/18_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro.png";

import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_rec_combo_duo_cp_gaseosas_pet_500_ccsa-07.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco.png";
import image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/21_plantilla_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro.png";


import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/24_plantilla_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro.png";
//
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_man.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_man_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_man_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_desc_negro.png";

//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_nar.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_nar_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_nar_desc_negro.png";

import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pera.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pera_desc_blanco.png";
import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pera_desc_negro.png";

//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_rosado_desc_negro.png";

//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_sa.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_sa_desc_blanco.png";
//import image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/27_plantilla_cuadrada_combo_simple_cf_stills_pet_2,25L_aq_pom_sa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccsa_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_ccsa.png";

import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_fn_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_fn_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_fn.png";

import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/30_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_237_sp.png";

import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_sp_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_sp.png";

import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_fn_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_fn.png";

import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa from "../../../assets/image/07_elementos_plantillas_cuadradas/31_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa.png";

import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/32_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/32_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_blanco.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/32_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccso.png";

import image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_y_ccso.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_y_ccso_desc_negro.png";
import image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_y_ccso_desc_blanco.png";

import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_ccso.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_negro from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_ccso_desc_negro.png";
import image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_blanco from "../../../assets/image/07_elementos_plantillas_cuadradas/33_plantilla_cuadrada_combo_simple_cf_gaseosas_pet_2_5_ccsa_y_ccso_desc_blanco.png";

// COMIDAS ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Formato rectangular
import comidaMarcoRect01 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_1.png";
import comidaMarcoRect02 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_2.png";
import comidaMarcoRect03 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_3.png";
import comidaMarcoRect04 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_4.png";
import comidaMarcoRect05 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_5.png";
import comidaMarcoRect06 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_6.png";
import comidaMarcoRect07 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_7.png";
import comidaMarcoRect08 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_8.png";
import comidaMarcoRect09 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_9.png";
import comidaMarcoRect10 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_10.png";
import comidaMarcoRect11 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_11.png";
import comidaMarcoRect12 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_12.png";
import comidaMarcoRect13 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_13.png";
import comidaMarcoRect14 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_14.png";
import comidaMarcoRect15 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_15.png";
import comidaMarcoRect16 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_16.png";
import comidaMarcoRect17 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_17.png";
import comidaMarcoRect18 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_18.png";
import comidaMarcoRect19 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_19.png";
import comidaMarcoRect20 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_20.png";
import comidaMarcoRect21 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_21.png";
import comidaMarcoRect22 from "../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_marco_comida_22.png";

import comidaMarcoRect23 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_23.png";
import comidaMarcoRect24 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_24.png";
import comidaMarcoRect25 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_25.png";
import comidaMarcoRect26 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_26.png";
import comidaMarcoRect27 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_27.png";
import comidaMarcoRect28 from "../../../assets/image/03_plantillas_combo_rectangular/20_plantilla_combo_rectangular_marco_comida_28.png";

import comidaMarcoRect29 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_29.png";
import comidaMarcoRect30 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_30.png";
import comidaMarcoRect31 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_31.png";
import comidaMarcoRect32 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_32.png";
import comidaMarcoRect33 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_33.png";
import comidaMarcoRect34 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_34.png";
import comidaMarcoRect35 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_35.png";
import comidaMarcoRect36 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_36.png";
import comidaMarcoRect37 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_37.png";
import comidaMarcoRect38 from "../../../assets/image/03_plantillas_combo_rectangular/22_plantilla_combo_rectangular_marco_comida_38.png";

import comidaMarcoRect39 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_39.png";
import comidaMarcoRect40 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_40.png";
import comidaMarcoRect41 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_41.png";
import comidaMarcoRect42 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_42.png";
import comidaMarcoRect43 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_43.png";
import comidaMarcoRect49 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_49.png";
import comidaMarcoRect50 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_50.png";
import comidaMarcoRect51 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_51.png";
import comidaMarcoRect52 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_52.png";
import comidaMarcoRect53 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_53.png";
import comidaMarcoRect54 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_54.png";
import comidaMarcoRect55 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_55.png";
import comidaMarcoRect56 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_56.png";
import comidaMarcoRect57 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_57.png";
import comidaMarcoRect58 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_58.png";
import comidaMarcoRect59 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_59.png";
import comidaMarcoRect60 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_60.png";
import comidaMarcoRect61 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_61.png";
import comidaMarcoRect62 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_62.png";
import comidaMarcoRect63 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_63.png";
import comidaMarcoRect64 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_64.png";
import comidaMarcoRect65 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_65.png";
import comidaMarcoRect66 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_66.png";
import comidaMarcoRect67 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_67.png";
import comidaMarcoRect68 from "../../../assets/image/03_plantillas_combo_rectangular/25_plantilla_combo_rectangular_marco_comida_68.png";
import comidaMarcoRect69 from "../../../assets/image/03_plantillas_combo_rectangular/25_plantilla_combo_rectangular_marco_comida_69.png";
import comidaMarcoRect70 from "../../../assets/image/03_plantillas_combo_rectangular/25_plantilla_combo_rectangular_marco_comida_70.png";
import comidaMarcoRect71 from "../../../assets/image/03_plantillas_combo_rectangular/25_plantilla_combo_rectangular_marco_comida_71.png";

import comidaMarcoRect44 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_44.png";
import comidaMarcoRect45 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_45.png";
import comidaMarcoRect46 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_46.png";
import comidaMarcoRect47 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_47.png";
import comidaMarcoRect48 from "../../../assets/image/03_plantillas_combo_rectangular/23_plantilla_combo_rectangular_marco_comida_48.png";

// Formato cuadrado
import comidaMarcoCuad01 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_1.png";
import comidaMarcoCuad02 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_2.png";
import comidaMarcoCuad03 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_3.png";
import comidaMarcoCuad04 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_4.png";
import comidaMarcoCuad05 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_5.png";
import comidaMarcoCuad06 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_6.png";
import comidaMarcoCuad07 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_7.png";
import comidaMarcoCuad08 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_8.png";
import comidaMarcoCuad09 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_9.png";
import comidaMarcoCuad10 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_10.png";
import comidaMarcoCuad11 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_11.png";
import comidaMarcoCuad12 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_12.png";
import comidaMarcoCuad13 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_13.png";
import comidaMarcoCuad14 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_14.png";
import comidaMarcoCuad15 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_15.png";
import comidaMarcoCuad16 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_16.png";
import comidaMarcoCuad17 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_17.png";
import comidaMarcoCuad18 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_18.png";
import comidaMarcoCuad19 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_19.png";
import comidaMarcoCuad20 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_20.png";
import comidaMarcoCuad21 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_21.png";
import comidaMarcoCuad22 from "../../../assets/image/04_plantillas_combo_cuadrada/04_plantilla_combo_rectangular_marco_comida_22.png";

//TODO
import comidaMarcoCuad23 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_23.png";
import comidaMarcoCuad24 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_24.png";
import comidaMarcoCuad25 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_25.png";
import comidaMarcoCuad26 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_26.png";
import comidaMarcoCuad27 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_27.png";
import comidaMarcoCuad28 from "../../../assets/image/04_plantillas_combo_cuadrada/20_plantilla_combo_cuadrada_marco_comida_28.png";

import comidaMarcoCuad29 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_29.png";
import comidaMarcoCuad30 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_30.png";
import comidaMarcoCuad31 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_31.png";
import comidaMarcoCuad32 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_32.png";
import comidaMarcoCuad33 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_33.png";
import comidaMarcoCuad34 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_34.png";
import comidaMarcoCuad35 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_35.png";
import comidaMarcoCuad36 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_36.png";
import comidaMarcoCuad37 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_37.png";
import comidaMarcoCuad38 from "../../../assets/image/04_plantillas_combo_cuadrada/22_plantilla_combo_cuadrada_marco_comida_38.png";

import comidaMarcoCuad39 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_39.png";
import comidaMarcoCuad40 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_40.png";
import comidaMarcoCuad41 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_41.png";
import comidaMarcoCuad42 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_42.png";
import comidaMarcoCuad43 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_43.png";
import comidaMarcoCuad49 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_49.png";
import comidaMarcoCuad50 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_50.png";
import comidaMarcoCuad51 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_51.png";
import comidaMarcoCuad52 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_52.png";
import comidaMarcoCuad53 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_53.png";
import comidaMarcoCuad54 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_54.png";
import comidaMarcoCuad55 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_55.png";
import comidaMarcoCuad56 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_56.png";
import comidaMarcoCuad57 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_57.png";
import comidaMarcoCuad58 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_58.png";
import comidaMarcoCuad59 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_59.png";
import comidaMarcoCuad60 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_60.png";
import comidaMarcoCuad61 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_61.png";
import comidaMarcoCuad62 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_62.png";
import comidaMarcoCuad63 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_63.png";
import comidaMarcoCuad64 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_64.png";
import comidaMarcoCuad65 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_65.png";
import comidaMarcoCuad66 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_66.png";
import comidaMarcoCuad67 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_67.png";
import comidaMarcoCuad68 from "../../../assets/image/04_plantillas_combo_cuadrada/25_plantilla_combo_cuadrada_marco_comida_68.png";
import comidaMarcoCuad69 from "../../../assets/image/04_plantillas_combo_cuadrada/25_plantilla_combo_cuadrada_marco_comida_69.png";
import comidaMarcoCuad70 from "../../../assets/image/04_plantillas_combo_cuadrada/25_plantilla_combo_cuadrada_marco_comida_70.png";
import comidaMarcoCuad71 from "../../../assets/image/04_plantillas_combo_cuadrada/25_plantilla_combo_cuadrada_marco_comida_71.png";

import comidaMarcoCuad44 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_44.png";
import comidaMarcoCuad45 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_45.png";
import comidaMarcoCuad46 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_46.png";
import comidaMarcoCuad47 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_47.png";
import comidaMarcoCuad48 from "../../../assets/image/04_plantillas_combo_cuadrada/23_plantilla_combo_cuadrada_marco_comida_48.png";

////Navidad
//rectangular
import plantilla_navidad_duo_rec_motivo_1 from "../../../assets/image/navidad/rectangular/26_galeria_plantilla_navidad_combo_duo_rec_motivo_1.png";
import plantilla_navidad_duo_rec_motivo_2 from "../../../assets/image/navidad/rectangular/26_galeria_plantilla_navidad_combo_duo_rec_motivo_2.png";
import plantilla_navidad_simple_rec_motivo_1 from "../../../assets/image/navidad/rectangular/26_galeria_plantilla_navidad_combo_simple_rec_motivo_1.png";
import plantilla_navidad_simple_rec_motivo_2 from "../../../assets/image/navidad/rectangular/26_galeria_plantilla_navidad_combo_simple_rec_motivo_2.png";
import plantilla_navidad_simple_y_duo_rec_fondo_motivo_1 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_simple_y_duo_rec_fondo_motivo_1.png";
import plantilla_navidad_simple_y_duo_rec_fondo_motivo_2 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_simple_y_duo_rec_fondo_motivo_2.png";
import duo_rec_cinta_1 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_duo_rec_cinta_1_motivo_1.png";
import duo_rec_cinta_2 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_duo_rec_cinta_2_motivo_1.png";

import duo_rec_sticker_1 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_simple_y_duo_rec_sticker_1_motivo_2.png";
import duo_rec_sticker_2 from "../../../assets/image/navidad/rectangular/26_plantilla_navidad_combo_simple_y_duo_rec_sticker_2_motivo_2.png";
//cuadrada
import plantilla_navidad_duo_cuadrada_motivo_1 from "../../../assets/image/navidad/cuadrada/26_galeria_plantilla_navidad_combo_duo_cuadrada_motivo_1.png";
import plantilla_navidad_duo_cuadrada_motivo_2 from "../../../assets/image/navidad/cuadrada/26_galeria_plantilla_navidad_combo_duo_cuadrada_motivo_2.png";
import plantilla_navidad_simple_cuadrada_motivo_2 from "../../../assets/image/navidad/cuadrada/26_galeria_plantilla_navidad_combo_simple_cuadrada_motivo_2.png";
import plantilla_navidad_simple_cuadrada_motivo_1 from "../../../assets/image/navidad/cuadrada/26_galeria_plantilla_navidad_combo_simple_cuadrada_motivo_1.png";
import plantilla_navidad_duo_cuadrada_fondo_motivo_1 from "../../../assets/image/navidad/cuadrada/26_plantilla_navidad_combo_simple_y_duo_cuadrada_fondo_motivo_2.png";
import plantilla_navidad_duo_cuadrada_fondo_motivo_2 from "../../../assets/image/navidad/cuadrada/26_plantilla_navidad_combo_simple_y_duo_cuadrada_fondo_motivo_1.png";
import duo_cuad_cinta_1 from "../../../assets/image/navidad/cuadrada/26_plantilla_navidad_combo_dua_cuadrada_cinta_1_motivo_1.png";
import duo_cuad_cinta_2 from "../../../assets/image/navidad/cuadrada/26_plantilla_navidad_combo_dua_cuadrada_cinta_2_motivo_1.png";

import colors from "../../../assets/colors";

// logos
const initializeReducer = () => ({
  etapas: ["PLANTILLA", "LOGO", "DESCRIPCION", "BEBIDA", "COMIDA", "CONTACTO"],
  etapa: "PLANTILLA",

  plantilla: undefined,
  logo: undefined,
  descripcion: undefined,
  bebida: undefined,
  comida: undefined,
  contacto: undefined,

  imagenFinal: undefined,

  setSelUserLogo: false,
  setSelUserComida: false,
  setSelUserContacto: false,
  setSelUserBebida: false,
  setSelUserDescripcion: false,

  setCheckLogoCombo: false,

  plantillas: [
    //////navidad - rec
    /*  {
      src: plantilla_navidad_duo_rec_motivo_1,
      id: "plantilla_navidad_duo_rec_motivo_1",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: plantilla_navidad_simple_y_duo_rec_fondo_motivo_1,
        logo: undefined,
        sinLogoColor: "#456a98",
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          titulo_plantilla_roja: "ok",
          tipo_pizza: "ok",
          tipo_burger: "ok",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "2 Burger Palermo",
          titulo_comida_color: "#dc0f1d",
          descp_combo:
            "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: duo_rec_cinta_1,
        cinta2: duo_rec_cinta_2,
        comida: comidaMarcoRect06,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "",
        legal_color: "white",

        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
        //
      },
    },
    {
      src: plantilla_navidad_duo_rec_motivo_2,
      id: "plantilla_navidad_duo_rec_motivo_2",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: plantilla_navidad_simple_y_duo_rec_fondo_motivo_2,
        logo: undefined,
        sinLogoColor: "#50b3dfb5",
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          titulo_combo_font: "GilroyBold",
          titulo_combo_case: "upperCase",
          //titulo_combo_color: "#dc0f1d",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "2 Burger Palermo",
          titulo_comida_color: "#dc0f1d",
          descp_combo:
            "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "blanco",
        bebida_image_text:
          image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        sticker1: duo_rec_sticker_1,
        sticker2: duo_rec_sticker_2,
        comida: comidaMarcoRect06,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "",
        legal_color: "black",

        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
        //
      },
    },
    ////////
    {
      src: plantilla_navidad_simple_rec_motivo_1,
      id: "plantilla_navidad_simple_rec_motivo_1",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: plantilla_navidad_simple_y_duo_rec_fondo_motivo_1,
        logo: undefined,
        sinLogoColor: "#50b3dfb5",
        descripcion: {
          titulo_combo: "Combo Pizza",
          titulo_combo_font: "GilroyBold",
          tipo_pizza: "ok",

          //titulo_combo_case: "upperCase",
          titulo_plantilla_roja: "ok",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "Pizza grande clásica",
          titulo_comida_color: "#dc0f1d",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: duo_rec_cinta_1,
        cinta2: duo_rec_cinta_2,
        comida: comidaMarcoRect01,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "",
        legal_color: "white",

        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
        //
      },
    },

    {
      src: plantilla_navidad_simple_rec_motivo_2,
      id: "plantilla_navidad_simple_rec_motivo_2",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: plantilla_navidad_simple_y_duo_rec_fondo_motivo_2,
        logo: undefined,
        sinLogoColor: "#50b3dfb5",
        descripcion: {
          titulo_combo: "Combo Pizza",
          titulo_combo_font: "GilroyBold",
          titulo_combo_case: "upperCase",
          titulo_combo_color: "#dc0f1d",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "Pizza grande clásica",
          titulo_comida_color: "#dc0f1d",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        sticker1: duo_rec_sticker_1,
        sticker2: duo_rec_sticker_2,
        comida: comidaMarcoRect01,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "",
        legal_color: "black",

        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
        //
      },
    },
    //cuadrado
    {
      src: plantilla_navidad_duo_cuadrada_motivo_2,
      id: "plantilla_navidad_duo_cuadrada_motivo_2",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: plantilla_navidad_duo_cuadrada_fondo_motivo_2,
        logo: undefined,
        sinLogoColor: "#456a98",
        descripcion: {
          titulo_combo: "Combo mega burger",
          titulo_combo_color: "white",
          titulo_plantilla_roja: "ok",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "2 Burger Palermo",
          titulo_comida_color: "#dc0f1d",
          descp_combo:
            "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "blanco",
        bebida_image_text:
          image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: duo_cuad_cinta_1,
        cinta2: duo_cuad_cinta_2,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: plantilla_navidad_duo_cuadrada_motivo_1,
      id: "plantilla_navidad_duo_cuadrada_motivo_1",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: plantilla_navidad_duo_cuadrada_fondo_motivo_1,
        logo: undefined,
        sinLogoColor: "#50b3dfb5",
        descripcion: {
          titulo_combo: "Combo mega burger",
          titulo_combo_color: "#dc0f1d",
          titulo_combo_case: "upperCase",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          titulo_comida_color: "#dc0f1d",
          descp_combo:
            "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: "",
        cinta2: "",
        sticker1: duo_rec_sticker_1,
        sticker2: duo_rec_sticker_2,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: plantilla_navidad_simple_cuadrada_motivo_1,
      id: "plantilla_navidad_simple_cuadrada_motivo_1",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: plantilla_navidad_duo_cuadrada_fondo_motivo_2,
        logo: undefined,
        sinLogoColor: "#456a98",
        descripcion: {
          titulo_combo: "Combo Pizza",
          titulo_combo_color: "white",
          titulo_plantilla_roja: "ok",
          tipo_pizza: "ok",
          precio: "000",
          border_precio_color: "white",
          titulo_comida: "Pizza grande clásica",
          titulo_comida_color: "#dc0f1d",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: duo_cuad_cinta_1,
        cinta2: duo_cuad_cinta_2,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: plantilla_navidad_simple_cuadrada_motivo_2,
      id: "plantilla_navidad_simple_cuadrada_motivo_2",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: plantilla_navidad_duo_cuadrada_fondo_motivo_1,
        logo: undefined,
        sinLogoColor: "#50b3dfb5",
        descripcion: {
          titulo_combo: "Combo Pizza",
          titulo_combo_color: "#dc0f1d",
          titulo_combo_case: "upperCase",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          titulo_comida_color: "#dc0f1d",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text:
          image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        motivo: "navidad",
        cinta1: "",
        cinta2: "",
        sticker1: duo_rec_sticker_1,
        sticker2: duo_rec_sticker_2,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    }, */
    //////////////
    {
      src: combo_duo_rec_1,
      id: "combo_duo_rec_1",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect01,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo 222",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        presetFoodPhoto: true,
        comidaUser: undefined,
        comida: comidaMarcoRect06,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com. Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. ",
      },
    },
    {
      src: combo_duo_cuad_1,
      id: "combo_duo_cuad_1",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad01,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_2,
      id: "combo_duo_rec_2",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect02,
        logo: undefined,
        sinLogoColor: "lightgrey",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "blanco",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_2,
      id: "combo_duo_cuad_2",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad02,
        logo: undefined,
        sinLogoColor: "lightgrey",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_3,
      id: "combo_duo_rec_3",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect03,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: "black",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "black",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_3,
      id: "combo_duo_cuad_3",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad03,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_4,
      id: "combo_duo_rec_4",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect04,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_4,
      id: "combo_duo_cuad_4",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad04,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_5,
      id: "combo_duo_cuad_5",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad05,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_6,
      id: "combo_duo_rec_6",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect06,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_6,
      id: "combo_duo_cuad_6",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad06,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_1,
      id: "combo_simple_rec_1",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect01,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_2,
      id: "combo_simple_rec_2",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect02,
        logo: undefined,
        sinLogoColor: "lightgrey",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "blanco",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_3,
      id: "combo_simple_rec_3",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect03,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_4,
      id: "combo_simple_rec_4",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect05,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_5,
      id: "combo_simple_rec_5",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect04,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo mega burger",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_6,
      id: "combo_simple_rec_6",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect06,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_1,
      id: "combo_simple_cuad_1",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad01,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "cuentaFacebook",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_3,
      id: "combo_simple_cuad_3",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad03,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_4,
      id: "combo_simple_cuad_4",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad04,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_5,
      id: "combo_simple_cuad_5",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad05,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: colors.palette.primary,
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "white",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_6,
      id: "combo_simple_cuad_6",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad06,
        logo: undefined,
        sinLogoColor: "lightblue",
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza grande clásica",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },

    /////////TODOOOO
    {
      src: combo_duo_cuad_13,
      id: "combo_duo_cuad_13",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad07,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_13,
      id: "combo_simple_cuad_13",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad07,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_13,
      id: "combo_duo_rec_13",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect07,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_13,
      id: "combo_simple_rec_13",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect07,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_14,
      id: "combo_duo_cuad_14",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad08,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_14,
      id: "combo_simple_cuad_14",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad08,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_14,
      id: "combo_duo_rec_14",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect08,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_14,
      id: "combo_simple_rec_14",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect08,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "white",
        logo_cc_color: "red",
        legal_color: "white",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_15,
      id: "combo_duo_cuad_15",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad09,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_15,
      id: "combo_simple_cuad_15",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad09,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_15,
      id: "combo_duo_rec_15",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect09,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_15,
      id: "combo_simple_rec_15",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect09,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_16,
      id: "combo_duo_cuad_16",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad10,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_16,
      id: "combo_simple_cuad_16",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad10,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_16,
      id: "combo_duo_rec_16",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect10,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_16,
      id: "combo_simple_rec_16",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect10,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_17,
      id: "combo_duo_cuad_17",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad11,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_17,
      id: "combo_simple_cuad_17",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad11,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_17,
      id: "combo_duo_rec_17",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect11,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_17,
      id: "combo_simple_rec_17",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect11,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_18,
      id: "combo_duo_cuad_18",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad12,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_18,
      id: "combo_simple_cuad_18",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad12,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_18,
      id: "combo_duo_rec_18",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect12,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_18,
      id: "combo_simple_rec_18",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect12,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_19,
      id: "combo_duo_cuad_19",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad13,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_19,
      id: "combo_simple_cuad_19",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad13,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_19,
      id: "combo_duo_rec_19",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect13,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_19,
      id: "combo_simple_rec_19",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect13,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_20,
      id: "combo_duo_cuad_20",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad14,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_20,
      id: "combo_simple_cuad_20",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad14,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_20,
      id: "combo_duo_rec_20",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect14,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_20,
      id: "combo_simple_rec_20",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect14,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_cuad_21,
      id: "combo_duo_cuad_21",
      ref: "duo",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad15,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoCuad06, //
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_cuad_21,
      id: "combo_simple_cuad_21",
      ref: "simple",
      forma: "cuadrado",
      combo: {
        srcPlantilla: cuad15,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoCuad01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_duo_rec_21,
      id: "combo_duo_rec_21",
      ref: "duo",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect15,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Mega Burger",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "2 Burger Palermo",
          descp_combo: "Hamburgesa 320gr, queso azul, chedar, cebolla morada caramelizada, mostaza dulce, tomate y lechuga",
        },
        bebida: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
        comida: comidaMarcoRect06,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
    {
      src: combo_simple_rec_21,
      id: "combo_simple_rec_21",
      ref: "simple",
      forma: "rectangular",
      combo: {
        srcPlantilla: rect15,
        logo: undefined,
        sinLogoColor: colors.palette.colorLogo,
        descripcion: {
          titulo_combo: "Combo Pizza",
          precio: "000",
          border_precio_color: "red",
          titulo_comida: "Pizza Grande Clásica",
          descp_combo: "Pizza a la piedra de mozzarella y jamón",
        },
        bebida: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
        drinkBrand: "",
        drinkLine: "",
        drinkLitrage: "",
        bebida_color_text: "negro",
        bebida_image_text: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
        comida: comidaMarcoRect01,
        presetFoodPhoto: true,
        comidaUser: undefined,
        contacto: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
        color_text: "black",
        logo_cc_color: "red",
        legal_color: "black",
        legal:
          "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
      },
    },
  ],
  comidas: [
    {
      src: comidaMarcoRect01,
      id: "comidaMarcoRect01",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoRect02,
      id: "comidaMarcoRect02",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoRect03,
      id: "comidaMarcoRect03",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect04,
      id: "comidaMarcoRect04",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect05,
      id: "comidaMarcoRect05",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect06,
      id: "comidaMarcoRect06",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect07,
      id: "comidaMarcoRect07",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoRect08,
      id: "comidaMarcoRect08",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect09,
      id: "comidaMarcoRect09",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect10,
      id: "comidaMarcoRect10",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect11,
      id: "comidaMarcoRect11",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect12,
      id: "comidaMarcoRect12",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect13,
      id: "comidaMarcoRect13",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect14,
      id: "comidaMarcoRect14",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect15,
      id: "comidaMarcoRect15",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect16,
      id: "comidaMarcoRect16",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect17,
      id: "comidaMarcoRect17",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect18,
      id: "comidaMarcoRect18",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect19,
      id: "comidaMarcoRect19",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect20,
      id: "comidaMarcoRect20",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect21,
      id: "comidaMarcoRect21",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect22,
      id: "comidaMarcoRect22",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect23,
      id: "comidaMarcoRect23",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect24,
      id: "comidaMarcoRect24",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoRect25,
      id: "comidaMarcoRect25",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoRect26,
      id: "comidaMarcoRect26",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect27,
      id: "comidaMarcoRect27",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoRect28,
      id: "comidaMarcoRect28",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect29,
      id: "comidaMarcoRect29",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoRect30,
      id: "comidaMarcoRect30",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect31,
      id: "comidaMarcoRect31",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect32,
      id: "comidaMarcoRect32",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoRect33,
      id: "comidaMarcoRect33",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect34,
      id: "comidaMarcoRect34",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoRect35,
      id: "comidaMarcoRect35",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect36,
      id: "comidaMarcoRect36",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoRect37,
      id: "comidaMarcoRect37",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoRect38,
      id: "comidaMarcoRect38",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },

    {
      src: comidaMarcoRect39,
      id: "comidaMarcoRect39",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect40,
      id: "comidaMarcoRect40",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect41,
      id: "comidaMarcoRect41",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect42,
      id: "comidaMarcoRect42",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect43,
      id: "comidaMarcoRect43",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    {
      src: comidaMarcoRect49,
      id: "comidaMarcoRect49",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect50,
      id: "comidaMarcoRect50",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },

    {
      src: comidaMarcoRect51,
      id: "comidaMarcoRect51",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoRect52,
      id: "comidaMarcoRect52",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect53,
      id: "comidaMarcoRect53",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect54,
      id: "comidaMarcoRect54",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect55,
      id: "comidaMarcoRect55",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect56,
      id: "comidaMarcoRect56",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect57,
      id: "comidaMarcoRect57",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect58,
      id: "comidaMarcoRect58",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect59,
      id: "comidaMarcoRect59",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect60,
      id: "comidaMarcoRect60",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect61,
      id: "comidaMarcoRect61",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    {
      src: comidaMarcoRect62,
      id: "comidaMarcoRect62",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect63,
      id: "comidaMarcoRect63",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect64,
      id: "comidaMarcoRect64",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect65,
      id: "comidaMarcoRect65",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoRect66,
      id: "comidaMarcoRect66",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect67,
      id: "comidaMarcoRect67",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect68,
      id: "comidaMarcoRect68",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoRect69,
      id: "comidaMarcoRect69",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect70,
      id: "comidaMarcoRect70",
      ref: "rectangular",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoRect71,
      id: "comidaMarcoRect71",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    {
      src: comidaMarcoRect44,
      id: "comidaMarcoRect44",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoRect45,
      id: "comidaMarcoRect45",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoRect46,
      id: "comidaMarcoRect46",
      ref: "rectangular",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoRect47,
      id: "comidaMarcoRect47",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoRect48,
      id: "comidaMarcoRect48",
      ref: "rectangular",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    ///

    {
      src: comidaMarcoCuad01,
      id: "comidaMarcoCuad01",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoCuad02,
      id: "comidaMarcoCuad02",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoCuad03,
      id: "comidaMarcoCuad03",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad04,
      id: "comidaMarcoCuad04",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad05,
      id: "comidaMarcoCuad05",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad06,
      id: "comidaMarcoCuad06",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad07,
      id: "comidaMarcoCuad07",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoCuad08,
      id: "comidaMarcoCuad08",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad09,
      id: "comidaMarcoCuad09",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad10,
      id: "comidaMarcoCuad10",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad11,
      id: "comidaMarcoCuad11",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad12,
      id: "comidaMarcoCuad12",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad13,
      id: "comidaMarcoCuad13",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad14,
      id: "comidaMarcoCuad14",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad15,
      id: "comidaMarcoCuad15",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad16,
      id: "comidaMarcoCuad16",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad17,
      id: "comidaMarcoCuad17",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad18,
      id: "comidaMarcoCuad18",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad19,
      id: "comidaMarcoCuad19",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad20,
      id: "comidaMarcoCuad20",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad21,
      id: "comidaMarcoCuad21",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad22,
      id: "comidaMarcoCuad22",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad23,
      id: "comidaMarcoCuad23",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad24,
      id: "comidaMarcoCuad24",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad25,
      id: "comidaMarcoCuad25",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoCuad26,
      id: "comidaMarcoCuad26",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad27,
      id: "comidaMarcoCuad27",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoCuad28,
      id: "comidaMarcoCuad28",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad29,
      id: "comidaMarcoCuad29",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoCuad30,
      id: "comidaMarcoCuad30",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad31,
      id: "comidaMarcoCuad31",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad32,
      id: "comidaMarcoCuad32",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoCuad33,
      id: "comidaMarcoCuad33",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad34,
      id: "comidaMarcoCuad34",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "snackSalado"],
    },
    {
      src: comidaMarcoCuad35,
      id: "comidaMarcoCuad35",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad36,
      id: "comidaMarcoCuad36",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas"],
    },
    {
      src: comidaMarcoCuad37,
      id: "comidaMarcoCuad37",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad38,
      id: "comidaMarcoCuad38",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad39,
      id: "comidaMarcoCuad39",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad40,
      id: "comidaMarcoCuad40",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad41,
      id: "comidaMarcoCuad41",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad42,
      id: "comidaMarcoCuad42",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad43,
      id: "comidaMarcoCuad43",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    {
      src: comidaMarcoCuad49,
      id: "comidaMarcoCuad49",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad50,
      id: "comidaMarcoCuad50",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad51,
      id: "comidaMarcoCuad51",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad52,
      id: "comidaMarcoCuad52",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad53,
      id: "comidaMarcoCuad53",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad54,
      id: "comidaMarcoCuad54",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad55,
      id: "comidaMarcoCuad55",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad56,
      id: "comidaMarcoCuad56",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad57,
      id: "comidaMarcoCuad57",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad58,
      id: "comidaMarcoCuad58",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad59,
      id: "comidaMarcoCuad59",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad60,
      id: "comidaMarcoCuad60",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad61,
      id: "comidaMarcoCuad61",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad62,
      id: "comidaMarcoCuad62",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad63,
      id: "comidaMarcoCuad63",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad64,
      id: "comidaMarcoCuad64",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad65,
      id: "comidaMarcoCuad65",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackSalado"],
    },
    {
      src: comidaMarcoCuad66,
      id: "comidaMarcoCuad66",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad67,
      id: "comidaMarcoCuad67",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad68,
      id: "comidaMarcoCuad68",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad69,
      id: "comidaMarcoCuad69",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad70,
      id: "comidaMarcoCuad70",
      ref: "cuadrado",
      marco: true,
      ocasion: ["comidasRapidas", "almuerzo/cena"],
    },
    {
      src: comidaMarcoCuad71,
      id: "comidaMarcoCuad71",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },

    {
      src: comidaMarcoCuad44,
      id: "comidaMarcoCuad44",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoCuad45,
      id: "comidaMarcoCuad45",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad46,
      id: "comidaMarcoCuad46",
      ref: "cuadrado",
      marco: true,
      ocasion: ["snackDulce"],
    },
    {
      src: comidaMarcoCuad47,
      id: "comidaMarcoCuad47",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena", "comidasRapidas"],
    },
    {
      src: comidaMarcoCuad48,
      id: "comidaMarcoCuad48",
      ref: "cuadrado",
      marco: true,
      ocasion: ["almuerzo/cena"],
    },
  ],
  bebidas: [
    // RECTANGULAR DUO ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_ccso,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_ccso",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sabor Original",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_ccsa,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_ccsa",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_sp,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_sp",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "500ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_fn,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_fn",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },

    // aguas
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_kin,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_kin",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_blano,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_rec_combo_duo_cp_gaseosas_pet_500_aq_pomelo,
      id: "image_rec_combo_duo_cp_gaseosas_pet_500_aq_pomelo",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_duo_cp_aguas_pet_591_swsg,
      id: "image_rec_combo_duo_cp_aguas_pet_591_swsg",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_rec_combo_duo_cp_aguas_pet_591_swsg_desc_blanco,
      text_negro: image_rec_combo_duo_cp_aguas_pet_591_swsg_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "591cc",
      description: "Agua mineraliza sin gas",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente gasificada. Imágenes de fondo y comida: freepik.com.",
    },

    // RECTANGULAR SIMPLE ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    //gaseosas
    {
      src: plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_cc,
      id: "plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_cc",
      ref: "rectangular",
      combo: "simple",
      text_blanco: plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_blanco,
      text_negro: plantilla_rec_combo_simple_cp_gaseosas_vidrio_vaso_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "Vaso",
      description: "Sabor Original",
      legal:
        "Coca-cola, la botella contour y sprite son marcas registradas de The Coca-cola company. Coca-Cola Sabor Original: Bebida sin alcohol gasificada de extractos vegetales. Coca-Cola Sabor Sin Szúcares: Bebida sin alcohol gasificada de extractosvegetales. Sprite: Bebida sin alcohol gasificada con 5% de jugode limón. Imagen de Pixabay/FREEPIK.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee,
      id: "image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl-coffee",
      litraje: "220ml",
      description: "Con Café",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola con café: Bebida sin alcohol dietética gasificada de extractos vegetales sabor a café. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_220_ccso,
      id: "image_rec_combo_simple_cp_gaseosas_lata_220_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "220ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_220_ccsa,
      id: "image_rec_combo_simple_cp_gaseosas_lata_220_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "220ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_220_spsa,
      id: "image_rec_combo_simple_cp_gaseosas_lata_220_spsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "spsa",
      litraje: "220ml",
      description: "Sin Azucares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_237_ccso,
      id: "image_rec_combo_simple_cp_gaseosas_pet_237_ccso ",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "237ml",
      description: "Sabor Original",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_237_ccsa,
      id: "image_rec_combo_simple_cp_gaseosas_pet_237_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_237_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "237ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_237_fn,
      id: "image_rec_combo_simple_cp_gaseosas_pet_237_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_237_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "237ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_237_sp,
      id: "image_rec_combo_simple_cp_gaseosas_pet_237_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_237_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "237ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso,
      id: "plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco,
      text_negro: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "350ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "350ml",
      description: "Sabor Liviano",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabot original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "350ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp,
      id: "plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco,
      text_negro: plantilla_rec_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "350ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "350ml",
      description: "Sin Azúcares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_fn,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "350ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "350ml",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica,
      id: "image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "350ml",
      description: "Sabor Tonica",
      legal: "Schweppes es marca registrada de Atlantic Industries. Schweppes agua tónica: agua tónica. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_354_ccso,
      id: "image_rec_combo_simple_cp_gaseosas_lata_354_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "354ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_354_ccsa,
      id: "image_rec_combo_simple_cp_gaseosas_lata_354_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "354ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_354_sp,
      id: "image_rec_combo_simple_cp_gaseosas_lata_354_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_354_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "354ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_354_fn,
      id: "image_rec_combo_simple_cp_gaseosas_lata_354_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_354_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "354ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo,
      id: "image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "354ml",
      description: "Sabor Pomelo",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_ccso,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_ccsa,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_ccl,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_ccl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "500ml",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_sp,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "500ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_fn,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_fnsa,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_fnsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja sin azúcares: Bebida dietética sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseosas_pet_500_fl,
      id: "image_rec_combo_simple_cp_gaseosas_pet_500_fl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseosas_pet_500_fl_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Limón",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta limón: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso,
      id: "image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sin Azucares u original",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company.Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_ccso,
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "1,75L",
      description: "Sabor Original",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_ccsa, // TODO
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "1,75L",
      description: "Sin Azucares",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_ccl,
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_ccl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_ccl_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "1,75L",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_sp,
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "1,75L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_spsa,
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_spsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_spsa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "1,75L",
      description: "Sin Azúcares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_175_fn,
      id: "image_rec_combo_simple_cf_gaseosas_pet_175_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_175_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_175_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "1,75L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa,
      id: "image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "1,5L",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica,
      id: "image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "1,5L",
      description: "Sabor Tonica",
      legal: "Schweppes es marca registrada de Atlantic Industries. Schweppes agua tónica: agua tónica. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "2,25L",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "2,25L",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "2,25L",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_sp,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "2,25L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    // {
    //   src: image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa,
    //   id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa",
    //   ref: "rectangular",
    //   combo: "simple",
    //   text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_blanco,
    //   text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_negro,
    //   marca: "sprite",
    //   drinkLine: 'sprite',
    //   litraje: "2_25L",
    //   description: "Sin Azúcares",
    //   legal:
    //     "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    // },

    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_fn,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_fnl_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,25L",
      description: "Sabor Limón",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta limón: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "2,25L",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "2,5L",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_5_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "2,5L",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_5_sp,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_5_sp",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_5_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "2,5L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_2_5_fn,
      id: "image_rec_combo_simple_cf_gaseosas_pet_2_5_fn",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_2_5_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,5L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso,
      id: "image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso",
      ref: "rectangular",
      combo: "duo",
      text_blanco: image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "2,5L",
      description: "Sin Azucares u Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    // aguas
    {
      src: image_rec_combo_simple_cp_stills_pet_500_kin,
      id: "image_rec_combo_simple_cp_stills_pet_500_kin",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_kin_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: Agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_ksg,
      id: "image_rec_combo_simple_cp_stills_pet_500_ksg",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_ksg_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_ksg_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa con gas",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: Agua de mesa envasada con gas. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_aguas_pet_591_sw,
      id: "image_rec_combo_simple_cp_aguas_pet_591_sw",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_aguas_pet_591_sw_desc_blanco,
      text_negro: image_rec_combo_simple_cp_aguas_pet_591_sw_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "591ml",
      description: "Agua mineralizada",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente no gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_591_sw_con_gas,
      id: "image_rec_combo_simple_cp_stills_pet_591_sw_con_gas",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_591_sw_con_gas_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_591_sw_con_gas_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "591ml",
      description: "Agua mineralizada con gas",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_aq_manzana,
      id: "image_rec_combo_simple_cp_stills_pet_500_aq_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Sabor Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_aq_pera,
      id: "image_rec_combo_simple_cp_stills_pet_500_aq_pera",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Sabor Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_aq_pomelo,
      id: "image_rec_combo_simple_cp_stills_pet_500_aq_pomelo",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar,
      id: "image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom,
      id: "image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "500ml",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_kin,
      id: "image_rec_combo_simple_cf_stills_pet_1_5_kin",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_kin_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "1,5L",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_15_sw,
      id: "image_rec_combo_simple_cf_gaseosas_pet_15_sw",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_blanco, // TODO
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_negro,
      // text_blanco: image_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_blanco,
      // text_negro: image_rec_combo_simple_cf_gaseosas_pet_15_sw_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "1,5L",
      description: "Agua sin gas",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente no gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana,
      id: " image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_aq_manzana_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_aq_pera,
      id: "image_rec_combo_simple_cf_stills_pet_1_5_aq_pera",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_aq_pera_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo,
      id: "image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja,
      id: "image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_naranja_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man,
      id: "image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_blanco,
      text_negro: image_rec_combo_simple_cf_gaseosas_pet_15_cepita_fresh_man_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Manzana",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Manzana: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo,
      id: "image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_1_5_cepita_fresh_pomelo_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cf_stills_pet_2_25_kin,
      id: "image_rec_combo_simple_cf_stills_pet_2_25_kin",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25_kin_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "2,25L",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man,
      id: "image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Manzana",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Manzana: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar,
      id: "image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom,
      id: "image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_man,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_man",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25L_aq_man_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25L_aq_man_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    /*  {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar",
      ref: "rectangular",
      combo: "simple",
      text_blanco:
        image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius naranjo: Alimento líquido con 10% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    }, */
    /*  {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado",
      ref: "rectangular",
      combo: "simple",
      text_blanco:
        image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_blanco,
      text_negro:
        image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo rosado: Alimento líquido con 10% de jugo de pomelo rosado con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    }, */

    /*   {
      src: image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa,
      id: "image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa",
      ref: "rectangular",
      combo: "simple",
      text_blanco:
        image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_blanco,
      text_negro:
        image_rec_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo sin azúcar",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo sin azúcar: Alimento líquido con 10% de jugo de pomelo sin azúcar, con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },
 */
    // resto
    {
      src: image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana,
      id: "image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Manzana",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita manzana: Alimento líquido con 50% de jugo de manzana con vitaminas B3 y B6, sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta,
      id: "image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Multifruta",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita multifruta: Alimento líquido con 50% de jugo mezcla (manzana - naranja - durazno) con vitaminas B3 y B6, sabor multifruta. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_tetra_200_cepita_nar,
      id: "image_rec_combo_simple_cp_stills_tetra_200_cepita_nar",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_tetra_200_ades_manzana,
      id: "image_rec_combo_simple_cp_stills_tetra_200_ades_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Sabor Manzana",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades manzana: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor manzana - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro,
      id: "image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Sabor Frutas Tropicales",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades Frutas Tropicales: Alimento de soja líquido dietético con jugo de frutas tropicales, fuente de vitaminas A y D, fortificado con vitaminas E, C, B1, B2, B3, B6, Ácido Fólico, B12 Y Zinc - Libre de Gluten. Sin tacc. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_200_ades_nar,
      id: "image_rec_combo_simple_cf_stills_tetra_200_ades_nar",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_200_ades_nar_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_200_ades_nar_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Sabor Naranja",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades Naranja: Alimento de soja líquido dietético con jugo de naranja, fuente de vitaminas A y D, fortificado con vitaminas E, C, B1, B2, B3, B6, Ácido Fólico, B12 Y Zinc - Libre de Gluten. Sin tacc. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Manzana",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita manzana: Alimento líquido con 50% de jugo de manzana con vitaminas B3 y B6, sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Multifruta",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita multifruta: Alimento líquido con 50% de jugo mezcla (manzana - naranja - durazno) con vitaminas B3 y B6, sabor multifruta. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Naranja",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita naranja: Alimento líquido con 50% de jugo de naranja con vitaminas B3 y B6, sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Almendras",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades almendras: Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, D, E, B6, B12, ácido fólico y calcio - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Frutas Tropicales",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades frutas tropicales: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor frutas tropicales - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Manzana",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades manzana: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor manzana - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1l_ades_natural,
      id: "image_rec_combo_simple_cf_stills_tetra_1l_ades_natural",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Natural",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades natural: Alimento de soja líquido fortificado con vitaminas A, C, D, E, B2, B6, B12, ácido fólico y calcio - libre de gluten. Sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    ///
    {
      src: image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa,
      id: "image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sin Azúcar",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, D, E, B6, B12, ácido fólico y calcio - Libre de gluten sin TACC.",
    },
    {
      src: image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla,
      id: "image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco,
      text_negro: image_rec_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Vainilla",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades Vainilla: Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, C, D, E, B2, B6, B12, ácido fólico y calcio - libre de gluten. Sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop,
      id: "image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Frutas Tropicales",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade Frutas tropicales: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor mora. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_pwd_manzana,
      id: "image_rec_combo_simple_cp_stills_pet_500_pwd_manzana",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Manzana",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade manzana: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b,
      id: "image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco,
      text_negro: image_rec_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Mountain Blast",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade Mountain blast: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor mora. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener,
      id: "image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco,
      text_negro: image_rec_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Energy",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango,
      id: "image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco,
      text_negro: image_rec_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Mango loco",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_rec_combo_simple_cp_isotonicas_lata_473_monster_white,
      id: "image_rec_combo_simple_cp_isotonicas_lata_473_monster_white",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco,
      text_negro: image_rec_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Ultra",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise,
      id: "image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco,
      text_negro: image_rec_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Paradise",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise,
      id: "image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise",
      ref: "rectangular",
      combo: "simple",
      text_blanco: image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco,
      text_negro: image_rec_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Sunrise",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    // CUADRADO DUO
    // gaseosas

    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcar: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "500ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    //aguas
    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_blano,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo,
      id: "image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_gaseosas_pet_500_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },

    ///////////////ADD AGUA

    {
      src: image_cuadrada_combo_duo_cp_agua_pet_591_swsg,
      id: "image_cuadrada_combo_duo_cp_agua_pet_591_swsg",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_duo_cp_agua_pet_591_swsg_desc_blanco,
      text_negro: image_cuadrada_combo_duo_cp_agua_pet_591_swsg_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "591cc",
      description: "Agua mineraliza sin gas",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    // CUADRADO SIMPLE

    {
      src: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_cc,
      id: "plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_cc",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_blanco,
      text_negro: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_vaso_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "Vaso",
      description: "Sabor Original",
      legal:
        "Coca-cola, la botella contour y sprite son marcas registradas de The Coca-cola company. Coca-Cola Sabor Original: Bebida sin alcohol gasificada de extractos vegetales. Coca-Cola Sabor Sin Szúcares: Bebida sin alcohol gasificada de extractosvegetales. Sprite: Bebida sin alcohol gasificada con 5% de jugode limón. Imagen de Pixabay/FREEPIK.",
    },
    // gaseosas
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_220_cc_coffee_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl-coffee",
      litraje: "220ml",
      description: "Con Café",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola con café: Bebida sin alcohol dietética gasificada de extractos vegetales sabor a café. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "220ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_220_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "220ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },

    // {
    //   src: image_cuadrada_combo_simple_cp_gaseosas_lata_220_sp,
    //   id: "image_cuadrada_combo_simple_cp_gaseosas_lata_220_sp",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_220_sp_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_220_sp_desc_negro,
    //   marca: "sprite",
    //   drinkLine: 'sprite',
    //   litraje: "220ml",
    //   description: "Lima Limon",
    //   legal:
    //     "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    // },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_220_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "220ml",
      description: "Sin Azúcares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_237_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "237ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa,
      id: "image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "237ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp,
      id: "plantilla_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "237ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn,
      id: "image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseaosas_pet_237_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "237ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso,
      id: "plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_blanco,
      text_negro: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "350ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "350ml",
      description: "Sabor Liviano",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en colorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "350ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp,
      id: "plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_blanco,
      text_negro: plantilla_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "350ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "350ml",
      description: "Sin Azúcares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "350ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "350ml",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica,
      id: "image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_vidrio_350_sch_tonica_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "350ml",
      description: "Sabor Tonica",
      legal: "Schweppes es marca registrada de Atlantic Industries. Schweppes agua tónica: agua tónica. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "354ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "354ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "354ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "354ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo,
      id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_pomelo_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "354ml",
      description: "Sabor Pomelo",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },

    // {
    //   src: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_tonica,
    //   id: "image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_tonica",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_tonica_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cp_gaseosas_lata_354_schw_tonica_desc_negro,
    //   marca: "schweppes",
    //   drinkLine: 'schweppes',
    //   litraje: "354ml",
    //   description: "Sabor Tonica",
    //   legal:
    //     "Schweppes es marca registrada de Atlantic Industries. Schweppes agua tónica: agua tónica. Imágenes de fondo y comida: freepik.com.",
    // },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "500ml",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "500ml",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    //TODO se cambia image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_fsp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "500ml",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fnsa_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sin Azucares",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja sin azúcar: Bebida sin alcohol dietética artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseosas_pet_500_fl_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "500ml",
      description: "Sabor Limón",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta limón: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso,
      id: "image_cuadrada_combo_simple_cp_gaseosas_pet_500_ccsa",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_gaseaosas_pet_500_ccsa_y_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "500ml",
      description: "Sin Azucares u Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales.Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_15_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "1,75L",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "1,75L",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "1,75L",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "1,75L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_spsa_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "1,75L",
      description: "Sin Azúcares",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite sabor sin azúcares: Bebida sin alcohol dietética gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_175_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "1,75L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "1,5L",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_15_schw_tonica_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "1,5L",
      description: "Sabor Tonica",
      legal: "Schweppes es marca registrada de Atlantic Industries. Schweppes agua tónica: agua tónica. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "2,25L",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "2,25L",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_ccl_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccl",
      litraje: "2,25L",
      description: "Sabor Liviano",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola light sabor liviano: Bebida sin alcohol dietética gasificada de extractos vegetales. 100% reducida en calorías comparado con Coca-Cola sabor original. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "2,25L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    // {
    //   src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_spsa,
    //   id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_spsa",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_spsa_desc_negro,
    //   marca: "sprite",
    //   drinkLine: 'sprite',
    //   litraje: "2_25L",
    //   description: "Sin Azúcares",
    //   legal:
    //     "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    // },

    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_fl_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,25L",
      description: "Sabor Limón",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta limón: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa,
      id: "image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseosas_pet_2_25_schw_pomelo_sa_desc_negro,
      marca: "schweppes",
      drinkLine: "schweppes",
      litraje: "2,25L",
      description: "Sabor Pomelo sin Azucar",
      legal:
        "Schweppes es marca registrada de Atlantic Industries. Schweppes pomelo: Bebida sin alcohol gasificada con 10% de jugo de pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso,
      id: "image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "2,5L",
      description: "Sabor Original",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa,
      id: "image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccsa",
      litraje: "2,5L",
      description: "Sin Azucares",
      legal:
        "Coca- Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp,
      id: "image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_sp_desc_negro,
      marca: "sprite",
      drinkLine: "sprite",
      litraje: "2,5L",
      description: "Lima Limon",
      legal:
        "Sprite es marca registrada de The Coca-Cola Company. Sprite: Bebida sin alcohol gasificada con 5% de jugo de limón. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn,
      id: "image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_fn_desc_negro,
      marca: "fanta",
      drinkLine: "fanta",
      litraje: "2,5L",
      description: "Sabor Naranja",
      legal:
        "Fanta es marca registrada de The Coca-Cola Company. Fanta naranja: Bebida sin alcohol artificial con sabor a naranja gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso,
      id: "image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso",
      ref: "cuadrado",
      combo: "duo",
      text_blanco: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_gaseaosas_pet_2_5_ccsa_y_ccso_desc_negro,
      marca: "coca-cola",
      drinkLine: "ccso",
      litraje: "2,5L",
      description: "Sabor sin Azucar u Original",
      legal:
        "Coca-Cola es marca registrada de The Coca-Cola Company.Coca-Cola sabor sin azúcares: Bebida sin alcohol dietética gasificada de extractos vegetales. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.",
    },

    // aguas
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_kin,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_kin",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: Agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_ksg,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_ksg",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_ksg_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "500ml",
      description: "Agua de mesa con gas",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: Agua de mesa envasada con gas. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_aguas_pet_591_sw,
      id: "image_cuadrada_combo_simple_cp_aguas_pet_591_sw",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_aguas_pet_591_sw_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "591cc",
      description: "Agua mineraliza",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente no gasificada. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_aq_manzana_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Aquarius Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Aquarius Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "500ml",
      description: "Aquarius Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_nar_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "500ml",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_cepita_fresh_pom_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "500ml",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_kin,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_kin",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_kin_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "1,5L",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_sw,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_sw",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_sw_desc_negro,
      marca: "smartwater",
      drinkLine: "smartwater",
      litraje: "1,5L",
      description: "Agua sin gas",
      legal:
        "Geau Smart Water es una marca registrada de energy brands inc. Agua mineralizada artificialmente no gasificada. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_aq_manzana_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_aq_pomelo_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "1,5L",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_naranja_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_man_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Manzana",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Manzana: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo,
      id: "image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_15_cepita_fresh_pomelo_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "1,5L",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_225_kin,
      id: "image_cuadrada_combo_simple_cf_stills_pet_225_kin",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_225_kin_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_225_kin_desc_negro,
      marca: "kin",
      drinkLine: "kin",
      litraje: "2,25L",
      description: "Agua de mesa",
      legal: "Kin es marca registrada de The Coca-Cola Company. Kin: agua de mesa envasada. Imagen de fondo e imagen de comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_man_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Manzana",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Manzana: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_nar_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25_cepita_fresh_pom_desc_negro,
      marca: "cepita-fresh",
      drinkLine: "cepita-fresh",
      litraje: "2,25L",
      description: "Sabor Pomelo",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Pomelo: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_man_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Manzana",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius manzana: Alimento líquido con 10% de jugo de manzana con vitaminas B3 y B6 sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pera_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pera",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pera: Alimento líquido con 10% de jugo de pera con vitaminas B3 y B6 sabor pera. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo: Alimento líquido con 10% de jugo de pomelo con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    },

    /*     {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar",
      ref: "cuadrado",
      combo: "simple",
      text_blanco:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_blanco,
      text_negro:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_nar_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Naranja",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius naranja: Alimento líquido con 10% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    }, */

    /*   {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado",
      ref: "cuadrado",
      combo: "simple",
      text_blanco:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_blanco,
      text_negro:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_rosado_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo rosado",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo rosado: Alimento líquido con 10% de jugo de pomelo rosado con vitaminas B3 y B6 sabor pomelo rosado. Imágenes de fondo y comida: freepik.com.",
    }, */
    /*   {
      src: image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa,
      id: "image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_blanco,
      text_negro:
        image_cuadrada_combo_simple_cf_stills_pet_2_25L_aq_pom_sa_desc_negro,
      marca: "aquarius",
      drinkLine: "aquarius",
      litraje: "2,25L",
      description: "Sabor Pomelo sin azúcar",
      legal:
        "Aquarius es marca registrada de The Coca-Cola Company. Aquarius pomelo sin azúcar: Alimento líquido con 10% de jugo de pomelo, sin azúcar con vitaminas B3 y B6 sabor pomelo. Imágenes de fondo y comida: freepik.com.",
    }, */

    // resto
    {
      src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man,
      id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_man_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Manzana",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Manzana: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi,
      id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multi_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Multi-fruta",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Multi-fruta: Alimento líquido con 1% de jugo de pomelo con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar,
      id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_nar_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "200ml",
      description: "Sabor Naranja",
      legal:
        "Cepita Fresh es marca registrada de The Coca-Cola Company. Cepita Fresh Naranja: Alimento líquido con 1% de jugo de naranja con vitaminas B3 y B6 sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    // {
    //   src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_manzana,
    //   id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_manzana",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_manzana_desc_negro,
    //   marca: "cepita",
    //   drinkLine: 'cepita',
    //   litraje: "200ml",
    //   description: "Sabor Manzana",
    //   legal:
    //     "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita manzana: Alimento líquido con 50% de jugo de manzana con vitaminas B3 y B6, sabor manzana. Imágenes de fondo y comida: freepik.com.",
    // },
    // {
    //   src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multifruta,
    //   id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multifruta",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_multifruta_desc_negro,
    //   marca: "cepita",
    //   drinkLine: 'cepita',
    //   litraje: "200ml",
    //   description: "Sabor Multifruta",
    //   legal:
    //     "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita multifruta: Alimento líquido con 50% de jugo mezcla (manzana - naranja - durazno) con vitaminas B3 y B6, sabor multifruta. Imágenes de fondo y comida: freepik.com.",
    // },
    // {
    //   src: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_naranja,
    //   id: "image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_naranja",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_naranja_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_cepita_naranja_desc_negro,
    //   marca: "cepita",
    //   drinkLine: 'cepita',
    //   litraje: "200ml",
    //   description: "Sabor Naranja",
    //   legal:
    //     "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita naranja: Alimento líquido con 50% de jugo de naranja con vitaminas B3 y B6, sabor naranja. Imágenes de fondo y comida: freepik.com.",
    // },
    {
      src: image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana,
      id: "image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_tetra_200_ades_manzana_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Ades Manzana",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades manzana: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor manzana - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_frutas_tro_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Ades Frutas Tropicales",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades Frutas Tropicales: Alimento de soja líquido dietético con jugo de frutas tropicales, fuente de vitaminas A y D, fortificado con vitaminas E, C, B1, B2, B3, B6, Ácido Fólico, B12 Y Zinc - Libre de Gluten. Sin tacc. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_200_ades_nar_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "200ml",
      description: "Ades Naranja",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades Naranja: Alimento de soja líquido dietético con jugo naranja, fuente de vitaminas A y D, fortificado con vitaminas E, C, B1, B2, B3, B6, Ácido Fólico, B12 Y Zinc - Libre de Gluten. Sin tacc. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_manzana_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Manzana",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades manzana: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor manzana - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    // {
    //   src: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja,
    //   id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja",
    //   ref: "cuadrado",
    //   combo: "simple",
    //   text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco,
    //   text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro,
    //   marca: "cepita",
    //   drinkLine: 'cepita',
    //   litraje: "1L",
    //   description: "Sabor Naranja",
    //   legal:
    //     "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita naranja: Alimento líquido con 50% de jugo de naranja con vitaminas B3 y B6, sabor naranja. Imágenes de fondo y comida: freepik.com.",
    // },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_multifruta_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Multifruta",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita multifruta: Alimento líquido con 50% de jugo mezcla (manzana - naranja - durazno) con vitaminas B3 y B6, sabor multifruta. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_cepita_naranja_desc_negro,
      marca: "cepita",
      drinkLine: "cepita",
      litraje: "1L",
      description: "Sabor Naranja",
      legal:
        "Cepita Del Valle es marca registrada de The Coca-Cola Company. Cepita naranja: Alimento líquido con 50% de jugo de naranja con vitaminas B3 y B6, sabor naranja. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_almendras_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Almendras",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades almendras: Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, D, E, B6, B12, ácido fólico y calcio - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_frutas_tropicales_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Frutas tropicales",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades frutas tropicales: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor frutas tropicales - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },

    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_manzana_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Manzana",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades manzana: Alimento de soja líquido dietético, fortificado con vitaminas C, E, B1, B2, B3, B6, B9, B12 y zinc con jugo de fruta sabor manzana - Libre de gluten sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1l_ades_natural_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Natural",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Ades natural: Alimento de soja líquido fortificado con vitaminas A, C, D, E, B2, B6, B12, ácido fólico y calcio - libre de gluten. Sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    ///
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_alm_sa_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sin Azúcar",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, D, E, B6, B12, ácido fólico y calcio - Libre de gluten sin TACC.",
    },
    {
      src: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla,
      id: "image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cf_stills_tetra_1L_ades_vainilla_desc_negro,
      marca: "ades",
      drinkLine: "ades",
      litraje: "1L",
      description: "Sabor Vainilla",
      legal:
        "Ades es marca registrada de The Coca-Cola Company. Alimento líquido a base de pasta de almendras, fortificado con vitaminas A, C, D, E, B2, B6, B12, ácido fólico y calcio - libre de gluten. Sin TACC. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_frutas_trop_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Frutas Tropicales",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade Frutas tropicales: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor mora. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_manzana_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Manzana",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade manzana: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor manzana. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b,
      id: "image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_stills_pet_500_pwd_mountain_b_desc_negro,
      marca: "powerade",
      drinkLine: "powerade",
      litraje: "500ml",
      description: "Sabor Mountain Blast",
      legal:
        "Powerade es marca registrada de The Coca-Cola Company. Powerade Mountain blast: Alimento liquido con 2,5 % de jugo de limon con 45 mg% de sodio, con 12 mg% de potasio, 2 mg% de calcio, con 1 mg % de magnesio, con vitaminas B3, B6, sabor mora. Imágenes de fondo y comida: freepik.com.",
    },
    {
      src: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener,
      id: "image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_ener_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Energy",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango,
      id: "image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_mango_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Mango Loco",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white,
      id: "image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_white_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Ultra",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise,
      id: "image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_paradise_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Paradise",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
    {
      src: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise,
      id: "image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise",
      ref: "cuadrado",
      combo: "simple",
      text_blanco: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_blanco,
      text_negro: image_cuadrada_combo_simple_cp_isotonicas_lata_473_monster_sunrise_desc_negro,
      marca: "monster",
      drinkLine: "monster",
      litraje: "473ml",
      description: "Sunrise",
      legal:
        "Para más información consultar www.monsterenergy.com. Bebida analcohólica dietética con cafeína y taurina gasificada. Monster Energy es una marca registrada de Monster Energy Company.",
    },
  ],
});

export default handleActions(
  {
    [combo.setEtapa]: (state, action) => ({ ...state, etapa: action.payload }),
    [combo.setSelUserLogo]: (state, action) => ({
      ...state,
      setSelUserLogo: action.payload,
    }),

    [combo.setCheckLogoCombo]: (state, action) => ({
      ...state,
      setCheckLogoCombo: action.payload,
    }),
    [combo.setSelUserDescripcion]: (state, action) => ({
      ...state,
      setSelUserDescripcion: action.payload,
    }),
    [combo.setSelUserBebida]: (state, action) => ({
      ...state,
      setSelUserBebida: action.payload,
    }),
    [combo.setSelUserComida]: (state, action) => ({
      ...state,
      setSelUserComida: action.payload,
    }),
    [combo.setSelUserContacto]: (state, action) => ({
      ...state,
      setSelUserContacto: action.payload,
    }),
    [combo.setPlantilla]: (state, action) => ({
      ...state,
      plantilla: action.payload,
      setCheckLogoCombo: false,
    }),
    [combo.setLogo]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            logo: action.payload,
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, logo: undefined };
    },
    [combo.setDescripcion]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            descripcion: {
              ...state.plantilla.combo.descripcion,
              descp_combo: action.payload.descp_combo,
              precio: action.payload.precio,
              titulo_combo: action.payload.titulo_combo,
              titulo_comida: action.payload.titulo_comida,
            },
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, descripcion: undefined };
    },
    [combo.setBebida]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            bebida: action.payload.src,
            drinkBrand: action.payload.marca,
            drinkLine: action.payload.drinkLine,
            drinkLitrage: action.payload.litraje,
            bebida_image_text: action.payload.colorText === "negro" ? action.payload.text_negro : action.payload.text_blanco,
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, bebida: undefined };
    },
    [combo.setLegal]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            legal: action.payload,
          },
        };
        return { ...state, plantilla: newPlantilla };
      }
    },
    [combo.setPresetFoodPhoto]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            presetFoodPhoto: action.payload,
          },
        };
        return { ...state, plantilla: newPlantilla };
      }
    },
    [combo.setComida]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            comida: action.payload.src,
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, comida: undefined };
    },
    [combo.setComidaUser]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            comidaUser: action.payload.src,
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, comida: undefined };
    },
    [combo.setContacto]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            contacto: action.payload,
          },
        };
        return { ...state, plantilla: newPlantilla };
      } else return { ...state, contacto: undefined };
    },

    [combo.setContactosSel]: (state, action) => {
      if (state.plantilla) {
        const newPlantilla = {
          ...state.plantilla,
          combo: {
            ...state.plantilla.combo,
            contactosSel: action.payload,
          },
        };
        return { ...state, plantilla: newPlantilla };
      }
    },
    [combo.setImagenFinal]: (state, action) => ({
      ...state,
      imagenFinal: action.payload,
    }),
    [combo.resetTemplate]: (state, action) => ({
      ...state,
      etapa: "PLANTILLA",
      imagenFinal: undefined,
      plantilla: undefined,
      setSelUserLogo: false,
      setSelUserComida: false,
      setSelUserContacto: false,
      setSelUserBebida: false,
      setSelUserDescripcion: false,
      setCheckLogoCombo: false,
    }),
  },

  initializeReducer()
);
