import React, { useState,  useLayoutEffect} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

//* stages de comboPrincipal
import{ Plantilla,
 Logo,
 Description,
 Comida,
 Bebida,
 Contacto,
} from '../views'

export default function ComboLayout() {
  const etapaCombo = useSelector((state) => state.combo.etapa);

  const [landscape, setLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useLayoutEffect(() => {
    const updateSize = () =>
      setLandscape(window.innerWidth > window.innerHeight);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);


  const renderLayout = () => {
    switch (etapaCombo) {
      case "PLANTILLA":
        return <Plantilla landscape={landscape}  />;
      case "LOGO":
        return <Logo landscape={landscape}  />;
      case "DESCRIPCION":
        return <Description landscape={landscape}  />;
      case "COMIDA":
        return <Comida landscape={landscape}  />;
      case "BEBIDA":
        return <Bebida landscape={landscape}  />;
      case "CONTACTO":
        return <Contacto landscape={landscape}  />;
      default:
        break;
    }
  };

  return <Container>{renderLayout()}</Container>;
}

const Container = styled.div`
    display: flex;
    // padding: 50px;
`;
