import { handleActions } from "redux-actions";
import { createMenu } from "./actions";

// Full templates
import crear_menu_plantilla_galeria_blanca from "../../../assets/menu/templates/full/11_crear_menu_plantilla_galeria_blanca.png";
import crear_menu_plantilla_galeria_foto from "../../../assets/menu/templates/full/11_crear_menu_plantilla_galeria_foto.png";
import crear_menu_plantilla_galeria_negra from "../../../assets/menu/templates/full/11_crear_menu_plantilla_galeria_negra.png";
import crear_menu_plantilla_galeria_roja from "../../../assets/menu/templates/full/11_crear_menu_plantilla_galeria_roja.png";

import crear_menu_plantilla_complejo_galeria_blanca from "../../../assets/menu/templates/full/14_crear_menu_plantilla_complejo_galeria_blanca.jpg";
import crear_menu_plantilla_complejo_galeria_foto from "../../../assets/menu/templates/full/14_crear_menu_plantilla_complejo_galeria_foto.jpg";
import crear_menu_plantilla_complejo_galeria_negra from "../../../assets/menu/templates/full/14_crear_menu_plantilla_complejo_galeria_negra.jpg";
import crear_menu_plantilla_complejo_galeria_rojo from "../../../assets/menu/templates/full/14_crear_menu_plantilla_complejo_galeria_rojo.jpg";

// Empty templates
import plantilla_menu_fondo_blanco from "../../../assets/menu/templates/empty/11_plantilla_menu_fondo_blanco.png";
import plantilla_menu_fondo_foto from "../../../assets/menu/templates/empty/11_plantilla_menu_fondo_foto.png";
import plantilla_menu_fondo_negro from "../../../assets/menu/templates/empty/11_plantilla_menu_fondo_negro.png";
import plantilla_menu_fondo_rojo from "../../../assets/menu/templates/empty/11_plantilla_menu_fondo_rojo.png";

// Stripes
import plantilla_menu_franja_redes_plantilla_blanca from "../../../assets/menu/stripes/11_plantilla_menu_franja_redes_plantilla_blanca.png";
import plantilla_menu_franja_redes_plantilla_negra from "../../../assets/menu/stripes/11_plantilla_menu_franja_redes_plantilla_negra.png";
import plantilla_menu_franja_redes_plantilla_roja from "../../../assets/menu/stripes/11_plantilla_menu_franja_redes_plantilla_roja.png";

// Logo
import plantilla_menu_logo_cc_blanco from "../../../assets/menu/logo/11_plantilla_menu_logo_cc_blanco.png";
import plantilla_menu_logo_cc_rojo from "../../../assets/menu/logo/11_plantilla_menu_logo_cc_rojo.png";

// Legal
import plantilla_menu_legal_blanco from "../../../assets/menu/legal/11_plantilla_menu_legal_blanco.png";
import plantilla_menu_legal_negro from "../../../assets/menu/legal/11_plantilla_menu_legal_negro.png";

// Food Galery
import plantilla_menu_comida_2 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_2.png";
import plantilla_menu_comida_3 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_3.png";
import plantilla_menu_comida_4 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_4.png";
import plantilla_menu_comida_5 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_5.png";
import plantilla_menu_comida_6 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_6.png";
import plantilla_menu_comida_7 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_7.png";
import plantilla_menu_comida_9 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_9.png";
import plantilla_menu_comida_10 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_10.png";
import plantilla_menu_comida_11 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_11.png";
import plantilla_menu_comida_12 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_12.png";
import plantilla_menu_comida_13 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_13.png";
import plantilla_menu_comida_14 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_14.png";
import plantilla_menu_comida_15 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_15.png";
import plantilla_menu_comida_16 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_16.png";
import plantilla_menu_comida_17 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_17.png";
import plantilla_menu_comida_18 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_18.png";
import plantilla_menu_comida_19 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_19.png";
import plantilla_menu_comida_20 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_20.png";
import plantilla_menu_comida_21 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_21.png";
import plantilla_menu_comida_22 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_22.png";

import plantilla_menu_comida_23 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_23.png";
import plantilla_menu_comida_24 from "../../../assets/menu/foodGalery/11_plantilla_menu_comida_24.png";
////navidad
import crear_menu_complejo_plantilla_motivo_1 from "../../../assets/image/navidad/26_galeria_plantilla_navidad_menu_motivo_1 copy.png";
import crear_menu_complejo_plantilla_motivo_2 from "../../../assets/image/navidad/26_galeria_plantilla_navidad_menu_motivo_2 copy.png";
import crear_menu_plantilla_motivo_1 from "../../../assets/image/navidad/26_galeria_plantilla_navidad_menu_motivo_1.png";
import fondo_plantilla_motivo_1 from "../../../assets/image/navidad/26_plantilla_navidad_menu_4_rec_fondo_motivo_2.png";
import crear_menu_plantilla_motivo_2 from "../../../assets/image/navidad/26_galeria_plantilla_navidad_menu_motivo_2.png";
import fondo_plantilla_motivo_2 from "../../../assets/image/navidad/26_plantilla_navidad_menu_4_rec_fondo_motivo_1.png";
import cinta_motivo_1 from "../../../assets/image/navidad/26_plantilla_navidad_menu_4_y_8_rec_cinta_motivo_1.png";

const initializeReducer = () => ({
  //* etapas del menu
  stages: ["template", "logo", "description", "picture", "contact"],
  stage: "template",

  //* stages del menu
  template: undefined,
  logo: undefined,
  description: undefined,
  food: undefined,
  contact: undefined,
  imagenFinal: undefined,

  setSelUserBebida: false,
  setSelUserDescripcion: false,
  setSelUserComida: false,
  setLogo: false,
  setFood: false,
  setDescription: false,
  setSelUserContact: false,
  setSelUserLogo: false,
  setSetCheckLogo: false,

  foods: [
    {
      src: plantilla_menu_comida_2,
      id: "plantilla_menu_comida_2",
    },
    {
      src: plantilla_menu_comida_3,
      id: "plantilla_menu_comida_3",
    },
    {
      src: plantilla_menu_comida_4,
      id: "plantilla_menu_comida_4",
    },
    {
      src: plantilla_menu_comida_5,
      id: "plantilla_menu_comida_5",
    },
    {
      src: plantilla_menu_comida_6,
      id: "plantilla_menu_comida_6",
    },
    {
      src: plantilla_menu_comida_7,
      id: "plantilla_menu_comida_7",
    },
    {
      src: plantilla_menu_comida_9,
      id: "plantilla_menu_comida_9",
    },
    {
      src: plantilla_menu_comida_10,
      id: "plantilla_menu_comida_10",
    },
    {
      src: plantilla_menu_comida_11,
      id: "plantilla_menu_comida_11",
    },
    {
      src: plantilla_menu_comida_12,
      id: "plantilla_menu_comida_12",
    },
    {
      src: plantilla_menu_comida_13,
      id: "plantilla_menu_comida_13",
    },
    {
      src: plantilla_menu_comida_14,
      id: "plantilla_menu_comida_14",
    },
    {
      src: plantilla_menu_comida_15,
      id: "plantilla_menu_comida_15",
    },
    {
      src: plantilla_menu_comida_16,
      id: "plantilla_menu_comida_16",
    },
    {
      src: plantilla_menu_comida_17,
      id: "plantilla_menu_comida_17",
    },
    {
      src: plantilla_menu_comida_18,
      id: "plantilla_menu_comida_18",
    },
    {
      src: plantilla_menu_comida_19,
      id: "plantilla_menu_comida_19",
    },
    {
      src: plantilla_menu_comida_20,
      id: "plantilla_menu_comida_20",
    },
    {
      src: plantilla_menu_comida_21,
      id: "plantilla_menu_comida_21",
    },
    {
      src: plantilla_menu_comida_22,
      id: "plantilla_menu_comida_22",
    },
    {
      src: plantilla_menu_comida_23,
      id: "plantilla_menu_comida_23",
    },
    {
      src: plantilla_menu_comida_24,
      id: "plantilla_menu_comida_24",
    },
  ],

  //* templates prearmados
  templates: [
    ///
    /*  {
      src: crear_menu_plantilla_motivo_1,
      id: "crear_menu_plantilla_motivo_1 ",
      ref: "simple",
      menu: {
        motivo: "navidad",
        srcPlantilla: fondo_plantilla_motivo_1,
        stripe: "",
        // brandLogo: plantilla_menu_logo_cc_rojo,
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_motivo_2,
      id: "crear_menu_plantilla_motivo_2 ",
      ref: "simple",
      menu: {
        motivo: "navidad",
        cinta: cinta_motivo_1,
        srcPlantilla: fondo_plantilla_motivo_2,
        stripe: "",
        brandLogo: "",
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "black",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    ////compleja
    {
      src: crear_menu_complejo_plantilla_motivo_1,
      id: "crear_menu_complejo_plantilla_motivo_1",
      ref: "complex",
      menu: {
        motivo: "navidad",
        srcPlantilla: fondo_plantilla_motivo_1,
        stripe: "",
        brandLogo: "",
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_complejo_plantilla_motivo_2,
      id: "crear_menu_complejo_plantilla_motivo_2",
      ref: "complex",
      menu: {
        motivo: "navidad",
        cinta: cinta_motivo_1,
        srcPlantilla: fondo_plantilla_motivo_2,
        stripe: "",
        brandLogo: "",
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "black",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    }, */
    ////////////
    {
      src: crear_menu_plantilla_galeria_blanca,
      id: "crear_menu_plantilla_galeria_blanca",
      ref: "simple",
      menu: {
        srcPlantilla: plantilla_menu_fondo_blanco,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_rojo,
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_galeria_foto,
      id: "crear_menu_plantilla_galeria_foto",
      ref: "simple",
      menu: {
        srcPlantilla: plantilla_menu_fondo_foto,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_rojo,
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_galeria_negra,
      id: "crear_menu_plantilla_galeria_negra",
      ref: "simple",
      menu: {
        srcPlantilla: plantilla_menu_fondo_negro,
        stripe: plantilla_menu_franja_redes_plantilla_negra,
        brandLogo: plantilla_menu_logo_cc_blanco,
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_galeria_roja,
      id: "crear_menu_plantilla_galeria_roja",
      ref: "simple",
      menu: {
        srcPlantilla: plantilla_menu_fondo_rojo,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_blanco,
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "black",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_complejo_galeria_blanca,
      id: "crear_menu_plantilla_complejo_galeria_blanca",
      ref: "complex",
      menu: {
        srcPlantilla: plantilla_menu_fondo_blanco,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_rojo,
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_complejo_galeria_foto,
      id: "crear_menu_plantilla_complejo_galeria_foto",
      ref: "complex",
      menu: {
        srcPlantilla: plantilla_menu_fondo_foto,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_rojo,
        legal: plantilla_menu_legal_negro,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "black",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_complejo_galeria_negra,
      id: "crear_menu_plantilla_complejo_galeria_negra",
      ref: "complex",
      menu: {
        srcPlantilla: plantilla_menu_fondo_negro,
        stripe: plantilla_menu_franja_redes_plantilla_negra,
        brandLogo: plantilla_menu_logo_cc_blanco,
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "red",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
    {
      src: crear_menu_plantilla_complejo_galeria_rojo,
      id: "crear_menu_plantilla_complejo_galeria_rojo",
      ref: "complex",
      menu: {
        srcPlantilla: plantilla_menu_fondo_rojo,
        stripe: plantilla_menu_franja_redes_plantilla_roja,
        brandLogo: plantilla_menu_logo_cc_blanco,
        legal: plantilla_menu_legal_blanco,
        logo: undefined,
        title: "NUESTROS COMBOS",
        titleColor: "black",
        textColor: "white",
        descriptionColor: "white",
        sections: {
          section_1: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
          section_2: {
            title: "PLATOS O COMBOS",
            combos: [
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
              {
                name: "Nombre del plato",
                description: "Ingrese descripción",
                price: "000",
              },
            ],
          },
        },
        comida: plantilla_menu_comida_23,
        presetFoodPhoto: true,
        contact: {
          whatsapp: "+5491162000551",
          facebook: "@cuentaFacebook",
          instagram: "",
        },
        contactosSel: ["whatsapp", "facebook"],
      },
    },
  ],
});

export default handleActions(
  {
    [createMenu.setEtapa]: (state, action) => ({
      ...state,
      stage: action.payload,
    }),
    [createMenu.setLogo]: (state, action) => ({
      ...state,
      setLogo: action.payload,
    }),
    [createMenu.setSetCheckLogo]: (state, action) => ({
      ...state,
      setSetCheckLogo: action.payload,
    }),
    [createMenu.setSelUserContact]: (state, action) => ({
      ...state,
      setSelUserContact: action.payload,
    }),
    [createMenu.setSelUserComida]: (state, action) => ({
      ...state,
      setSelUserComida: action.payload,
    }),
    [createMenu.setDescription]: (state, action) => ({
      ...state,
      setDescription: action.payload,
    }),
    [createMenu.setFood]: (state, action) => ({
      ...state,
      setFood: action.payload,
    }),
    [createMenu.setContact]: (state, action) => ({
      ...state,
      setContact: action.payload,
    }),
    [createMenu.setTemplate]: (state, action) => ({
      ...state,
      template: action.payload,
      setSetCheckLogo: false,
    }),
    [createMenu.setSelUserLogo]: (state, action) => ({
      ...state,
      setSelUserLogo: action.payload,
    }),
    [createMenu.setSelUserDescripcion]: (state, action) => ({
      ...state,
      setSelUserDescripcion: action.payload,
    }),
    [createMenu.setTitle]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.menu.title = action.payload;
      return { ...state, title: newPlantilla };
    },
    [createMenu.setLogo]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.menu.logo = action.payload;
      return { ...state, template: newPlantilla };
    },
    [createMenu.setDescription]: (state, action) => {
      console.log(action.payload, "acacaca");
      const newPlantilla = { ...state.template };
      newPlantilla.menu.sections = action.payload;
      return { ...state, template: newPlantilla };
    },
    [createMenu.setPresetFoodPhoto]: (state, action) => {
      if (state.template) {
        const newPlantilla = {
          ...state.template,
          menu: {
            ...state.template.menu,
            presetFoodPhoto: action.payload,
          },
        };
        return { ...state, template: newPlantilla };
      }
    },
    [createMenu.setFood]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.menu.food = action.payload.src;
      return { ...state, template: newPlantilla };
    },
    [createMenu.setContact]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.menu.contact = action.payload;
      return { ...state, template: newPlantilla };
    },
    [createMenu.setContactSel]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.menu.contactosSel = action.payload;
      return { ...state, template: newPlantilla };
    },
    [createMenu.setImagenFinal]: (state, action) => ({
      ...state,
      imagenFinal: action.payload,
    }),
    [createMenu.resetTemplate]: (state, action) => ({
      ...state,
      stage: "template",
      template: undefined,
      imagenFinal: undefined,
      setSelUserBebida: false,
      setSelUserComida: false,
      setSelUserContact: false,
      setSelUserDescripcion: false,
      setSelUserLogo: false,
      setSetCheckLogo: false,
    }),
  },

  initializeReducer()
);
