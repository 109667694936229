import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { combo } from "../../utils/actions";

//* components
import AlertComponent from "../../../../components/common/alert";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import Tips from "../../../../components/Tips";
import Checkbox from "../../../../components/Checkbox";

//* styles
import {
  Container,
  FormLogin,
  CustomLabel,
  CustomLabelLeft,
  UploadSection,
  ImagePreviewSection,
  ContainerImage,
  ImagePreview,
  TittleContainer,
} from "./styles";

export const Logo = ({ landscape }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const exist = useSelector((state) => state.combo.setSelUserLogo);
  //const plantillaCombo = useSelector((state) => state);

  const [selectedFile, setSelectedFile] = useState(null);

  const checked = useSelector((state) => state.combo.setCheckLogoCombo);

  const handleCheck = () => {
    dispatch(combo.setCheckLogoCombo(!checked));

    if (checked) {
      dispatch(combo.setSelUserLogo(false));
    } else {
      dispatch(combo.setSelUserLogo(true));
      dispatch(combo.setLogo({}));
      setSelectedFile(null);
    }
  };

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const onSubmit = (values) => {
    if (checked || Object.keys(values.logo).length > 0) {
      dispatch(combo.setEtapa("DESCRIPCION"));
      dispatch(combo.setSelUserLogo(true));
      setSelectedFile(null);
    } else {
      setAlert(true);
    }
  };

  const onChangeLogo = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        dispatch(combo.setLogo(reader.result));
        dispatch(combo.setSelUserLogo(true));
        //setChecked(false);
        dispatch(combo.setCheckLogoCombo(false));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Puede seleccionar un logo o continuar sin logo" />
      <UploadSection>
        <TittleContainer>
          <Title text="Cargá tu logo" />
        </TittleContainer>

        <Subtitle text="· Sólo archivos .jpg y .png" />
        <Subtitle text="· Peso máximo 350kb." />
      </UploadSection>

      <FormLogin onSubmit={handleSubmit(onSubmit)}>
        <div style={{ disply: "flex", height: "30px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "30px",
            }}
          >
            {landscape && <CustomLabelLeft>archivo.jpg</CustomLabelLeft>}

            <CustomLabel htmlFor="logo">EXAMINAR...</CustomLabel>
          </div>

          <input
            id="logo"
            name="logo"
            ref={register}
            type="file"
            onChange={onChangeLogo}
            style={{ display: "none" }}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </div>

        <label style={{ lineHeight: 0 }}>
          <Checkbox checked={checked} onChange={handleCheck} inputWidth="13px" inputHeight="13px" />
          <span
            style={{
              lineHeight: "16px",
              verticalAlign: "middle",
              marginLeft: 8,
              fontFamily: "GilroyMedium",
              fontSize: 16,
            }}
          >
            continuar sin logo
          </span>
        </label>

        {!landscape && (
          <ImagePreviewSection>
            {selectedFile && (
              <div style={{ marginTop: 50 }}>
                <ContainerImage>
                  <ImagePreview src={URL.createObjectURL(selectedFile)} alt="previewImage" />
                </ContainerImage>
              </div>
            )}
          </ImagePreviewSection>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <GlobalButton content="SIGUIENTE" disabled={!exist} />
        </div>
      </FormLogin>

      {landscape && <Tips texts={["Verificá que las fotos no estén en baja resolución (pixeladas o fuera de foco)."]} />}
    </Container>
  );
};
