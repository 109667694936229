import React, { useState, useEffect } from "react";
import axios from "axios";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";
import GlobalStyles from "./global-styles";
import Routes from "./routes/routesIndex";
import config from "./config";
import { ThemeProvider } from "styled-components";

function App() {
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const colors = useSelector((state) => state.colors.palette);

  useEffect(() => {
    // Configuro la url base de axios antes de cargar la web
    axios.defaults.baseURL = `${config.API_URL}`;
    axios.defaults.withCredentials = false;
    setIsLoadingApp(false);
  }, []);

  if (isLoadingApp) return null;

  return (
    <ThemeProvider theme={{ colors }}>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Routes />
      </Router>
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}
