import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  margin-left: 12px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  border-radius: 50px;
  padding: 6px;
  padding-left: 12px;
  font-family: GilroyMedium;
  font-weight: 25;
  width: 60%;
  border: 1.3px solid ${({ theme }) => theme.colors.secondary};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.border};
    font-family: GilroyMedium;
    font-size: 13px;
    font-weight: 500;
  }

  :focus::placeholder {
    color: transparent;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.button};
  margin: 0;
  margin-left: 5px;
  font-size: 16px;
  font-family: GilroyMedium;
  font-weight: 25;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-around;
  flex: 1;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 20px;
  overflow: scroll;
  @media (orientation: portrait) {
    height: 79vh;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
  @media (max-width: 1200px) {
    justify-content: center;
  }
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
