import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 50px;
  overflow: hidden;
  @media (orientation: portrait) {
    padding: 20px 20px 0 20px;
    height: 82vh;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex: 7;
  justify-content: space-around;
  flex-flow: wrap;
  overflow: scroll;
`;

export const GridControl = styled.div`
  --id: GridControl;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0% 2%;
`;

export const ErrorText = styled.div`
  font-size: 18px;
  font-family: GilroyMedium;
  mediumor: ${({ theme }) => theme.colors.border};
  width: 250px;
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
