import styled from "styled-components";

export const Container = styled.div`
  --id: ContainerPlantilla;
  display: grid;
  grid-template-rows: 8em 6em 1fr;
`;

export const GridControl = styled.div`
  --id: GridControl;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`;

export const GridImage = styled.div`
  --id: GridImage;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 8px;
  padding-bottom: 50px;
`;

export const ContainerImageGrid = styled.div`
  --id: ContainerImageGrid;
`;

export const ContainerImage = styled.div`
  --id: ContainerImage;
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  margin: 5px;
  background-color: ${({ theme, active }) => (active ? theme.colors.secondary : "none")};
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Image = styled.img`
  --id: Image;
  width: 100%;
  cursor: pointer;
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
