//* librerias
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//* components
import { Sidebar } from "../Sidebar";
import Footer from "../../../../components/Footer";

//* functions and actions
import { getLines, callBack_contactImage, generateQR } from "../../../../utils/functions";
import { comboQR } from "../../utils/actions";
import { addQRMetric } from "../../../../redux/qr/actions";
import { showTermsAndConditions } from "../../../../redux/ui/actions";

//* assets
import imageBackRect from "../../../../assets/image/02_crear_combo/02_crear_combo_base_rectangular_empeza_a_crear.png";
import footer_logo_cc_blanco from "../../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_logo_cc_blanco.png";
import footer_logo_cc_rojo from "../../../../assets/image/03_plantillas_combo_rectangular/03_plantilla_combo_rectangular_logo_cc_rojo.png";
import food1 from "../../../../assets/image/qr/food/12_plantilla_qr_comida_1.png";
import smile_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_smille_final.png";
import final_image from "../../../../assets/image/02_crear_combo/02_crear_combo_frase_final.png";
import fb_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_facebook.png";
import wsp_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_whatsapp.png";
import inst_image from "../../../../assets/image/02_crear_combo/02_crear_combo_icono_final_instagram.png";
import phone from "../../../../assets/image/02_crear_combo/02_crear_combo_base_telefono.png";

//* styles
import {
  Container,
  GridBinding,
  BackImageComboRect,
  ContainerDescarga,
  GridLeft,
  ContainerImages,
  ImageText,
  ImageSmile,
  ImageIcon,
  ContainerIcons,
  GridButtons,
  BtnDescarga,
  Canvas2D,
  ImageWrapper,
  Canvas2DDescarga,
  HomeButton,
  BackButton,
  SubContainerDescarga,
} from "./styles";

import { useTheme } from "styled-components";
export const CreateQR = () => {
  const dispatch = useDispatch();
  const [descImage, setDescImage] = useState(null);

  const finalTemplate = useSelector((state) => state.comboQR.template);
  const final_imagen = useSelector((state) => state.comboQR.imagenFinal);
  const user = useSelector((state) => state.credentials.userLogueado);
  const theme = useTheme();
  const sendMetric = () =>
    dispatch(
      addQRMetric({
        presetFoodPhoto: finalTemplate.combo.presetFoodPhoto,
        token: user.token,
      })
    );

  const [landscape, setLandscape] = useState(window.innerWidth > 700);
  useLayoutEffect(() => {
    const updateSize = () => setLandscape(window.innerWidth > 700);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const mergeTemplate = useCallback(
    (template) => {
      const canvas_global = document.getElementById("canvas");
      const ctx = canvas_global.getContext("2d");
      const imagefinal = new Image();
      imagefinal.crossOrigin = "anonymous";
      imagefinal.src = template.combo.srcPlantilla;

      imagefinal.onload = async function () {
        if (!template.combo.foodImg) {
          //* image food
          const imageFood = new Image();
          imageFood.src = food1;
          imageFood.onload = async function () {
            return await ctx.drawImage(this, 0, 0, 1060, 586);
          };
        } else {
          const imageFood = new Image();
          imageFood.src = template.combo.foodImg;
          imageFood.onload = async function () {
            return await ctx.drawImage(this, 0, 0, 1060, 586);
          };
        }
        //* imageQR
        setTimeout(async () => {
          const qrImage = await generateQR(template.combo.url, template.combo.qrcolor);
          const qrTemplate = new Image();
          qrTemplate.src = qrImage;
          qrTemplate.onload = async function () {
            return await ctx.drawImage(this, 100, 220, 500, 500);
          };
        }, 400);

        //* footer template background
        const imageFooter = new Image();
        imageFooter.src = template.combo.footerImg;
        imageFooter.onload = function () {
          return ctx.drawImage(imageFooter, 0, 1060, 700, 56);
        };

        //* logo
        setTimeout(() => {
          if (template.combo.logo) {
            const imageLogo = new Image();
            imageLogo.src = template.combo.logo;
            imageLogo.onload = function () {
              ctx.drawImage(imageLogo, 260, 20, 160, 160);
            };
          } else {
            if (template.combo.motivo === "navidad") {
              ctx.lineWidth = 5;
              ctx.strokeStyle = "#ffffff";
              ctx.fillStyle = "#3798dcb3";
              ctx.fillRect(260, 20, 160, 160);
              ctx.strokeRect(260, 20, 160, 160);
            } else {
              ctx.fillStyle = theme.colors.logo;
              ctx.fillRect(260, 20, 160, 160);
            }
          }
        }, 400);

        //* footer logo image
        const imageLogoFooter = new Image();
        if (template.combo.logo_cc_color === "") {
          imageLogoFooter.src = "";
        } else {
          imageLogoFooter.src = template.combo.logo_cc_color === "white" ? footer_logo_cc_blanco : footer_logo_cc_rojo;

          imageLogoFooter.onload = function () {
            ctx.drawImage(imageLogoFooter, 470, 970, 230, 254);
          };
        }

        setTimeout(() => {
          //* añade el central text
          if (template.combo.descripcion.title) {
            ctx.fillStyle = template.combo.descripcion.color || "white";
            ctx.font = "48px GilroyBold";
            ctx.textAlign = "center";
            getLines(ctx, template.combo.descripcion.title, 520).forEach((text, i) => ctx.fillText(text, 350, 64 * i + 820));
          }

          //* legales
          let minYValLegal = 1150;
          function writeTextLegal(text, options) {
            ctx.fillStyle = options.color || theme.colors.primary;
            ctx.font = `${options.size || 11}px ${options.font || "GilroyMedium"}`;
            ctx.textAlign = "left";

            getLines(ctx, text, 450).forEach((text) => {
              ctx.fillText(text, 30, minYValLegal + options.size + 2);
              minYValLegal += options.size + 2;
            });
          }

          if (template.combo.legal) {
            ctx.fillStyle = theme.colors.primary;
            ctx.font = "12px GilroyMedium";

            writeTextLegal(template.combo.legal, {
              color: template.combo.legal_color,
              font: "GilroyMedium",
              size: 11,
            });
          }
        }, 0);

        //contacto
        setTimeout(() => {
          if (template.combo.contacto) {
            if (template.combo.contactosSel.length === 1) {
              const ctxTitle = canvas_global.getContext("2d");
              ctxTitle.fillStyle = "white";
              ctxTitle.fontWeight = 120;
              ctxTitle.font = "23px 'GilroyBold'";
              const imageContact1 = new Image();
              imageContact1.src = callBack_contactImage(template.combo.contactosSel[0]);
              imageContact1.onload = function () {
                if (template.combo.motivo === "navidad") {
                  ctx.drawImage(imageContact1, 20, 1080, 30, 30);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[0]], 70, 1101, 180, 45);
                } else {
                  ctx.drawImage(imageContact1, 20, 1074, 30, 30);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[0]], 70, 1095, 180, 45);
                }
              };
            } else {
              const ctxTitle = canvas_global.getContext("2d");
              ctxTitle.fillStyle = "white";
              ctxTitle.fontWeight = 120;
              ctxTitle.font = "23px 'GilroyBold'";

              const imageContact1 = new Image();
              imageContact1.src = callBack_contactImage(template.combo.contactosSel[0]);
              imageContact1.onload = function () {
                if (template.combo.motivo === "navidad") {
                  ctx.drawImage(imageContact1, 20, 1080, 28, 28);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[0]], 53, 1101, 180, 50);
                } else {
                  ctx.drawImage(imageContact1, 20, 1074, 28, 28);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[0]], 53, 1095, 180, 50);
                }
              };
              const ctxTitle2 = canvas_global.getContext("2d");
              ctxTitle2.fillStyle = "white";
              ctxTitle2.fontWeight = 120;
              ctxTitle2.font = "23px 'GilroyBold'";
              const imageContact2 = new Image();
              imageContact2.src = callBack_contactImage(template.combo.contactosSel[1]);
              imageContact2.onload = function () {
                if (template.combo.motivo === "navidad") {
                  ctx.drawImage(imageContact2, 250, 1080, 28, 28);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[1]], 285, 1101, 180, 40);
                } else {
                  ctx.drawImage(imageContact2, 250, 1074, 28, 28);
                  ctx.fillText(template.combo.contacto[template.combo.contactosSel[1]], 285, 1095, 180, 40);
                }
              };
            }
          }
        }, 0);
        //plantilla
        ctx.drawImage(imagefinal, 0, 0, canvas_global.width, canvas_global.height);
      };

      if (final_imagen) {
        setTimeout(() => {
          var image = canvas_global.toDataURL("image/png").replace("image/png", "image/octet-stream");
          setDescImage(image);
        }, 1000);
      }
    },
    [final_imagen]
  );

  useEffect(() => {
    dispatch(showTermsAndConditions(true));
  }, [dispatch]);

  useEffect(() => {
    if (finalTemplate) mergeTemplate(finalTemplate);
  }, [finalTemplate, final_imagen, mergeTemplate]);

  return !final_imagen ? (
    <Container>
      <Sidebar landscape={landscape} />

      <GridBinding theme={theme}>
        {finalTemplate ? <Canvas2D id="canvas" height={1244} width={700} /> : <BackImageComboRect src={imageBackRect} />}
      </GridBinding>
    </Container>
  ) : (
    finalTemplate && (
      <ContainerDescarga>
        <SubContainerDescarga>
          <GridLeft>
            <ContainerImages style={{ paddingLeft: 50 }}>
              <ImageText src={final_image} />
              {landscape && <ImageSmile src={smile_image} />}
            </ContainerImages>

            <ContainerIcons>
              <ImageIcon src={fb_image} />
              <ImageIcon src={wsp_image} />
              <ImageIcon src={inst_image} />
            </ContainerIcons>
          </GridLeft>

          <ImageWrapper theme={theme}>
            <div
              style={{
                width: "100%",
                height: landscape ? "70%" : "100%",
                position: "relative",
                left: "0px",
                top: "0px",
              }}
            >
              <img
                src={phone}
                className="eye"
                alt=""
                style={{
                  position: "absolute",
                  height: landscape ? "100%" : "100%",
                  left: "30%",
                }}
              />

              <Canvas2DDescarga id="canvas" height={1244} width={700} size={"84%"} top={"10%"} />
              <div
                style={{
                  display: "flex",
                  flex: 0.4,
                  height: landscape ? "auto" : "80px",
                }}
              >
                <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
              </div>
            </div>
            {landscape && (
              <GridButtons>
                <div style={{ display: "flex", flex: 0.3 }}>
                  <BackButton
                    theme={theme}
                    onClick={() => {
                      dispatch(comboQR.setImagenFinal(false));
                      dispatch(comboQR.setEtapa("contact"));
                    }}
                  >
                    {" "}
                    ATRAS
                  </BackButton>
                </div>

                <div style={{ display: "flex", flex: 0.4 }}>
                  <BtnDescarga id="downloadLink" href={descImage} download={`descarga${Math.random()}.jpg`} onClick={sendMetric} />
                </div>

                <div style={{ display: "flex", flex: 0.3 }}>
                  <HomeButton
                    theme={theme}
                    onClick={() => {
                      dispatch(comboQR.setImagenFinal(false));
                      dispatch(comboQR.resetTemplate());
                    }}
                  >
                    VOLVER AL INICIO
                  </HomeButton>
                </div>
              </GridButtons>
            )}
          </ImageWrapper>
        </SubContainerDescarga>
        {/*  <Footer /> */}
        {landscape && <Footer />}
      </ContainerDescarga>
    )
  );
};
