import React from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Container, ModalContainer } from "./styles";
import { useTheme } from "styled-components";

export default function ({ openModal, onClose }) {
  const theme = useTheme();
  return (
    <Modal open={openModal} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <ModalContainer theme={theme}>
        <Container>
          <HighlightOffIcon style={{ color: theme.colors.primary }} fontSize="large" onClick={onClose} />
        </Container>

        <div style={{ height: "400px", overflow: "scroll" }}>
          <h1>BASES Y CONDICIONES</h1>
          <p>
            1) Cada uno de los comercios será el único responsable por el contenido que le den al Combo o al Menú según corresponda, asumiendo el
            compromiso de mantener indemne a BLIVE por cualquier eventual reclamo que, por ese motivo, pueda efectuar un tercero;
          </p>
          <p>
            2) También deberán de abstenerse en el diseño del Combo o el Menú y en la publicidad que efectúen de afectar directa o indirectamente la
            imagen y prestigio de BLIVE y/o de las marcas con las cuales comercializa sus productos;
          </p>
          <p>
            3) Cada comercio en la configuración del Combo o Menú y en la publicidad que realice deberá ser lo suficientemente claro y transparente de
            modo de no generar confusión o engaño en el público consumidor;
          </p>
          <p>
            4) En la configuración del combo o menú no podrán incorporar productos que compitan con los comercializados por BLIVE o resulten
            incompatibles con los mismos.
          </p>
          <p>
            5) De comprobarse alguna violación de alguna de las precedentes condiciones y/o de cualquier disposición legal aplicable al caso BLIVE
            podrá, a su solo criterio, dejar sin efecto la registración del comercio responsable.
          </p>
          <p>6) Cada uno de los comercios se obliga a pagar los tributos por publicidad que se apliquen en su municipio.</p>
        </div>
      </ModalContainer>
    </Modal>
  );
}
