import React from "react";
import styled, { useTheme } from "styled-components";
import { useDispatch } from "react-redux";

//* importamos ambas actions
import { createMenu } from "../redux/actions";
import { darkenColor } from "../../../utils/functions";

import { ReactSVG } from "react-svg";
import bitmap from "../../../assets/image/02_crear_combo/B5.svg";

export default function BtnPlantillaCombo({ template, stage }) {
  const dispatch = useDispatch();
  const comboType = createMenu.setEtapa;
  const stageType = "template";
  const theme = useTheme();

  const buttonInactive = (plantilla) => {
    return plantilla?.menu?.srcPlantilla ? (
      <StyledSVG src={bitmap} onClick={() => dispatch(comboType(stageType))} color={theme.colors.primary} background={theme.colors.background} />
    ) : (
      <StyledSVG src={bitmap} color={theme.colors.button} background={theme.colors.background} />
    );
  };

  const StyledSVGContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.border};
    @media screen and (max-width: 700px) {
      width: 60px;
      height: 60px;
    }
  `;

  const StyledSVG = styled(ReactSVG)`
    svg {
      margin-top: -0.6em;
      width: 100%;
      height: 100%;

      path {
        fill: ${(props) => props.color || "currentColor"};
      }
    }
    ${(props) =>
      props.background &&
      `
    & > div {
      background-color: ${props.background};
    }
  `}
  `;

  return (
    <StyledSVGContainer>
      {stage === stageType ? (
        <StyledSVG src={bitmap} color={theme.colors.background} background={darkenColor(theme.colors.primary, 20)} />
      ) : (
        buttonInactive(template)
      )}
    </StyledSVGContainer>
  );
}
