import styled from "styled-components";
import { ReactSVG } from "react-svg";

export const GridControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100px;
  justify-content: flex-start;
  margin-top: -2px;
  @media screen and (max-width: 700px) {
    flex-direction: row;
    // height: 9vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
  }
`;

export const GridLayout = styled.div`
  display: flex;
  flex: 1;
  //background-color: green;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80px;
  position: relative;
  @media (orientation: portrait) {
    flex: 6;
    //  height: 10vh;
    width: 18vw;
  }
`;

export const Flecha = styled(ReactSVG)`
  svg {
    width: 60%;
    height: 60%;
    path {
      fill: ${(props) => props.color || "currentColor"};
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: -10px; /* Ajusta el posicionamiento horizontal de la flecha */
  transform: translateY(-50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)}; /* Muestra la flecha solo si isActive es true */
  transition: opacity 0.3s ease-in-out; /* Agrega una transición para suavizar el cambio de opacidad */

  @media screen and (max-width: 700px) {
    opacity: 0; /* Oculta la flecha en pantallas pequeñas */
  }
`;
