import React from "react";
import { ButtonUploadImage } from "./styles";
//TODO VER SI SE USA COMPONENTE
const ButtonUploader = ({ selectedFile, setSelectedFile }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    console.log(event);
    const fileUploaded = event.target.files[0];
    setSelectedFile(fileUploaded);
  };

  return (
    <div>
      <ButtonUploadImage onClick={handleClick}>{selectedFile ? "CAMBIAR IMAGEN" : "SELECCIONAR IMAGEN"}</ButtonUploadImage>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </div>
  );
};
export { ButtonUploader };
