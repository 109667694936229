import React from 'react';
import styled from 'styled-components';
import Img_Header from '../assets/metrics/ _HEADER.png';

export default function HeaderMetrics() {
  return (
    <Container>
      <img style={{width:'100%', height:'12vh'}} src={Img_Header} alt='img' />
    </Container>
  )
}

const Container = styled.div`
  
`
