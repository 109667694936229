import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "@material-ui/core";
import styled, { useTheme } from "styled-components";
import ListItemComponent from "./listItem";
import { logoutUser } from "../../redux/credentials/credentials";
import TermsAndConditions from "../TermsAndConditions";
import { darkenColor } from "../../utils/functions";
import { combo } from "../../pages/create-combo/utils/actions";
import { createMenu } from "../../pages/createMenu/redux/actions";
import { comboQR } from "../../pages/comboQR/utils/actions";

const NestedListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  padding: 0;
`;

const StyledListItem = styled.div`
  background-color: ${({ theme }) => darkenColor(theme.colors.primary, 20)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
`;

const StyledListItemText = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: GilroyMedium;
  //font-size: x-large;
  margin: 0;
`;

export default function NestedList({ handleDrawerClose }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.credentials.userLogueado);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();

  const handleSignout = () => {
    dispatch(logoutUser());
    localStorage.clear();
    // window.location.reload();
  };

  const onClose = () => {
    setOpenModal(false);
  };
  const resetCombo = () => {
    dispatch(combo.resetTemplate());
    handleDrawerClose();
    //history.push("/account/login");
  };

  const resetMenu = () => {
    dispatch(createMenu.resetTemplate());
    handleDrawerClose();
    //history.push("/account/login");
  };

  const resetQR = () => {
    dispatch(comboQR.resetTemplate());
    handleDrawerClose();
    // history.push("/account/login");
  };
  return (
    <NestedListContainer component="nav" aria-labelledby="nested-list-subheader">
      {/*    {user && user.accessToken && ( */}
      <>
        <ListItemComponent
          path="/account/createcombo"
          selected={window.location.pathname.includes("/account/createcombo")}
          title="Crear Combo"
          onClick={resetCombo}
        />
        <ListItemComponent
          path="/account/createMenu"
          selected={window.location.pathname.includes("/account/createMenu")}
          title="Crear Menú"
          onClick={resetMenu}
        />

        <ListItemComponent
          path="/account/createQR"
          selected={window.location.pathname.includes("/account/createQR")}
          title="Crear QR"
          onClick={resetQR}
        />

        {/*  <StyledListItem onClick={handleSignout}>
          <StyledListItemText theme={theme}>Cerrar sesión</StyledListItemText>
        </StyledListItem> */}

        <StyledListItem onClick={() => setOpenModal(true)}>
          <StyledListItemText theme={theme}>Bases y condiciones</StyledListItemText>
        </StyledListItem>
        <TermsAndConditions openModal={openModal} onClose={onClose} />
      </>
      {/*   )} */}
    </NestedListContainer>
  );
}
