import styled from "styled-components";

//* assets
import btnCombo from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_combo.png";
import btnComboHover from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_combo_hover.png";
import btnMenu from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_menu.png";
import btnMenuHover from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_menu_hover.png";
import btnQR from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_qr.png";
import btnQRHover from "../../assets/image/01_inicio_y_registro/01_registro_boton_icono_qr_hover.png";

export const Container = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  height: 90%;
  background-color: ${({ theme }) => theme.colors.primary};
  @media (orientation: portrait) {
    margin: 0px;
    width: 100%;
    height: 100%;
  }
`;

export const FondoGrd = styled.div``;

export const GrdHome = styled.div`
  --id: GrdHome;
  display: flex;
  flex: 0.5;
  @media (orientation: portrait) {
    display: grid;
    margin: 60px 0px 20px 0px;
    grid-template-rows: 60px 140px;
  }
`;
export const GrdBtnHome = styled.div`
  --id: GrdBtnHome;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0.5;
  // padding: 0 30px;
  @media (orientation: portrait) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 40px;
  }
`;
export const GrdBtnColumn = styled.div`
  --id: GrdBtnHome;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
export const ImageBtnHome = styled.img`
  --id: ImageBtnHome;
  width: 80%;
`;
export const BtnCombo = styled.a`
  --id: BtnCombo;
  cursor: pointer;
  width: 7em;
  height: 7em;
  background-image: url(${btnCombo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  :hover {
    background-image: url(${btnComboHover});
    background-repeat: no-repeat;
  }
  @media (orientation: portrait) {
    background-size: contain;
    background-position: center;
  }
`;
export const BtnMenu = styled.a`
  --id: BtnCombo;
  width: 7em;
  height: 7em;
  cursor: pointer;
  background-image: url(${btnMenu});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  :hover {
    background-image: url(${btnMenuHover});
    background-repeat: no-repeat;
  }
  @media (orientation: portrait) {
    background-size: contain;
    background-position: center;
  }
`;
export const BtnQR = styled.a`
  --id: BtnCombo;
  width: 7em;
  height: 7em;
  cursor: pointer;
  background-image: url(${btnQR});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  :hover {
    background-image: url(${btnQRHover});
    background-repeat: no-repeat;
  }
  @media (orientation: portrait) {
    background-size: contain;
    ////agreagar position
  }
`;
export const Title = styled.p`
  font-family: GilroyBold;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  text-align: center;
  @media (orientation: portrait) {
    font-size: 20px;
  }
`;

export const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-family: GilroyMedium;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  &:hover {
    color: ${({ theme }) => theme.colors.background};
  }
`;
export const GlassBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-size: 14px;
  font-family: GilroyMedium;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  height: ${(props) => (props.height ? props.height + "px" : "35px")};
  border: ${(props) => (props.empty ? "none" : `1.5px solid ${({ theme }) => theme.colors.primary};`)};
  background: ${({ theme }) => theme.colors.secondary};
`;

export const Input = styled.input`
  color: white;
  border: none;
  border-radius: 0;
  padding: 6px;
  font-family: GilroyMedium;
  font-size: 18px;
  font-weight: lighter;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.secondary};
  outline: none;
  background: transparent;
  height: 25px;
  margin: 0px auto;
`;
export const ErrorMessage = styled.p`
  color: white;
  font-family: GilroyMedium;
  font-size: 12px;
  font-weight: lighter;
  margin: 0;
`;
