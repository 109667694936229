import { createGlobalStyle } from "styled-components";
import font from "./fonts/Gilroy-Regular.otf";

export default createGlobalStyle`


::-webkit-scrollbar {
  width: 3px;  
  background: transparent;
  border-radius: 50px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255,255,255,0.5) inset !important;
}

::-webkit-scrollbar-thumb {
  background: #b0ae9d;
}

@font-face {
  font-family: 'GilroyExtrabold';
  src: local('GilroyExtrabold'), url(./fonts/Gilroy-Extrabold.otf) format('otf');
}

@font-face {
  font-family: 'GilroyBlack';
  src: local('GilroyBlack'), url(./fonts/Gilroy-Black.otf) format('otf');
}

@font-face {
  font-family: 'GilroyBlackItalic';
  src: local('GilroyBlackItalic'), url(./fonts/Gilroy-BlackItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyBold';
  src: local('GilroyBold'), url(./fonts/Gilroy-Bold.otf) format('otf');
}

@font-face {
  font-family: 'GilroyBoldItalic';
  src: local('GilroyBoldItalic'), url(./fonts/Gilroy-BoldItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyHeavy';
  src: local('GilroyHeavy'), url(./fonts/Gilroy-Heavy.otf) format('otf');
}

@font-face {
  font-family: 'GilroyHeavyItalic';
  src: local('GilroyHeavyItalic'), url(./fonts/Gilroy-HeavyItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyLight';
  src: local('GilroyLight'), url(./fonts/Gilroy-Light.otf) format('otf');
}

@font-face {
  font-family: 'GilroyLightItalic';
  src: local('GilroyLightItalic'), url(./fonts/Gilroy-LightItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyMedium';
  src: local('GilroyMedium'), url(./fonts/Gilroy-Medium.otf) format('otf');
}

@font-face {
  font-family: 'GilroyMediumItalic';
  src: local('GilroyMediumItalic'), url(./fonts/Gilroy-MediumItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyRegular';
  src: local('GilroyRegular'), url(${font}) format('otf');
}

@font-face {
  font-family: 'GilroyRegularItalic';
  src: local('GilroyRegularItalic'), url(./fonts/Gilroy-RegularItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroySemibold';
  src: local('GilroySemibold'), url(./fonts/Gilroy-Semibold.otf) format('otf');
}

@font-face {
  font-family: 'GilroySemiboldItalic';
  src: local('GilroySemiboldItalic'), url(./fonts/Gilroy-SemiboldItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyThin';
  src: local('GilroyThin'), url(./fonts/Gilroy-Thin.otf) format('otf');
}

@font-face {
  font-family: 'GilroyThinItalic';
  src: local('GilroyThinItalic'), url(./fonts/Gilroy-ThinItalic.otf) format('otf');
}

@font-face {
  font-family: 'GilroyUltraLight';
  src: local('GilroyUltraLight'), url(./fonts/Gilroy-UltraLight.otf) format('otf');
}

@font-face {
  font-family: 'GilroyUltraLightItalic';
  src: local('GilroyUltraLightItalic'), url(./fonts/Gilroy-UltraLightItalic.otf) format('otf');
}


/* Bebas neue */

@font-face {
  font-family: 'BebasNeueBold';
  src: local('BebasNeueBold'), url(./fonts/BebasNeue-Bold.otf) format('otf');
}

@font-face {
  font-family: 'BebasNeueBook';
  src: local('BebasNeueBook'), url(./fonts/BebasNeue-Book.otf) format('otf');
}

@font-face {
  font-family: 'BebasNeueLight';
  src: local('BebasNeueLight'), url(./fonts/BebasNeue-Light.otf) format('otf');
}

@font-face {
  font-family: 'BebasNeueRegular';
  src: local('BebasNeueRegular'), url(./fonts/BebasNeue-Regular.otf) format('otf');
}

@font-face {
  font-family: 'BebasNeueThin';
  src: local('BebasNeueThin'), url(./fonts/BebasNeue-Thin.otf) format('otf');
}


/* Gotham */

@font-face {
  font-family: 'GothamBlack';
  src: local('GothamBlack'), url(./fonts/Gotham-Black.otf) format('otf');
}

@font-face {
  font-family: 'GothamBlackItalic';
  src: local('GothamBlackItalic'), url(./fonts/Gotham-BlackItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'), url(./fonts/Gotham-Bold.otf) format('otf');
}

@font-face {
  font-family: 'GothamBoldItalic';
  src: local('GothamBoldItalic'), url(./fonts/Gotham-BoldItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'), url(./fonts/Gotham-Book.otf) format('otf');
}

@font-face {
  font-family: 'GothamBookItalic';
  src: local('GothamBookItalic'), url(./fonts/Gotham-BookItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'), url(./fonts/Gotham-Light.otf) format('otf');
}

@font-face {
  font-family: 'GothamLightItalic';
  src: local('GothamLightItalic'), url(./fonts/Gotham-LightItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamMedium';
  src: local('GothamMedium'), url(./fonts/Gotham-Medium.otf) format('otf');
}

@font-face {
  font-family: 'GothamMediumItalic';
  src: local('GothamMediumItalic'), url(./fonts/Gotham-MediumItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamThin';
  src: local('GothamThin'), url(./fonts/Gotham-Thin.otf) format('otf');
}

@font-face {
  font-family: 'GothamThinItalic';
  src: local('GothamThinItalic'), url(./fonts/Gotham-ThinItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamUltra';
  src: local('GothamUltra'), url(./fonts/Gotham-Ultra.otf) format('otf');
}

@font-face {
  font-family: 'GothamUltraItalic';
  src: local('GothamUltraItalic'), url(./fonts/Gotham-UltraItalic.otf) format('otf');
}

@font-face {
  font-family: 'GothamXLight';
  src: local('GothamXLight'), url(./fonts/Gotham-XLight.otf) format('otf');
}

@font-face {
  font-family: 'GothamXLightItalic';
  src: local('GothamXLightItalic'), url(./fonts/Gotham-XLightItalic.otf) format('otf');
}

@font-face {
  font-family: 'You2013';
  src: local('You2013'), url(./fonts/You2013.otf) format('otf');
}

#root{
  font-family: 'You2013','GilroyRegular';
  height: 100vh;
  overflow: hidden;
}
.contenedor::-webkit-scrollbar {
    -webkit-appearance: none;
}

.contenedor::-webkit-scrollbar:vertical {
    width:10px;
}

.contenedor::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.contenedor::-webkit-scrollbar:horizontal {
    height: 10px;
}

.contenedor::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.contenedor::-webkit-scrollbar-track {
    border-radius: 10px;  
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: gray;
  font-size: 14px;
  font-weight: 200;
}

/* input[type="submit"] {
  background: #ec5990;
  color: gray;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input[type="submit"]:hover {
  background: #bf1650;
} */

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.desc {
  text-align: center;
}
.outer, .wrap, .html2canvas, .image_text {
  display: inline-block;
  vertical-align: top;
}
.wrap {
  text-align: center;
}
#imagewrap {
}
#wow {
  color: red;
  display: block;
  transform: translate(0px, -12px) rotate(-10deg);
}

::-webkit-scrollbar {
  width: 8px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
}

`;
