import axios from "axios";

export const ADD_MENU_METRIC = "ADD_MENU_METRIC";
export const GET_ALL_MENUS = "GET_ALL_MENUS";
export const GET_MENUS_BY_TYPE = "GET_MENUS_BY_TYPE";
export const ADD_MENU_ENTRY = "ADD_MENU_ENTRY";
export const GET_MENU_ENTRIES = "GET_MENU_ENTRIES";

export const getMenuEntries = () => ({
  type: GET_MENU_ENTRIES,
  payload: new Promise((res, rej) => {
    axios
      .get("/menuMetrics/menu-entries")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addMenuEntry = (data) => ({
  type: ADD_MENU_ENTRY,
  payload: new Promise((res, rej) => {
    axios
      .post("/menuMetrics/menu-entry", { token: data })
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addMenuMetric = (data) => ({
  type: ADD_MENU_METRIC,
  payload: new Promise((res, rej) => {
    axios
      .post("/menuMetrics/add", data)
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllMenus = () => ({
  type: GET_ALL_MENUS,
  payload: new Promise((res, rej) => {
    axios
      .get("/menuMetrics/all-menus")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getMenusByType = () => ({
  type: GET_MENUS_BY_TYPE,
  payload: new Promise((res, rej) => {
    axios
      .get("/menuMetrics/all-menus-by-type")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});
