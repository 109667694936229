export default {
  palette: {
    primary: "#D82403", //rojo letras
    secondary: "#EDEBD2", //crema textos
    lightGray: "#B1AE9B", // gris medio claro //BORDES
    darkGray: "#585953", //BOTONES
    //mediumGray: "#818181",
    //  darkRed: '#B40912', //header y foooter
    grey: "#e0e0e0", //gris claro //INPUTS
    white: "#FFFFFF", //BACKGROUND
    black: "#000000", // TEXTO
    yellow: "#fcfac1", //SELECCION
    colorLogo: "rgba(67,161,194,0.5)", //LOGO
  },
};
