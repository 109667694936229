import React from "react";
import { useSelector } from "react-redux";

//* components
import SwitchStage from "../../utils/SwitchStage";

import Plantilla from "../../../../components/common/buttons/Plantilla";
import Bebida from "../../../../components/common/buttons/Bebida";
import Food from "../../../../components/common/buttons/Food";
import Contact from "../../../../components/common/buttons/Contact";
import Description from "../../../../components/common/buttons/Description";
import Logo from "../../../../components/common/buttons/Logo";
//import FlechaSelect from "../../../../components/common/flechaSelect";
//import flecha from "../../../../assets/image/02_crear_combo/02_crear_combo_iconos_flecha_seleccion.png";
import { useTheme } from "styled-components";
import flecha from "../../../../assets/image/02_crear_combo/arrow.svg";
import { darkenColor } from "../../../../utils/functions";

//* styles
import { GridControl, GridLayout, ButtonContainer, Container, Flecha } from "./styles";

export const Sidebar = ({ landscape }) => {
  const { etapa, plantilla, setSelUserLogo, setSelUserDescripcion, setSelUserBebida, setSelUserComida, setSelUserContacto } = useSelector(
    (state) => state.combo
  );
  const theme = useTheme();
  const FlechaSelect = ({ isActive }) => (
    <Container isActive={isActive}>
      <Flecha src={flecha} color={darkenColor(theme.colors.primary, 20)} />
    </Container>
  );
  const SidebarButton = ({ children, isActive }) => (
    <ButtonContainer>
      {children}
      {isActive && <FlechaSelect isActive={isActive} />}
    </ButtonContainer>
  );

  return (
    <GridLayout>
      <GridControl>
        <SidebarButton isActive={etapa === "PLANTILLA"}>
          <Plantilla type="combo" stage={etapa} template={plantilla} />
        </SidebarButton>
        <SidebarButton isActive={etapa === "LOGO"}>
          <Logo type="combo" stage={etapa} template={plantilla} setLogo={setSelUserLogo} />
        </SidebarButton>

        <SidebarButton isActive={etapa === "DESCRIPCION"}>
          <Description type="combo" stage={etapa} template={plantilla} setDescripcion={setSelUserDescripcion} />
        </SidebarButton>

        <SidebarButton isActive={etapa === "BEBIDA"}>
          <Bebida type="combo" stage={etapa} template={plantilla} setBebida={setSelUserBebida} />
        </SidebarButton>

        <SidebarButton isActive={etapa === "COMIDA"}>
          <Food type="combo" stage={etapa} template={plantilla} setComida={setSelUserComida} />
        </SidebarButton>

        <SidebarButton isActive={etapa === "CONTACTO"}>
          <Contact type="combo" stage={etapa} template={plantilla} setContacto={setSelUserContacto} />
        </SidebarButton>
      </GridControl>
      <SwitchStage />
    </GridLayout>
  );
};
