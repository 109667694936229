import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import history from "../history";

import logo from "../assets/logo/LOGO-blive.png";
//import navbar_perfil_sin_ingresar from '../assets/image/01_inicio_y_registro/01_navbar_perfil_sin_ingresar.png';

import Drawer from "./drawer/drawer";

import { combo } from "../pages/create-combo/utils/actions";
import { createMenu } from "../pages/createMenu/redux/actions";
import { comboQR } from "../pages/comboQR/utils/actions";

import { darkenColor } from "../utils/functions";

export default function Header() {
  const token = useSelector((state) => state.credentials.userLogueado.accessToken);
  const showTermsAndConditions = useSelector((state) => state.ui.showTermsAndConditions);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen(true);
  const dispatch = useDispatch();
  const handleDrawerClose = () => setOpen(false);
  const theme = useTheme();

  const homeBtn = () => {
    dispatch(combo.resetTemplate());
    dispatch(createMenu.resetTemplate());
    dispatch(comboQR.resetTemplate());
    history.push("/private/home");
  };

  return (
    <Container token={token} theme={theme}>
      <Image src={logo} onClick={homeBtn} />

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <>
          {(showTermsAndConditions || token) && (
            <>
              <Drawer handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />

              <IconButton
                color="inherit"
                style={{ color: theme.colors.background, marginRight: "2%" }}
                onClick={!open ? handleDrawerOpen : handleDrawerClose}
              >
                {!open ? <MenuIcon style={{ fontSize: "38px" }} /> : <CloseIcon style={{ fontSize: "38px" }} />}
              </IconButton>
            </>
          )}
        </>
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${(props) => darkenColor(props.theme.colors.primary, 20)};
  border-bottom: ${(props) => !props.token && `2px solid ${props.theme.colors.secondary}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 10%;
  @media (orientation: portrait) {
    height: 6%;
  }
`;
const Image = styled.img`
  width: 100px;
  margin-left: 1%;
  cursor: pointer;
  @media (orientation: portrait) {
    width: 100px;
    margin-left: 2%;
  }
`;
