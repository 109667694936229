import axios from "axios";

export const lOGIN_USER = "lOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const SEND_CODE = "SEND_CODE";
export const LOGOUT = "LOGOUT";

export const SEND_RECOVERY_CODE = "SEND_RECOVERY_CODE";
export const CHECK_RECOVERY_CODE = "CHECK_RECOVERY_CODE";
export const SEND_NEW_CREDENTIALS = "SEND_NEW_CREDENTIALS";

export const loginUser = (data) => ({
  type: lOGIN_USER,
  payload: data,
});

export const registerUser = (data) => ({
  type: REGISTER_USER,
  payload: new Promise((res, rej) => {
    axios
      .post("/clients/signup", data, {
        headers: {
          Authorization: "Bearer FeMsA@2022",
        },
      })
      .then((response) => res(response.data))
      .catch((err) => rej(err.response));
  }),
});

export const sendCheckCode = (data) => ({
  type: SEND_CODE,
  payload: new Promise((res, rej) => {
    axios
      .post("/credentials/check-code", data)
      .then((response) => res(response.data))
      .catch((err) => rej(err.response));
  }),
});

export const sendRecoryCode = (data) => ({
  type: SEND_RECOVERY_CODE,
  payload: new Promise((res, rej) => {
    axios
      .post("/clients/reset_password", data, {
        headers: {
          Authorization: "Bearer FeMsA@2022",
        },
      })
      .then((response) => res(response.data))
      .catch((err) => rej(err.response));
  }),
});

export const checkRecoveryCode = (data) => ({
  type: CHECK_RECOVERY_CODE,
  payload: new Promise((res, rej) => {
    axios
      .post("/credentials/check-password-code", data)
      .then((response) => res(response.data))
      .catch((err) => rej(err.response));
  }),
});

export const sendNewCredentials = (data) => ({
  type: SEND_NEW_CREDENTIALS,
  payload: new Promise((res, rej) => {
    axios
      .post("/credentials/recover-password", data, {
        headers: {
          Authorization: "Bearer FeMsA@2022",
        },
      })
      .then((response) => res(response.data))
      .catch((err) => rej(err.response));
  }),
});

export const logoutUser = (token) => ({
  type: LOGOUT,
  payload: axios.get("/credentials/logout", {
    headers: {
      "auth-token": token,
    },
  }),
});
