import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-rows: 17vh 5vh 17vh;
  justify-content: center;
`;

export const ContainerCards = styled.div`
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-columns: 100px 160px 160px 160px;
  margin: 20px;
  column-gap: 1em;
  justify-content: center;
`;
export const ContainerCardsTwo = styled.div`
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-columns: 160px 160px 160px 160px 200px;
  margin: 20px;
  column-gap: 1em;
  justify-content: center;
`;

export const Card = styled.button`
  font-family: "You2013", "GilroyRegular";
  font-size: 16px;
  display: grid;
  grid-template-rows: 30px 50px;
  align-items: center;
  width: 150px;
  background: lightgrey;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 6px;
  min-height: 95px;
  max-height: 100px;
  //box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: 0;
  :hover {
    background-color: #d8322d;
    color: white;
  }
`;
export const CardnoButton = styled.button`
  font-family: "You2013", "GilroyRegular";
  font-size: 16px;
  display: grid;
  grid-template-rows: 30px 50px;
  align-items: center;
  width: 150px;
  background: lightgrey;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 6px;
  min-height: 95px;
  max-height: 100px;
  border: 0;
`;
export const Linea = styled.div``;
