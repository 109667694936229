import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { combo } from "../../utils/actions";
import { compare } from "../../../../utils/functions";

//* components
import AlertComponent from "../../../../components/common/alert";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";
import Dropdown from "../../../../components/Dropdown";
import { MiniPreview } from "./components";

//* styles
import { Container, ButtonsContainer, PreviewContainer, TittleContainer } from "./styles";

export const Plantilla = ({ landscape }) => {
  const dispatch = useDispatch();
  const plantillas = useSelector((state) => state.combo.plantillas);
  const exist = useSelector((state) => state.combo.plantilla);

  const [openAlert, setAlert] = useState(false);
  const handleClose = (event, reason) => setAlert(false);

  const [plantilla, setPlantilla] = useState(null);
  const [forma, setForma] = useState(null);
  const [referencia, setReferencia] = useState(null);
  const [list, setList] = useState([]);

  const comboFilter = useCallback(() => {
    if (forma && referencia) {
      setList(plantillas.filter((p) => p.ref === referencia && p.forma === forma));
    } else if (forma) {
      setList(plantillas.filter((p) => p.forma === forma));
    } else {
      setList(plantillas.filter((p) => p.ref === referencia));
    }
  }, [forma, referencia, plantillas]);

  const setPlantillaPosta = (plant) => {
    setPlantilla(plant.id);
    dispatch(combo.setPlantilla({ ...plant }));
  };

  useEffect(() => {
    if (forma || referencia) comboFilter();
    else {
      setList(plantillas.sort(compare));
    }
  }, [forma, referencia, plantillas, comboFilter]);

  return (
    <Container>
      <TittleContainer>
        <Title text="Elegí tu plantilla" />
      </TittleContainer>

      <Subtitle text="Recordá que podés cambiar todos los elementos de plantilla en los próximos pasos." />

      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Seleccione alguna plantilla." />

      {plantillas && (
        <ButtonsContainer>
          <Dropdown
            name="FORMATO"
            data={[
              {
                name: "Todos",
                action: () => {
                  setReferencia(null);
                  setForma(null);
                },
              },
              {
                name: "Cuadrado",
                action: () => setForma("cuadrado"),
              },
              {
                name: "Rectangulo",
                action: () => setForma("rectangular"),
              },
            ]}
          />

          <Dropdown
            name="COMBOS"
            data={[
              { name: "Simple", action: () => setReferencia("simple") },
              { name: "Dúo", action: () => setReferencia("duo") },
            ]}
          />

          {landscape && (
            <GlobalButton
              disabled={!exist}
              content="SIGUIENTE"
              onClick={() => {
                if (plantilla) dispatch(combo.setEtapa("LOGO"));
                else setAlert(true);
              }}
            />
          )}
        </ButtonsContainer>
      )}

      {!landscape && (
        <GlobalButton
          disabled={!exist}
          content="SIGUIENTE"
          onClick={() => {
            if (plantilla) dispatch(combo.setEtapa("LOGO"));
            else setAlert(true);
          }}
        />
      )}

      <PreviewContainer>
        {list.map((template) => (
          <MiniPreview active={plantilla === template.id} key={template.id} onClick={() => setPlantillaPosta(template)} src={template.src} />
        ))}
      </PreviewContainer>
    </Container>
  );
};
