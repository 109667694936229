import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
`;

export const CustomLabel = styled.label`
  flex: 0.4;
  width: 130px;
  text-align: center;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CustomLabelLeft = styled.label`
  flex: 0.6;
  width: 130px;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  text-align: center;
  display: inline-block;
  padding: 1px 6px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px;
`;

export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
