import { createAction } from "redux-actions";

export const setEtapa = createAction("comboQR.setStage");
export const setTemplate = createAction("comboQR.setTemplate");
export const setLogo = createAction("comboQR.setLogo");
export const setDescription = createAction("comboQR.setDescription");
export const setPicture = createAction("comboQR.setPicture");
export const setLink = createAction("comboQR.setLink");
export const setContact = createAction("comboQR.setContact");
export const setContactosSel = createAction("comboQR.setContactosSel");
export const setImagenFinal = createAction("comboQR.setImagenFinal");
export const setLegal = createAction("comboQR.setLegal");
export const resetTemplate = createAction("comboQR.resetTemplate");

export const setSelUserLogo = createAction("comboQR.setSelUserLogo");
export const setSelUrl = createAction("comboQR.setSelUrl");
export const setSelUserComida = createAction("comboQR.setSelUserComida");
export const setSelUserDescripcion = createAction(
  "comboQR.setSelUserDescripcion"
);
export const setSelUserContacto = createAction("comboQR.setSelUserContacto");
export const setPresetFoodPhoto = createAction("comboQR.setPresetFoodPhoto");
export const setCheckLogoQR = createAction("combo.setCheckLogoQR");

export const comboQR = {
  setTemplate, //
  setEtapa, //
  setLogo, //
  setDescription, //
  setPicture, //
  setLink, //
  setContactosSel,
  setSelUserLogo, //
  setSelUserComida, //
  setSelUserDescripcion, //
  setSelUserContacto,
  resetTemplate,
  setSelUrl,
  setContact,
  setPresetFoodPhoto,
  setLegal,
  setImagenFinal,
  setCheckLogoQR,
};
