import { handleActions } from 'redux-actions';
import { comboQR } from './actions';
import colors from '../../../assets/colors';

//* miniaturas
import minRed from '../../../assets/image/qr/icons/min_red.png';
import minBlack from '../../../assets/image/qr/icons/min_black.png';
import minWhite from '../../../assets/image/qr/icons/min_white.png';

//* background footer template
import footer_black from '../../../assets/image/qr/combos/bg_black.png';
import footer_white from '../../../assets/image/qr/combos/bg_white.png';

//* bg combos
import bgRed from '../../../assets/image/qr/combos/rojo.png';
import bgWhite from '../../../assets/image/qr/combos/blanco.png';
import bgBlack from '../../../assets/image/qr/combos/negro.png';

//* food
import food1 from '../../../assets/image/qr/food/12_plantilla_qr_comida_1.png';
import food2 from '../../../assets/image/qr/food/12_plantilla_qr_comida_2.png';
import food3 from '../../../assets/image/qr/food/12_plantilla_qr_comida_3.png';

const commonProperties = {
  contacto: {
    whatsapp: '+5491162000551',
    facebook: '@cuentaFacebook',
    instagram: '',
  },
  logo: undefined,
  url: 'www.google.com',
  foodImg: false,
  presetFoodPhoto: true,
  contactosSel: ['whatsapp', 'facebook'],
  legal:
    'Coca-Cola es marca registrada de The Coca-Cola Company. Coca-Cola sabor original: Bebida sin alcohol gasificada de extractos vegetales. Imágenes de fondo y comida: freepik.com.',
};

const initializeReducer = () => ({
  //* etapas del combo
  stages: ['template', 'logo', 'description', 'picture', 'link', 'contact'],
  stage: 'template',

  //* stages del combo
  template: undefined,
  logo: undefined,
  description: undefined,
  picture: undefined,
  link: undefined,
  contact: undefined,
  imagenFinal: undefined,

  setSelUserLogo: false,
  setSelUserDescripcion: false,
  setSelUrl: false,
  setSelUserComida: false,
  setSelUserContacto: false,
  setCheckLogoQR: false,

  //* templates prearmados
  templates: [
    {
      src: minWhite,
      id: 1,
      combo: {
        srcPlantilla: bgWhite,
        footerImg: footer_white,
        qrcolor: colors.palette.primary,
        descripcion: {
          title: 'Escaneá el código y conocé nuestros combos del día',
          color: colors.palette.primary,
        },
        color_text: 'black',
        logo_cc_color: 'black',
        legal_color: 'black',
        ...commonProperties,
      },
    },
    {
      src: minBlack,
      id: 2,
      combo: {
        srcPlantilla: bgBlack,
        footerImg: footer_black,
        qrcolor:colors.palette.black,
        descripcion: {
          title: 'Escaneá el código y conocé nuestros combos del día',
        },
        color_text: 'white',
        logo_cc_color: 'white',
        legal_color: 'white',
        ...commonProperties,
      },
    },
    {
      src: minRed,
      id: 3,
      combo: {
        srcPlantilla: bgRed,
        footerImg: footer_white,
        qrcolor: colors.palette.primary,
        descripcion: {
          title: 'Escaneá el código y conocé nuestros combos del día',
        },
        color_text: 'white',
        logo_cc_color: 'white',
        legal_color: 'white',
        ...commonProperties,
      },
    },
  ],

  //* foods
  food: [
    {
      id: 1,
      src: food1,
    },
    {
      id: 2,
      src: food2,
    },
    {
      id: 3,
      src: food3,
    },
  ],
});

export default handleActions(
  {
    [comboQR.setEtapa]: (state, action) => ({
      ...state,
      stage: action.payload,
    }), //* setea la etapa del combo
    [comboQR.setCheckLogoQR]: (state, action) => ({
      ...state,
      setCheckLogoQR: action.payload,
    }),
    [comboQR.setLogo]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.logo = action.payload;
      return { ...state, template: newPlantilla };
    }, //* setea el logo
    [comboQR.setSelUserLogo]: (state, action) => ({
      ...state,
      setSelUserLogo: action.payload,
    }), //* setea logo seleccionado por user

    [comboQR.setDescription]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.descripcion.title = action.payload;
      return { ...state, template: newPlantilla };
    }, //* setea la description del user
    [comboQR.setSelUserDescripcion]: (state, action) => ({
      ...state,
      setSelUserDescripcion: action.payload,
    }), //* setea description seleccionado por user

    [comboQR.setPicture]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.foodImg = action.payload.src;
      return { ...state, template: newPlantilla };
    }, //* imagen de comida
    [comboQR.setSelUserComida]: (state, action) => ({
      ...state,
      setSelUserComida: action.payload,
    }), //* setea imagen de comida seleccionada por user

    [comboQR.setLink]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.url = action.payload;
      return { ...state, template: newPlantilla };
    }, //* link para qr
    [comboQR.setSelUrl]: (state, action) => ({
      ...state,
      setSelUrl: action.payload,
    }), //* setea link para qr seleccionado por user

    [comboQR.setSelUserContacto]: (state, action) => ({
      ...state,
      setSelUserContacto: action.payload,
    }),
    [comboQR.setTemplate]: (state, action) => ({
      ...state,
      template: action.payload,
      setCheckLogoQR: false,
    }),

    [comboQR.setLegal]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.legal = action.payload;
      return { ...state, template: newPlantilla };
    },

    [comboQR.setContact]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.contacto = action.payload;
      return { ...state, template: newPlantilla };
    },
    [comboQR.setContactosSel]: (state, action) => {
      const newPlantilla = { ...state.template };
      newPlantilla.combo.contactosSel = action.payload;
      return { ...state, template: newPlantilla };
    },
    [comboQR.setPresetFoodPhoto]: (state, action) => {
      if (state.template) {
        const newPlantilla = {
          ...state.template,
          combo: {
            ...state.template.combo,
            presetFoodPhoto: action.payload,
          },
        };
        return { ...state, template: newPlantilla };
      }
    },
    [comboQR.setImagenFinal]: (state, action) => ({
      ...state,
      imagenFinal: action.payload,
    }),
    [comboQR.resetTemplate]: (state, action) => ({
      ...state,
      stage: 'template',
      template: undefined,
      imagenFinal: undefined,
      setSelUserLogo: false,
      setSelUserDescripcion: false,
      setSelUrl: false,
      setSelUserComida: false,
      setSelUserContacto: false,
      setCheckLogoQR: false,
    }),
  },

  initializeReducer()
);
