import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 4;
  padding: 50px;
  @media (orientation: portrait) {
    padding: 0px;
    justify-content: flex-start;
    height: 85vh;
  }
`;

export const FormLogin = styled.form`
  margin-top: 20px;
  margin-bottom: 20px;
  width: calc(100vw * 400 / 1366);

  @media (orientation: portrait) {
    margin: 20px 20px 0 20px;
    width: 94vw;
  }
`;

export const CustomLabel = styled.label`
  flex: 0.4;
  width: 130px;
  text-align: center;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  display: inline-block;
  padding: 1px 6px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
  @media (orientation: portrait) {
    border-radius: 50px;
  }
`;

export const CustomLabelLeft = styled.label`
  flex: 0.6;
  width: 130px;
  border: 1.5px solid ${({ theme }) => theme.colors.border};
  margin: 0px;
  font-family: GilroyMedium;
  text-align: center;
  display: inline-block;
  padding: 1px 6px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px;
`;

export const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (orientation: portrait) {
    width: 100vw;
    padding: 20px 20px 0 20px;
  }
`;

export const DescriptioSection = styled.div`
  margin-top: 1.5rem;
`;

export const ButtonUploadImage = styled.button`
  border-radius: 20px;
  border: ${({ theme }) => theme.colors.border} 1px solid;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 5px;
  text-align: center;
  background: transparent;
  text-transform: uppercase;
`;
export const ImagePreviewSection = styled.div`
  border-top:${({ theme }) => theme.colors.primary}; 1px solid;
  display: flex;
  flex-direction: column;
  //flex: 2;
`;
export const ContainerImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: auto;
`;
export const ContainerNextButton = styled.div`
  align-self: flex-end;
  margin: 1rem;
`;
export const ImagePreview = styled.img`
  width: 40vw;
  height: 40vw;
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
