import { lOGIN_USER, LOGOUT } from "./credentials";

const INITIAL_STATE = {
  didInvalidate: false,
  isFetching: false,
  userLogueado: {},
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lOGIN_USER:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        userLogueado: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        userLogueado: {},
      };

    default:
      return state;
  }
};

export default providers;
