import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createMenu } from "../../redux/actions";

//* components
import AlertComponent from "../../../../components/common/alert";
import GlobalButton from "../../../../components/common/GlobalButton";
import Title from "../../../../components/Title";
import Subtitle from "../../../../components/Subtitle";

//* styles
import { Form, Input, Label, TittleContainer } from "./styles";
import { useTheme } from "styled-components";

export const Contact = () => {
  const dispatch = useDispatch();
  const plantillaCombo = useSelector((state) => state.createMenu.template);
  const { handleSubmit, register } = useForm();
  const theme = useTheme();

  const [openAlert, setAlert] = useState(false);
  const exists = useSelector((state) => state.createMenu.setSelUserContact);

  const [checkbox, setCheckbox] = useState(exists ? plantillaCombo.menu.contactosSel : []);

  const setChecks = (name) => {
    if (checkbox.includes(name)) setCheckbox(checkbox.filter((value) => value !== name));
    else setCheckbox([...checkbox, name]);
  };

  const onSubmit = (values) => {
    let checkOk = [];

    if (checkbox.length > 0) {
      checkbox.map((checkElem) => {
        checkOk = [];
        if (checkElem === "whatsapp" && values.whatsapp.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
        if (checkElem === "facebook" && values.facebook.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
        if (checkElem === "instagram" && values.instagram.length === 0) {
          setAlert(true);
        } else {
          checkOk.push("ok");
        }
      });

      if (checkOk.length > 2) {
        dispatch(createMenu.setContact(values));
        dispatch(createMenu.setContactSel(checkbox));
        dispatch(createMenu.setSelUserContact(true));
        dispatch(createMenu.setImagenFinal(true));
      }
    } else {
      dispatch(createMenu.setContact(values));
      dispatch(createMenu.setContactSel(checkbox));
      dispatch(createMenu.setSelUserContact(true));
      dispatch(createMenu.setImagenFinal(true));
    }
    /*  if (
      ((checkbox.length === 1 && values[checkbox[0]].length)
        ||
        (checkbox.length === 2 && values[checkbox[0]].length && values[checkbox[1]].length))
    ) {
      dispatch(createMenu.setContact(values));
      dispatch(createMenu.setContactSel(checkbox));
      dispatch(createMenu.setSelUserContact(true));
      dispatch(createMenu.setImagenFinal(true));
    } else setAlert(true); */
  };

  const handleClose = (event, reason) => setAlert(false);

  return (
    <div>
      <TittleContainer>
        <Title text="Completá tus datos" c />
      </TittleContainer>

      <Subtitle text="Recordá que solo podes elegir dos datos de contacto." />

      <AlertComponent open={openAlert} color="warning" onClose={handleClose} message="Existen campos vacíos." />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Subtitle text="Completá los campos." />

        <GlobalButton
          content="FINALIZAR"
          onClick={handleSubmit(onSubmit)}
          //disabled={!checkbox.length}
        />
      </div>

      <Form>
        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("whatsapp")}
              type="checkbox"
              id="whatsapp"
              name="whatsapp"
              defaultChecked={exists && plantillaCombo?.menu.contactosSel.includes("whatsapp")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("whatsapp")}
            />
            <Label htmlFor="titulo">Whatsapp</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("whatsapp")}
            placeholder={plantillaCombo?.menu.contact.whatsapp}
            defaultValue={exists ? plantillaCombo?.menu.contact.whatsapp : ""}
            name="whatsapp"
            type="text"
            ref={register}
          />
        </div>
        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("facebook")}
              type="checkbox"
              id="facebook"
              name="facebook"
              defaultChecked={exists && plantillaCombo?.menu.contactosSel.includes("facebook")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("facebook")}
            />
            <Label htmlFor="titulo">Facebook</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("facebook")}
            placeholder={plantillaCombo?.menu.contact.facebook}
            defaultValue={exists ? plantillaCombo?.menu.contact.facebook : ""}
            name="facebook"
            type="text"
            ref={register}
          />
        </div>
        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={checkbox.length === 2 && !checkbox.includes("instagram")}
              type="checkbox"
              id="instagram"
              name="instagram"
              defaultChecked={exists && plantillaCombo?.menu.contactosSel.includes("instagram")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              onClick={() => setChecks("instagram")}
            />
            <Label htmlFor="titulo">Instagram</Label>
          </div>

          <Input
            disabled={checkbox.length === 2 && !checkbox.includes("instagram")}
            placeholder={plantillaCombo?.menu.contact.instagram}
            defaultValue={exists ? plantillaCombo?.menu.contact.instagram : ""}
            name="instagram"
            type="text"
            ref={register}
          />
        </div>
        {/* TODO  TIK TOK */}
        <div style={{ marginTop: 12, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              disabled={true}
              type="checkbox"
              id="instagram"
              name="instagram"
              //    defaultChecked={exists && plantillaCombo?.combo.contactosSel.includes("instagram")}
              color={theme.colors.border}
              style={{ width: "fit-content" }}
              //     onClick={() => setChecks("instagram")}
            />
            <Label style={{ color: theme.colors.border }} htmlFor="titulo">
              Tik Tok
            </Label>
          </div>

          <Input
            disabled={true}
            //   placeholder={plantillaCombo?.combo.contacto.instagram}
            //  defaultValue={exists ? plantillaCombo?.combo.contacto.instagram : ""}
            name="tiktok"
            type="text"
            ref={register}
          />
        </div>
      </Form>
    </div>
  );
};
