import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (orientation: portrait) {
    margin: 10px 0px;
  }
`;
export const TittleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 50px;
  overflow: scroll;
  @media (orientation: portrait) {
    padding: 20px 20px 0 20px;
    height: 82vh;
  }
`;

export const Image = styled.p`
  color: ${({ theme }) => theme.colors.text};
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex: 7;
  justify-content: space-around;
  flex-flow: wrap;
  overflow: scroll;
`;
