import React from "react";
import { Container, Image, Text } from "./styles";

const MiniPreview = ({ src, brand, description, litraje, onClick, active }) => (
  <Container onClick={onClick} active={active}>
    <Image src={src}></Image>
    <Text>{brand}</Text>
    <Text>{description}</Text>
    <Text>{litraje}</Text>
  </Container>
);

export { MiniPreview };
