import axios from "axios";

export const ADD_COMBO_ENTRY = "ADD_COMBO_ENTRY";
export const ADD_COMBO_METRIC = "ADD_COMBO_METRIC";
export const GET_ALL_COMBOS = "GET_ALL_COMBOS";
export const GET_COMBOS_BY_FORMAT = "GET_COMBOS_BY_FORMAT";
export const GET_COMBOS_BY_TYPE = "GET_COMBOS_BY_TYPE";
export const GET_COMBO_ENTRIES = "GET_COMBO_ENTRIES";

export const getComboEntries = () => ({
  type: GET_COMBO_ENTRIES,
  payload: new Promise((res, rej) => {
    axios
      .get("/comboMetrics/combo-entries")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addComboEntry = (data) => ({
  type: ADD_COMBO_ENTRY,
  payload: new Promise((res, rej) => {
    axios
      .post("/comboMetrics/combo-entry", { token: data })
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const addComboMetric = (data) => ({
  type: ADD_COMBO_METRIC,
  payload: new Promise((res, rej) => {
    axios
      .post("/comboMetrics/add", data)
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getAllCombos = () => ({
  type: GET_ALL_COMBOS,
  payload: new Promise((res, rej) => {
    axios
      .get("/comboMetrics/all-combos")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getCombosByFormat = () => ({
  type: GET_COMBOS_BY_FORMAT,
  payload: new Promise((res, rej) => {
    axios
      .get("/comboMetrics/all-combos-by-format")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});

export const getCombosByType = () => ({
  type: GET_COMBOS_BY_TYPE,
  payload: new Promise((res, rej) => {
    axios
      .get("/comboMetrics/all-combos-by-type")
      .then((response) => res({ data: response.data }))
      .catch((err) => rej(err.response));
  }),
});
