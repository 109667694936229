//* librerias
import QRCode from "qrcode";
import XLSX from "xlsx";
//* assets
import logo_fb from "../../assets/image/02_crear_combo/02_crear_combo_icono_final_facebook.png";
import logo_inst from "../../assets/image/02_crear_combo/02_crear_combo_icono_final_instagram.png";
import logo_wsp from "../../assets/image/02_crear_combo/02_crear_combo_icono_final_whatsapp.png";
import colors from "../../assets/colors";

export function getLines(ctx, text, maxWidth) {
  var words = text.split(" ");
  var lines = [];
  var currentLine = words[0];

  for (var i = 1; i < words.length; i++) {
    var word = words[i];
    var width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

export const callBack_contactImage = (socialApp) => {
  switch (socialApp) {
    case "whatsapp":
      return logo_wsp;
    case "facebook":
      return logo_fb;
    case "instagram":
      return logo_inst;
    default:
      break;
  }
};

export const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const bandA = a.forma.toUpperCase();
  const bandB = b.forma.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = -1;
  } else if (bandA < bandB) {
    comparison = 1;
  }
  return comparison;
};

export const generateQR = async (text, bg =colors.palette.black) => {
  var opts = {
    margin: 4,
    type: "image/jpeg",
    quality: 0.99,
    color: {
      dark: bg,
    },
  };
  try {
    return await QRCode.toDataURL(text, opts);
  } catch (err) {
    console.error(err);
  }
};

export const exportXLSXMetrics = (metrics, title) => {
  // export json to Worksheet of Excel
  // only array possible

  var metricsWS = XLSX.utils.json_to_sheet(metrics);

  // A workbook is the name given to an Excel file
  var wb = XLSX.utils.book_new(); // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, metricsWS, title); // sheetAName is name of Worksheet

  let today = new Date();
  let date =
    today.getDate() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getFullYear();
  // export Excel file
  XLSX.writeFile(wb, `${title}_${date}.xlsx`); // name of the file is 'book.xlsx'
};
export function lightenColor(color, percent) {
  const hex = color.replace('#', '');
  const num = parseInt(hex, 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = (num >> 8 & 0x00FF) + amt;
  const B = (num & 0x0000FF) + amt;
  const newColor = `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1)}`;
  return newColor;
}

export function darkenColor(color, percent) {
  const hex = color.replace('#', '');
  const num = parseInt(hex, 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) - amt;
  const G = (num >> 8 & 0x00FF) - amt;
  const B = (num & 0x0000FF) - amt;
  const newR = R < 0 ? 0 : R;
  const newG = G < 0 ? 0 : G;
  const newB = B < 0 ? 0 : B;
  const newColor = `#${(newR << 16 | newG << 8 | newB).toString(16)}`;
  return newColor;
}
