import React from "react";
import styled, { useTheme } from "styled-components";

export default function Subtitle({ text, notmr }) {
  const theme = useTheme();
  return (
    <Container>
      <Text theme={theme}>{text}</Text>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

export const Text = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  font-family: GilroyMedium;
`;
