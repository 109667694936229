import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
//* actions
import {
  getAllCombos,
  getAllCombosFormat,
  getAllCombosType,
  getAllMenu,
  getAllQR,
} from "../../../../redux/metrics/actions";
// import { bl } from './redux/sideEffects';

import { getComboEntries } from "../../../../redux/combo/actions";
import { getMenuEntries } from "../../../../redux/menu/actions";
import { getQREntries } from "../../../../redux/qr/actions";

//* components
//import BarChart from '../../components/charts/BarChart';
import { BarChart } from "../BarChart";
import { MetricsOne } from "../MetricsOne";
import { MetricsTwo } from "../MetricTwo";

//* styles
import { ContainerMetrics } from "./styles";
import Pdf from "react-to-pdf";

export const Metrics = () => {
  const dispatch = useDispatch();
  const [dataResult, setDataResult] = useState(undefined);
  const dataChart = useSelector((state) => state.metrics.totalCombos.combos);
  const containerRef = useRef();
  const options = {
    orientation: "p",
    unit: "mm",
    format: "a2",
    putOnlyUsedFonts: true,
    precision: 100000000,
    floatPrecision: "smart", // or "smart", default is 16
  };
  const convertData = () => {
    let result = [];
    dataChart.forEach((combo) => {
      const objBebida = result.find((b) => b.drinkLine === combo.drinkLine);
      const foundIndex = result.findIndex(
        (b) => b.drinkLine === combo.drinkLine
      );
      if (!objBebida) {
        let obj = {};
        let color = Math.round(Math.random() * 1000);
        obj.drinkLine = combo.drinkLine;
        obj[combo.drinkLitrage] = 1;
        obj.color = `hsl(${color}, 70%, 50%)`;
        result.push(obj);
      } else {
        if (objBebida[combo.drinkLitrage]) objBebida[combo.drinkLitrage]++;
        else objBebida[combo.drinkLitrage] = 1;
        result[foundIndex] = objBebida;
      }
    });

    setDataResult(result);
  };

  const exportXLSX = () => {
    let result = [];
    dataChart.forEach((combo) => {
      if (combo.drinkLine === "cc" || combo.drinkLine === "socc")
        combo.drinkLine = "ccso";
      const objBebida = result.find((b) => b.drinkLine === combo.drinkLine);
      const foundIndex = result.findIndex(
        (b) => b.drinkLine === combo.drinkLine
      );
      if (!objBebida) {
        let obj = {};
        obj.total = 1;
        obj.drinkLine = combo.drinkLine;
        obj[combo.drinkLitrage] = 1;
        result.push(obj);
      } else {
        objBebida.total++;
        if (objBebida[combo.drinkLitrage]) objBebida[combo.drinkLitrage]++;
        else objBebida[combo.drinkLitrage] = 1;
        result[foundIndex] = objBebida;
      }
    });

    // export json to Worksheet of Excel
    // only array possible
    var metricsWS = XLSX.utils.json_to_sheet(result);

    // A workbook is the name given to an Excel file
    var wb = XLSX.utils.book_new(); // make Workbook of Excel

    // add Worksheet to Workbook
    // Workbook contains one or more worksheets
    XLSX.utils.book_append_sheet(wb, metricsWS, "metrics"); // sheetAName is name of Worksheet

    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      parseInt(today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    // export Excel file
    XLSX.writeFile(wb, `metrics_bunker_${date}.xlsx`); // name of the file is 'book.xlsx'
  };

  useEffect(() => {
    // setLoading(true);
    dispatch(getComboEntries());
    dispatch(getMenuEntries());
    dispatch(getQREntries());
    dispatch(getAllCombos());
    dispatch(getAllCombosFormat());
    dispatch(getAllCombosType());
    dispatch(getAllMenu());
    dispatch(getAllQR());
  }, [dispatch]);

  useEffect(() => {
    // setLoading(true);
    if (dataChart) convertData();
  }, [dataChart]);

  return (
    <>
      <ContainerMetrics>
        <div ref={containerRef}>
          <MetricsOne />
          <MetricsTwo style={{ marginBottom: "2em" }} />
          {dataResult && <BarChart data={dataResult} />}
        </div>
        <div style={{ textAlign: "center" }}>
          <Pdf
            targetRef={containerRef}
            options={options}
            filename="code-example.pdf"
            x={15}
            y={15}
            scale={1}
          >
            {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
          </Pdf>
          <button onClick={exportXLSX}>Excel</button>
        </div>
      </ContainerMetrics>
      {/* <FooterMetrics /> */}
    </>
  );
};
