import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

//* stages de Menu
import { Template, Logo, Description, Food, Contact } from "../../views";

export default function MenuLayout() {
  const etapaCombo = useSelector((state) => state.createMenu.stage);

  const renderLayout = () => {
    switch (etapaCombo) {
      case "template":
        return <Template />;
      case "logo":
        return <Logo />;
      case "description":
        return <Description />;
      case "picture":
        return <Food />;
      case "contact":
        return <Contact />;
      default:
        break;
    }
  };

  return <Container>{renderLayout()}</Container>;
}

const Container = styled.div`
  --id: ContainerLayout;
  height: 79%;
  display: flex;
  padding: 1.5em;
`;
