import {
  GET_ALL_COMBOS,
  GET_ALL_COMBOS_FORMAT,
  GET_ALL_COMBOS_TYPE,
  GET_ALL_MENU,
  GET_ALL_MENU_TYPE,
  GET_ALL_QR,
} from "./actions";

import { GET_MENU_ENTRIES } from "../menu/actions";
import { GET_QR_ENTRIES } from "../qr/actions";
import { GET_COMBO_ENTRIES } from "../combo/actions";

const INITIAL_STATE = {
  didInvalidate: false,
  isFetching: false,
  totalCombos: false,
  totalCombosFomat: false,
  totalCombosType: false,
  totalMenu: false,
  totalMenuType: false,
  totalQR: false,
  totalMenuEntries: false,
  totalQREntries: false,
  totalComboEntries: false,
  totalComboClientEntries: false,
  totalMenuClientEntries: false,
  totalQrClientEntries: false,
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //* total de combos
    case `${GET_ALL_COMBOS}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_COMBOS}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_COMBOS}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalCombos: action.payload.data,
      };

    //* total de combos en formato
    case `${GET_ALL_COMBOS_FORMAT}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_COMBOS_FORMAT}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_COMBOS_FORMAT}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalCombosFomat: action.payload.data,
      };

    //* total de combos en tipo
    case `${GET_ALL_COMBOS_TYPE}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_COMBOS_TYPE}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_COMBOS_TYPE}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalCombosType: action.payload.data,
      };

    //* total de Menu
    case `${GET_ALL_MENU}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_MENU}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_MENU}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalMenu: action.payload.data,
      };

    //* total de Menu tipo
    case `${GET_ALL_MENU_TYPE}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_MENU_TYPE}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_MENU_TYPE}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalMenuType: action.payload.data,
      };

    //* total de QR
    case `${GET_ALL_QR}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_ALL_QR}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_ALL_QR}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalQR: action.payload.data,
      };

    //* total combo entries
    case `${GET_COMBO_ENTRIES}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_COMBO_ENTRIES}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_COMBO_ENTRIES}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalComboEntries: action.payload.data.totalEntries,
        totalComboClientEntries: action.payload.data.totalClientEntries,
      };

    //* total menu entries
    case `${GET_MENU_ENTRIES}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_MENU_ENTRIES}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_MENU_ENTRIES}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalMenuEntries: action.payload.data.totalEntries,
        totalMenuClientEntries: action.payload.data.totalClientEntries,
      };

    //* total qr entries
    case `${GET_QR_ENTRIES}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_QR_ENTRIES}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `${GET_QR_ENTRIES}_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        totalQREntries: action.payload.data.totalEntries,
        totalQrClientEntries: action.payload.data.totalClientEntries,
      };

    default:
      return state;
  }
};

export default providers;
