import React from "react";
import { useSelector } from "react-redux";
import { exportXLSXMetrics } from "../../../../utils/functions";
//* assets
import Linea1 from "../../../../assets/metrics/ _LINEAS 1.png";
import Linea2 from "../../../../assets/metrics/ _LINEAS 2.png";

//* styles
import {
  Container,
  ContainerCards,
  ContainerCardsTwo,
  Card,
  Linea,
  CardnoButton,
} from "./styles";

export const MetricsTwo = () => {
  const {
    totalCombos,
    totalMenu,
    totalQR,
    totalCombosFomat,
    totalCombosType,
  } = useSelector((state) => state.metrics);
  let total = 0;

  if (totalCombos && totalMenu && totalQR) {
    total = [
      ...totalCombos.clients,
      ...totalMenu.clients,
      ...totalQR.clients,
    ].sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  return (
    <Container>
      <Card
        style={{ marginLeft: "8.5em" }}
        onClick={() => {
          exportXLSXMetrics(total, "Total exportados");
        }}
      >
        <p>ARCHIVOS EXPORTADOS</p>
        <div>
          <strong>
            {totalCombos &&
              totalMenu &&
              totalQR &&
              totalCombos.total + totalMenu.total + totalQR.total}
          </strong>
        </div>
      </Card>
      <Linea>
        <img src={Linea1} alt="img" />
      </Linea>
      <ContainerCards>
        <div></div>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalCombos.clients, "Combo Client Metrics");
          }}
        >
          <p>COMBO</p>
          <div>
            <strong>{totalCombos && totalCombos.total}</strong>
          </div>
        </Card>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalMenu.clients, "Menu Client Metrics");
          }}
        >
          <p>MENÚ</p>
          <div>
            <strong>{totalMenu && totalMenu.total}</strong>
          </div>
        </Card>

        <Card
          onClick={() => {
            exportXLSXMetrics(totalQR.clients, "QR Client Metrics");
          }}
        >
          <p>QR</p>
          <div>
            <strong>{totalQR.total && totalQR.total}</strong>
          </div>
        </Card>
      </ContainerCards>
      <Linea style={{ marginRight: "13em" }}>
        <img src={Linea2} alt="img"/>
      </Linea>
      <ContainerCardsTwo>
        <CardnoButton>
          <p>CUADRADO</p>
          <div>
            <strong>
              {totalCombosFomat && totalCombosFomat.totalSquareCombos}
            </strong>
          </div>
        </CardnoButton>
        <CardnoButton>
          <p>RECTANGULAR</p>
          <div>
            <strong>
              {totalCombosFomat && totalCombosFomat.totalRectangularCombos}
            </strong>
          </div>
        </CardnoButton>
        <CardnoButton>
          <p>SIMPLE</p>
          <div>
            <strong>
              {totalCombosType && totalCombosType.totalSimpleCombos}
            </strong>
          </div>
        </CardnoButton>
        <CardnoButton>
          <p>DÚO</p>
          <div>
            <strong>{totalCombosType && totalCombosType.totalDuoCombos}</strong>
          </div>
        </CardnoButton>
        <div></div>
      </ContainerCardsTwo>
    </Container>
  );
};
