import styled from "styled-components";

export const Container = styled.div`
  max-width: fit-content;
  max-height: fit-content;
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  margin: 5px;
  background-color: ${({ theme, active }) => (active ? theme.colors.secondary : "none")};
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Image = styled.img`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 32px;
  max-width: 180px;
  max-height: fit-content;
  @media (orientation: portrait) {
    max-width: 90px;
  }
`;
